.editor-page {
  position: relative;

  &.app-page {
    height: 100vh !important;

    .app-page-header {
      width: 100%;
      min-height: 100px;
      display: flex;
      justify-content: space-between;
    }

    .app-page-content {
      height: calc(100vh - 100px);

      .btn-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .editor-page-buttons {
        position: absolute;
        gap: 10px;
        top: -124px;
        display: flex;
        justify-content: flex-end;

        .preview-btn {
          cursor: pointer;
          width: 113px;
          font-size: 14px;
          color: #017cf8;
          height: 30px;
          border-radius: 5px;
          background: white;
          border: 1px solid #e4f1ff;
          margin-left: 0;

          &:hover {
            background: #e4f1ff;
          }

          &:active {
            border: none;
            background: #f5f5f5;
          }
        }

        .publish-btn {
          width: 113px;
          height: 30px;
          font-size: 14px;
          color: #017cf8;
          background: #e4f1ff;
          border-radius: 5px;
          border: none;

          &:hover {
            background: #017cf8;
            color: white;
          }

          &:active {
            border: none;
            background: #f5f5f5;
          }
        }
      }

      .editor-page-header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .page-title {
          font-family: "Satoshi";
          font-style: normal;
          font-weight: 500;
          font-size: 24px;
          line-height: 32px;
          display: flex;
          align-items: center;
          /* identical to box height, or 133% */
          padding: 0;
          letter-spacing: -0.02em;
          margin-bottom: 8px;
          color: #0f0f0f;

          .info-icon {
            cursor: pointer;
            width: 10px;
            height: 10px;
            margin-left: 6px;
          }
        }
      }
    }

    .edit-title {
      // margin-left: 4px;
      cursor: pointer;
    }
    .edit-icon-container {
      height: 30px;
      width: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #e4f1ff;
      margin-left: 15px;
      border-radius: 5px;
      svg {
        height: 12px;
        width: 12px;
      }
      &:hover {
        background-color: #017cf8;

        svg > path {
          fill: #fff;
        }
      }
    }
    .page-subheading {
      font-family: "Satoshi";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: -0.02em;
      color: #75787e;
    }

    .editor-container {
      display: flex;
      margin-top: 16px;
      width: 100%;
      height: calc(100% - 93px);

      .tabs-content {
        position: relative;
        // margin: 2rem auto;
        padding-left: 50px;
      }

      .dragMe {
        border-right: 1px solid lightgrey;
        width: 20px;
        // background: black;
      }

      .editor {
        //  width: 50%;
        min-width: 33%;
        width: 50%;
        height: 100%;
        overflow: auto;
        margin-left: -40px;

        &::-webkit-scrollbar {
          width: 0px;
        }

        .recommendation,
        .email {
          .data {
            .data-holders {
              margin: 25px 0;

              .tag {
                margin-top: 25px;
                padding-bottom: 25px;

                .title {
                  color: #75787e;
                }

                .elements {
                  display: flex;
                  gap: 28px;
                  flex-wrap: wrap;

                  .color-container {
                    margin-top: 30px;
                    display: flex;

                    align-items: center;
                    gap: 28px;

                    .colorTag {
                      cursor: pointer;
                      margin: 0;
                      height: 37px;
                      width: 61px;
                      border: 1px solid #dbe1e7;
                      border-radius: 8px;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      padding: 9px;
                      gap: 12px;

                      .color {
                        width: 26px;
                        height: 20px;
                        border-radius: 100%;
                        background: #8ccff2;
                      }
                    }

                    colorTag-holder {
                      colorTag {
                        height: 52px;
                      }
                    }
                  }
                }
              }
            }
          }
        }

        .editor-tabs-container {
          position: sticky;
          top: 0;
          width: 100%;
          z-index: 20;
        }

        .tabs {
          background: rgb(255, 255, 255);
          background: linear-gradient(
            180deg,
            rgba(255, 255, 255, 1) 11%,
            rgba(255, 255, 255, 1) 25%,
            rgba(255, 255, 255, 1) 75%,
            rgba(255, 255, 255, 0) 100%
          );

          span {
            height: 35px;
            min-width: 110px;
            text-align: center;
          }

          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 10px 0px;
          margin: auto;

          span {
            font-weight: 400;
            font-size: 14px;
            line-height: 10px;
            /* identical to box height, or 171% */
            background: #e3f1ff;
            letter-spacing: -0.02em;
            display: inline-block;
            // color: #75787e;
            padding: 12px 22px;
            cursor: pointer;
            text-decoration: none;

            &:first-child {
              border-radius: 8px 0 0 8px;
            }

            &:last-child {
              border-radius: 0 8px 8px 0;
            }

            &.active {
              background: #017cf8;
              color: white;
              z-index: 1;
              box-shadow: -2px 0px 4px rgba(0, 0, 0, 0.25),
                2px 0px 4px rgba(0, 0, 0, 0.25);
            }
          }
        }

        .tabs-divider-container {
          height: 35px;
          width: 1px;
          display: flex;
          align-items: center;
          border-bottom: 2px solid #dbe1e7;
        }

        .tabs-divider {
          height: 16px;
          background: #dbe1e7;
          width: 3px;
        }

        .data-tabs {
          top: 55px;
          svg {
            fill: "#000";
          }
          span {
            background: none;
            border-bottom: 2px solid #dbe1e7;
            border-radius: none;
            color: #000;
            display: flex;
            align-items: center;

            &:first-child {
              border-radius: 0;
            }
          }

          .active-data {
            color: #017cf8;
            border-bottom: 2px solid #017cf8;

            svg {
              fill: #017cf8;
            }
          }
        }
      }

      .preview {
        margin-left: 50px;
        flex-direction: column;

        .skeep-close {
          display: none !important;
        }

        // .standard-kit-container {
        //   margin-left: 0.5vw;
        //   margin-right: 0.5vw;
        // }

        .skeep-emp-preview .skeep-popup {
          animation: unset !important;
        }

        .skeep-wrapper {
          border-radius: unset;
          backdrop-filter: unset;

          * {
            :not(.skeep-pr-preview):not(.standard-content-container):not(
                .standard-content-container *
              ) {
              pointer-events: none;
            }
          }
        }

        .standard-answers-container {
          width: 100%;
        }

        .mantine-Alert-root {
          display: none;
        }

        .mantine-Modal-root {
          display: none;
        }

        .prv3-cart-button {
          min-width: 130px;
        }

        .skeep-popup {
          min-height: 500px;
          width: 100%;
          max-height: 78%;
          box-shadow: 0 0 16px #0003;
          max-width: 100%;
          transform: translate(-50%, -50%);
        }

        .skeep-emp-preview {
          .prv3-product-add-container {
            width: 100%;
          }
        }

        #swiper-card-experience {
          width: 32vw;
          height: 42vh;

          max-width: 215px;
          max-height: 550px;
        }

        #swiper-card-title {
          padding-top: 13%;
          font-size: 17px;
        }

        .start-swiper-discover {
          margin-top: 8%;
        }

        .skeep-pr-close {
          display: none;
        }

        .skeep-wrapper
          .prv3-product-section
          .prv3-product-section-cta-container
          .prv3-quantity-indicator {
          width: auto;
        }

        .skeep-wrapper
          .prv3-product-section
          .prv3-product-section-cta-container
          .prv3-quantity-button {
          width: 35px;
        }

        .skeep-emp-preview .swp .swipe-container-card {
          width: 100%;
        }

        .swipe-container {
          height: 75%;
          width: 60%;
          max-width: 500px;
        }

        .skeep-embedded_page {
          top: 30%;
          position: relative;
        }

        .prv3-page,
        #multiple-products-page {
          height: 90% !important;
          position: absolute;
          bottom: 0;
        }

        .skeep-emp-preview {
          animation: unset !important;

          .standard-answers-container-images {
            flex-direction: row !important;
            overflow-x: auto !important;
            flex-wrap: nowrap !important;
            padding-left: 50px !important;
            gap: 10px !important;

            .standard-answer-text {
              padding-top: 6px;
              font-size: 14px;
            }
          }

          .swp-title {
            margin-bottom: 0vh !important;
            z-index: 1;
            position: absolute;
            top: 0;
          }

          .swipe-container-card {
            height: 60%;
            min-height: 340px !important;
          }

          .prv3-page,
          #multiple-products-page {
            height: 85% !important;
            position: absolute;
            bottom: 0;
          }

          .mantine-18eiw81 {
            flex-direction: column;
          }

          .standard-content-container {
            top: 0;
          }

          .skeep-app {
            width: 100%;
            height: 100%;

            .skeep-popup {
              min-width: unset;
              max-height: unset;
              max-width: unset;
              position: fixed;
              top: 50%;
              left: 50%;
            }
          }

          .skeep-embedded_page {
            top: 0;
            position: relative;
          }
        }

        .standard-slider-button {
          transform: translateX(-50%) !important;
          margin-left: 50% !important;
        }

        .prv3-fixed-section {
          position: fixed;
          top: 45%;
        }

        .skeep-popup-close {
          display: none;
        }

        .skeep-wrapper {
          overflow-y: scroll !important;
          height: 83%;
          width: 100%;

          &::-webkit-scrollbar {
            width: 0;
          }

          .prv3-product-section {
            height: 100%;
            //min-height: 100%;
          }
        }

        .skeep-pr-preview {
          //height: unset !important;
        }

        .skeep-design-id {
          position: absolute;
          bottom: 0;
        }

        .skeep-emp-preview {
          //border-radius: 0 0 50px 50px !important;

          //.skeep-wrapper .standard-question-title {
          //  color: #fff !important;
          //}

          .skeep-wrapper {
            height: 100%;
          }

          .skeep-embedded_page {
            margin-top: unset;
          }

          .skeep-popup {
            width: 100%;
            margin: auto;
            bottom: unset;
          }
        }

        display: flex;
        // height: 726px;
        justify-content: center;
        min-width: 33%;
        align-items: center;
        flex: 1;
        width: 100%;
        height: calc(100% - 70px);
        position: relative;

        .screen-size {
          position: absolute;
          top: -40px;
          border-radius: 6px;
          height: 30px;
          display: flex;
          align-items: center;
          z-index: 21;

          .tab-container {
            height: 30px;
            width: 92px;
            border: none;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          .tab {
            border: none;
            width: 100px;
            height: 100%;
            text-align: center;
          }
        }

        .preview-frame {
          position: absolute;
          height: 300px;
          z-index: 1;
          transform: scale(0.4);
        }
      }

      .skeep-emp-preview {
        .standard-answers-container {
          flex-wrap: wrap;
          //width: 100%;
        }

        .standard-answers-container::-webkit-scrollbar {
          display: none;
        }
      }

      .preview-frame {
        position: absolute;
        height: 300px;
        z-index: 1;
      }
    }

    .buttons-container {
      display: flex;
      margin-top: -4.5%;
      z-index: 1;
      justify-content: flex-end;
    }

    .field-medium {
      max-width: 100%;
      margin-bottom: 0;
    }

    .field-input {
      font-size: 12px;
      color: #75787e;
    }

    .colorTag {
      cursor: pointer;
      height: 37px;
      border: 1px solid #dbe1e7;
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 9px;
      gap: 12px;

      .color {
        width: 20px;
        height: 20px;
        border-radius: 100%;
        // background: #8ccff2;
      }
    }

    .design {
      &-title-section {
        width: 90%;
        padding: 0px 0;
        border-bottom: 1px solid #dbe1e7;
      }

      &-title {
        font-size: 12px;
        line-height: 16px;
        font-weight: 500;
        display: flex;
        justify-content: space-between;
        color: #75787e;
        padding: 20px 10px;

        svg {
          margin-top: 5px;
        }
      }

      &-title:hover {
        background-color: #f4f4f4;
        cursor: pointer;
      }

      &-elements {
        display: flex;
        margin: 32px 0;
        width: 100%;
        padding-right: 32px;
        justify-content: flex-start;
        gap: 16px;
        flex-wrap: wrap;
        padding: 0px 10px;

        .form-control {
          gap: 0.5%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          max-width: 215px;

          .colorTag:hover {
            border: 1px solid #212121;
            cursor: pointer;
          }

          .fontContainer {
            width: 100%;
            display: flex;
            align-items: center;
          }

          display: flex;

          label {
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            margin-right: 15px;
            /* or 133% */

            display: flex;
            align-items: center;

            color: #75787e;
          }
        }
      }
    }

    .email-title {
      display: flex;
      align-items: center;
      gap: 15px;

      .email-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 22px;
        width: 22px;
        border-radius: 5px;
      }
    }

    .content {
      width: 85%;

      .answer-inputs {
        padding-bottom: 11px;

        border-bottom: 1px solid #dbe1e7;
      }

      .answers-title {
        display: flex;
        align-items: center;
        gap: 8px;
      }

      .title {
        display: flex;
        gap: 9px;
        align-items: center;
        color: #0f0f0f;
        position: relative;
        padding: 18px 0px;
        cursor: pointer;

        .field-label {
          display: none;
        }

        .field {
          margin-bottom: 0px;
        }

        .field-input {
          background: transparent;
          border: none;
          outline: none;
          width: 80%;
        }
      }

      .title:hover {
        background-color: rgba(228, 241, 255, 0.5);
      }

      // margin: auto;
      .quesIcon {
        height: 20px;
        width: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 6px;
      }

      .tag-inputs {
        margin-top: 10px;
      }

      .questionNo {
        font-weight: 500;
        font-size: 16px;
        line-height: 32px;
      }

      .questions-list {
        // margin: 26px 0;

        .question {
          font-weight: 400;
          font-size: 11px;
          line-height: 120%;
          border-bottom: 1px solid #dbe1e7;

          .question-content {
            padding: 0rem 1.5rem;

            .field {
              margin-bottom: 0;
            }

            .side-image-title {
              display: flex;
              align-items: center;
              font-family: "Satoshi";
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              line-height: 16px;
              color: #000000;
            }

            .selection-inputs {
              display: flex;
              margin-top: 40px;
              .divider {
                height: 75px;
                margin: 7px 35px 0px 30px;
                background: #dbe1e7;
                width: 2px;
              }
            }
          }

          .inputs {
            display: flex;
            gap: 15px;
            padding: 0;
            padding-top: 12px;
            margin: 20px 0;
            align-items: center;
            justify-content: space-between;
            padding: 2rem 0;
            font-weight: 500;
            // border-bottom: 1px solid #dbe1e7;
          }

          .question-input,
          .answer-inputs,
          .side-image {
            padding: 25px 0;
            font-weight: 500;
          }

          .answer-inputs {
            width: 100%;

            .field {
              margin-bottom: 0 !important;
            }

            .tags-container {
              // margin-top: 8px;
              width: 40%;
            }

            .answer {
              display: flex;
              gap: 20px;
              gap: 10px;

              .answer-search {
                width: 100%;
                gap: 20px;
                display: flex;
                align-items: center;
                position: relative;
              }
            }
          }

          .side-image {
            div {
              width: 50%;
              border: 1px solid #dbe1e7;
              height: 44px;
              border-radius: 8px;
              margin: 10px 0;
              padding: 10px;
              align-items: center;
              display: flex;
              position: relative;

              label {
                color: #75787e;
                margin-left: 0.5rem;
              }

              input {
                position: absolute;
                left: 0;
                top: 0;
                opacity: 0;
                height: 100%;
                width: 100%;
              }
            }
          }

          .arrow {
            position: absolute;
            right: 20px;
            cursor: pointer;
          }
        }
      }

      .add-btn {
        font-weight: 500;
        font-size: 14px;
        color: #017cf8;
        background: transparent;
        border: none;
        display: flex;
        align-items: center;
        letter-spacing: -0.02em;
        cursor: pointer;
        padding: 5px 15px;
        border-radius: 5px;

        span {
          font-size: 30px;
          font-weight: 300;
          margin-right: 10px;
          padding-bottom: 3px;
        }
      }

      .add-btn:hover {
        background-color: #f4f4f4;
      }

      .answerError {
        background: #ffe4b4;
        border-radius: 5px;
        font-size: 10px;
        color: #75787e;
        top: 1240px;
        padding: 8px;
        display: flex;
        align-items: center;
        width: 95%;
        margin: 5px 0;
        margin-top: 14px;

        svg {
          border: 1px solid #f4b037;
          border-radius: 100%;
          height: 18px !important;
          width: 22px !important;
          margin-right: 5px;
        }
      }

      .infoNotif {
        background: #e4f1ff;
        border-radius: 5px;
        padding: 8px;
        width: 250px;
        margin: 5px 0;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        svg {
          border: 1px solid #75787e;
          border-radius: 100%;
          height: 13px;
          width: 14px;
          margin-right: 10px;
        }

        p {
          font-size: 10px;
          color: #75787e;
          width: 200px;
        }
      }

      .infoNotifError {
        background: #ffe4b4;
        border-radius: 5px;
        padding: 8px;
        width: 250px;
        margin: 5px 0;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        svg {
          border: 1px solid #f4b037;
          border-radius: 100%;
          height: 13px;
          width: 14px;
          margin-right: 10px;
        }

        p {
          font-size: 10px;
          color: #75787e;
          width: 200px;
        }
      }

      .infoNotifSuccess {
        background: #cec6f6;
        border-radius: 5px;
        padding: 8px;
        width: 250px;
        margin: 5px 0;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        svg {
          border: 1px solid #ffffff;
          border-radius: 100%;
          height: 13px;
          width: 14px;
          margin-right: 10px;
        }

        p {
          font-size: 10px;
          color: #ffffff;
          width: 200px;
        }
      }
    }

    .back-btn {
      display: flex;

      button {
        border: none;
        background: #017cf8;
        border-radius: 100%;
        height: 24px;
        width: 24px;
        margin-left: 2rem;
        margin-right: 1rem;
        position: relative;

        svg {
          position: absolute;
          right: 6px;
          bottom: 7.8px;
        }
      }
    }

    .templates,
    .question-types {
      display: flex;
      flex-wrap: wrap;
      padding: 4rem 0;
      gap: 2rem;
      width: 100%;

      .template,
      .question-type {
        display: flex;
        flex-direction: column;
        width: 28%;
        text-align: center;

        img {
          border-radius: 6px;
        }

        p {
          font-size: 12px;
          color: #75787e;
        }
      }
    }
    .generate-question {
      padding-top: 12px;
      width: 100%;
      display: flex;
      align-items: center;
      
      .icon {
        margin-left: 10px;
      }
      .animation{
        width: 100%;
        margin-left: 10px;
        height: 30px;
       
      }
    }

    .question-types {
      width: 100%;
      padding: 1rem 0;
      background: rgba(228, 241, 255, 0.3);
      border-radius: 8px;
      padding: 0 6px 0 6px;

      .title {
        width: 100%;
        position: relative;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #dbe1e7;

        .icon-ai {
          position: absolute;
          right: 5px;
        }
        .ques-icon {
          position: absolute;
          left: 0;
          z-index: 1;
        }

        .field-input {
          background: transparent;
          border: none;
          padding-left: 30px;
          outline: none;
        }
      }

      .sample-question {
        width: 100%;
        font-size: 12px;
        padding: 0rem 40px 2rem 40px;

        .inputs {
          display: flex;
          gap: 15px;
          padding: 0;
          padding-top: 12px;
          align-items: center;
          justify-content: space-between;
          padding: 2rem 0;
          font-weight: 500;
          border-bottom: 1px solid #dbe1e7;
        }

        .question {
          display: flex;
          gap: 15px;
          padding-bottom: 36px;
          border-bottom: 1px solid #dbe1e7;
        }

        .field-label {
          color: black;
        }

        .field-input-container-image {
          border: 1px solid #dbe1e7;
          border-radius: 8px;

          .field-input {
            z-index: 0;
            border: 0;
          }
        }

        .image-input-field {
          margin: 16px 0;
          max-width: 220px;
        }

        .selection-inputs {
          align-items: center;
          display: flex;
          gap: 13px;
        }

        .answer {
          padding-bottom: 11px;

          border-bottom: 1px solid #dbe1e7;
          gap: 15px;

          .add-btn {
            margin-bottom: 67px;
          }

          .tags-container {
            width: 45%;
          }

          .labels {
            display: flex;
            gap: 42%;
            margin-top: 28px;
          }
        }

        .side-image {
          margin-top: 18px;

          &-title {
            font-size: 14px;
            width: 100%;
            display: flex;
            margin-bottom: 25px;
          }

          div {
            width: 45%;
            border: 1px solid #dbe1e7;
            height: 44px;
            border-radius: 8px;
            margin: 10px 0;
            padding: 10px;
            align-items: center;
            display: flex;
            position: relative;

            label {
              color: #75787e;
              margin-left: 0.5rem;
            }

            input {
              position: absolute;
              left: 0;
              top: 0;
              opacity: 0;
              height: 100%;
              width: 100%;
            }
          }
        }

        .btn-container {
          display: flex;
          justify-content: flex-end;
          gap: 10px;
        }
      }
    }

    .s0,
    .s1 {
      stroke: none !important;
    }

    .type-slide-container {
      img {
        object-fit: cover;
        margin-bottom: 6px;
      }

      p {
        font-size: 14px;
        text-align: center;
        text-transform: capitalize;

        small {
          font-size: 12px;
          color: #75787e;
        }
      }

      &.selected {
        position: relative;

        img {
          border: 1px solid #017cf8;
          border-radius: 4px;
        }

        &:after {
          content: url("../../assets/icons/tick.svg");
          position: absolute;
          left: 50%;
          bottom: 20px;
          transform: translateX(-50%);
          z-index: 1;
        }
      }
    }

    .title-edit {
      display: flex;
      input {
        border: none;
        width: 483px;
        border-bottom: 1px solid #dbe1e7;
        font-size: inherit;
      }

      .icon-button {
        background: none;
        border: none;
        width: 24px;
        cursor: pointer;

        svg {
          height: 24px;
        }

        &:hover {
          svg {
            opacity: 0.7;
          }
        }
      }
    }
    .cancel-exp-btn {
      font-family: "Satoshi";
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: -0.02em;
      text-align: left;
      color: #75787e;
    }
    .save-exp-btn {
      margin-left: 40px;
      padding: 6px 15px 6px 15px;
      background-color: #017cf8;
      width: 60px;
      border-radius: 5px;
      color: white;
      font-family: "Satoshi";
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: -0.02em;
      text-align: left;
    }
    .answer {
      .field {
        width: 50% !important;
        min-width: 157px;
      }

      .tags-container {
        .field {
          width: 100% !important;
        }
      }
    }

    .email-segment {
      height: 30px;

      button {
        font-family: "Satoshi";

        &:last-child {
          border-left: none;
        }

        &[data-active] {
          background-color: #e4f1ff;
        }
      }
    }

    .add-question {
      .field {
        margin-bottom: 0;
      }
    }

    .product {
      width: 85%;
      min-width: 500px;

      .form-control {
        display: flex;
      }

      .design {
        &-title-section {
          width: 100%;
        }

        &-title {
          padding: 20px 0;
        }

        &-elements {
          padding: 0;
        }
      }
    }
  }

  .switch {
    background: #e4f1ff;
    border-radius: 20px;
    display: flex;
    padding: 4px;
    width: 64px;
    height: 28px;
    justify-content: space-between;
    align-items: center;

    button {
      border: none;
      background: transparent;
      color: #75787e;
      font-size: 8px;
    }

    div {
      background: #017cf8;
      height: 19px;
      width: 19px;
      border-radius: 100%;
    }

    &-disabled {
      background-color: #f3f5f7;

      div {
        background: #75787e;
      }
    }

    position: relative;

    * {
      position: absolute;
      transition: all 500ms;
    }
  }
}

#font-form-control {
  width: 100%;

  .fontContainer {
    margin-left: 15px;
  }
}

.editor-pop-container {
  width: calc(100% - 125px);
  height: 100%;
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  right: -124px;
  background-color: rgb(15, 15, 15, 0.25);
  z-index: 20000 !important;
}

.editor-pop-container-minimized {
  width: calc(100% - 70px);
  height: 100%;
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  right: -10px;
  background-color: rgb(15, 15, 15, 0.25);
  z-index: 20000 !important;
}

.editor-pop {
  width: 486px;

  /* white */

  background-color: #ffffff;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  padding: 32px 32px 15px 32px;
  position: relative;
}

.new-question-editor-pop-header {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  /* identical to box height, or 133% */

  letter-spacing: -0.02em;

  /* text / main-black */

  color: #0f0f0f;
}

.editor-pop-header {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  /* identical to box height, or 133% */

  letter-spacing: -0.02em;

  /* text / main-black */

  color: #0f0f0f;
}

.new-question-editor-pop-info {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  width: 330px;
  /* or 150% */

  letter-spacing: -0.02em;

  /* text/secondary */

  color: #75787e;
  margin-top: 12px;
}

.editor-pop-info {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  letter-spacing: -0.02em;

  /* text/secondary */

  color: #75787e;
  margin-top: 12px;
}

.editor-pop-button-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-top: 20px;
}

.sentence-content-container {
  display: flex;
  flex-direction: column;
  padding-top: 20px;

  .sentence-default-container {
    display: flex;
    padding: 10px 0;
    align-items: center;
    justify-content: flex-start;
    gap: 40px;
    border-bottom: 1px solid #dbe1e7;

    .sentence-row-trash {
      visibility: hidden;
    }
  }

  .sentence-tag-row {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
    padding: 10px 0;
  }

  .sentence-tag-row-labels {
    display: flex;
    align-items: center;
    gap: 20px;
    white-space: nowrap;
  }

  .sentence-row-trash {
    cursor: pointer;
  }
}

.title-upper-section {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 15px;

  .field-error-message {
    bottom: -20px;
  }
}

#sentence-title {
  height: fit-content;
  display: flex;
  flex-direction: column;
  padding: 10px 10px;
  justify-content: flex-start;
  border-top: 1px solid #dbe1e7;
  border-bottom: 1px solid #dbe1e7;
  background-color: rgba(228, 241, 255, 0.3);

  .field {
    margin-bottom: 0px;
  }

  .field-input {
    height: 38px !important;
  }
}

.sentence-input-closed {
  background-color: transparent;
  border: none;
  margin: 10px 0px !important;
}

.title-lower-section {
  display: flex;

  p {
    line-height: 1.2rem;
  }
}

.sentence-trash {
  position: absolute;
  top: 50%;
  right: -11%;
  max-height: 18px;
  max-width: 18px;
  transform: translate(-50%, -50%);
}

.skeep-emp-preview {
  &::-webkit-scrollbar {
    width: 2px;
  }

  &::-webkit-scrollbar-thumb {
    background: #75787e;
  }

  .swipe-container {
    height: 95% !important;
    width: 60%;
    max-width: 500px;
  }

  .swipe-container-card {
    min-height: 370px !important;
    height: 45vh !important;
  }
}

.star-question {
  position: absolute;
  left: -30px;
  top: 20px;
  cursor: pointer;

  height: 16px;
  width: 16px;
}

.star-question-stroke {
  height: 18px;
  width: 18px;
  stroke: white;
}

.star-question-stroke:hover {
  stroke: #017cf8;
}

.star-tooltip {
  position: absolute;
  left: -8px;
  top: 20px;
  font-size: 12px;
  padding: 8px 15px;
  border-radius: 5px;
  background-color: #e4f1ff;
  color: black;
}

.remove-skeep-branding {
  margin-left: 50px;
  margin-right: auto;
  color: #75787e;
  text-decoration: underline;
  font-size: 14px;
  cursor: pointer;
}

.remove-skeep-branding:hover {
  color: black;
}
