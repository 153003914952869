.editor-page {
  &.app-page.pr-page {
    position: relative;
    height: 100vh !important;
    .app-page-header {
      min-height: 100px;
    }
    .app-page-content {
      height: calc(100vh - 100px);
      .recommendation-header {
        display: flex;
        gap: 24px;
      }
    }
    .edit-title {
      margin-left: 4px;
      cursor: pointer;
      &:hover {
        opacity: 0.8;
      }
    }
    .page-title {
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 32px;
      /* identical to box height, or 133% */
      padding: 0;
      letter-spacing: -0.02em;
      margin-bottom: 8px;
      color: #0f0f0f;
    }
    .page-subheading {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: -0.02em;
      color: #75787e;
    }
    .editor-container {
      display: flex;
      margin-top: 16px;
      width: 100%;
      height: calc(100% - 93px);
      .dragMe {
        border-right: 1px solid lightgrey;
        width: 20px;
        // background: black;
      }
      .editor {
        //  width: 50%;
        min-width: 50%;
        width: 50%;
        height: 100%;
        overflow: auto;
        .question {
          font-weight: 400;
          font-size: 11px;
          line-height: 120%;

          .question-content {
            padding: 0rem 1.5rem;
            .field {
              margin-bottom: 0;
            }
          }
          .inputs {
            display: flex;
            gap: 15px;
            padding: 0;
            padding-top: 12px;
            margin: 20px 0;
            align-items: center;
            justify-content: space-between;
            padding: 2rem 0;
            font-weight: 500;
            // border-bottom: 1px solid #dbe1e7;
          }
          .question-input,
          .answer-inputs {
            border-bottom: 1px solid #dbe1e7;
          }
          .question-input,
          .answer-inputs,
          .side-image {
            padding: 25px 0;
            font-weight: 500;
          }
          .answer-inputs {
            width: 100%;
            .field {
              margin-bottom: 0 !important;
            }

            .tags-container {
              // margin-top: 8px;
              width: 40%;
            }
            .answer {
              display: flex;
              gap: 20px;
              gap: 10px;

              .answer-search {
                width: 100%;
                gap: 20px;
                display: flex;
                align-items: center;
                position: relative;
              }
            }
          }
          .side-image {
            div {
              width: 50%;
              border: 1px solid #dbe1e7;
              height: 44px;
              border-radius: 8px;
              margin: 10px 0;
              padding: 10px;
              align-items: center;
              display: flex;
              position: relative;
              label {
                color: #75787e;
                margin-left: 0.5rem;
              }

              input {
                position: absolute;
                left: 0;
                top: 0;
                opacity: 0;
                height: 100%;
                width: 100%;
              }
            }
          }

          .title {
            display: flex;
            gap: 9px;
            align-items: center;
            color: #0f0f0f;
            position: relative;
            padding: 15px 60px 15px 10px;
            cursor: pointer;

            .field-label {
              display: none;
            }

            .field {
              margin-bottom: 0px;
            }

            .field-input {
              background: transparent;
              border: none;
              outline: none;
              width: 80%;
            }
          }
          .title:hover {
            background-color: #f4f4f4;
          }
          .arrow {
            position: absolute;
            right: 20px;
            cursor: pointer;
          }
        }
        .product {
          .form-control {
            display: flex;
          }
          .design {
            &-title-section {
              width: 100%;
            }
            &-title {
              padding: 20px 0;
            }
            &-elements {
              padding: 0;
            }
          }
        }
        .email,
        .product {
          .data {
            .data-holders {
              margin: 25px 0;
              .tag {
                margin-top: 25px;
                padding-bottom: 25px;
                .title {
                  color: #75787e;
                }
                .elements {
                  display: flex;
                  gap: 28px;
                  flex-wrap: wrap;
                  .color-container {
                    margin-top: 30px;
                    display: flex;

                    align-items: center;
                    gap: 28px;

                    .colorTag {
                      cursor: pointer;
                      margin: 0;
                      height: 37px;
                      width: 61px;
                      border: 1px solid #dbe1e7;
                      border-radius: 8px;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      padding: 9px;
                      gap: 12px;
                      .color {
                        width: 26px;
                        height: 20px;
                        border-radius: 100%;
                        background: #8ccff2;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      // .preview {
      //   display: flex;
      //   // height: 726px;
      //   justify-content: center;
      //   min-width: 40%;
      //   align-items: center;
      //   flex: 1;
      //   width: 100%;
      //   // height: calc(100% - 48px);
      //   height: min-content;
      //   margin: auto;
      //   position: relative;
      //   .screen-size {
      //     position: absolute;
      //     top: -40px;
      //     right: 0;
      //     border: 1px solid #dfdfdf;
      //     border-radius: 6px;
      //     width: 100px;
      //     height: 30px;
      //     display: flex;
      //     justify-content: space-evenly;
      //     align-items: center;
      //     .tab {
      //       width: 100%;
      //       height: 100%;
      //       text-align: center;
      //     }
      //   }
      //   .preview-frame {
      //     position: absolute;
      //     height: 300px;
      //     z-index: 1;
      //     transform: scale(0.4);
      //   }
      // }
      // .preview-frame {
      //   position: absolute;
      //   height: 300px;
      //   z-index: 1;
      // }
    }
    .buttons-container {
      display: flex;
      margin-top: -36px;
      z-index: 1;
      justify-content: flex-end;
    }
    .field-medium {
      max-width: 100%;
      margin-bottom: 0;
    }
    .field-input {
      font-size: 12px;
      color: #75787e;
    }
    .design {
      &-title-section {
        width: 90%;
        padding: 0px 0;
        border-bottom: 1px solid #dbe1e7;
      }
      &-title {
        font-size: 12px;
        line-height: 16px;
        font-weight: 500;
        display: flex;
        justify-content: space-between;
        color: #75787e;
        padding: 20px 10px;

        svg {
          margin-top: 5px;
        }
      }
      &-title:hover {
        background-color: #f4f4f4;
        cursor: pointer;
      }
      &-elements {
        display: flex;
        margin: 32px 0;
        width: 100%;
        padding-right: 32px;
        justify-content: flex-start;
        gap: 16px;
        flex-wrap: wrap;
        padding: 0px 10px;

        .form-control {
          gap: 0.5%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          max-width: 215px;

          .colorTag:hover {
            border: 1px solid #212121;
            cursor: pointer;
          }
          .colorTag {
            cursor: pointer;
            height: 37px;
            border: 1px solid #dbe1e7;
            border-radius: 8px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 9px;
            gap: 12px;

            .color {
              width: 20px;
              height: 20px;
              border-radius: 100%;
              background: #8ccff2;
            }
          }

          .fontContainer {
            width: 100%;
            min-width: 220px;
            display: flex;
            align-items: center;
          }
          display: flex;

          label {
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            /* or 133% */

            display: flex;
            align-items: center;

            color: #75787e;
          }
        }
      }
    }
    .content {
      padding-left: 5px;
      .tag-inputs {
        margin-top: 10px;
      }
      .questionNo {
        font-weight: 500;
        font-size: 16px;
        line-height: 32px;
      }
      .questions-list {
        margin: 26px 0;
        width: 90%;

        .question {
          font-weight: 400;
          font-size: 11px;
          line-height: 120%;

          .question-content {
            padding: 0rem 1.5rem;
            .field {
              margin-bottom: 0;
            }
          }
          .inputs {
            display: flex;
            gap: 15px;
            padding: 0;
            padding-top: 12px;
            margin: 20px 0;
            align-items: center;
            justify-content: space-between;
            padding: 2rem 0;
            font-weight: 500;
            // border-bottom: 1px solid #dbe1e7;
          }
          .question-input,
          .answer-inputs {
            border-bottom: 1px solid #dbe1e7;
          }
          .question-input,
          .answer-inputs,
          .side-image {
            padding: 25px 0;
            font-weight: 500;
          }
          .answer-inputs {
            width: 100%;
            .field {
              margin-bottom: 0 !important;
            }

            .tags-container {
              // margin-top: 8px;
              width: 40%;
            }
            .answer {
              display: flex;
              gap: 20px;
              gap: 10px;

              .answer-search {
                width: 100%;
                gap: 20px;
                display: flex;
                align-items: center;
                position: relative;
              }
            }
          }
          .side-image {
            div {
              width: 50%;
              border: 1px solid #dbe1e7;
              height: 44px;
              border-radius: 8px;
              margin: 10px 0;
              padding: 10px;
              align-items: center;
              display: flex;
              position: relative;
              label {
                color: #75787e;
                margin-left: 0.5rem;
              }

              input {
                position: absolute;
                left: 0;
                top: 0;
                opacity: 0;
                height: 100%;
                width: 100%;
              }
            }
          }

          .title {
            display: flex;
            gap: 9px;
            align-items: center;
            color: #0f0f0f;
            position: relative;
            padding: 15px 60px 15px 10px;
            cursor: pointer;

            .field-label {
              display: none;
            }

            .field {
              margin-bottom: 0px;
            }

            .field-input {
              background: transparent;
              border: none;
              outline: none;
              width: 80%;
            }
          }
          .title:hover {
            background-color: #f4f4f4;
          }
          .arrow {
            position: absolute;
            right: 20px;
            cursor: pointer;
          }
        }
      }
      .add-btn {
        font-weight: 500;
        font-size: 14px;
        color: #017cf8;
        background: transparent;
        border: none;
        display: flex;
        align-items: center;
        letter-spacing: -0.02em;
        cursor: pointer;
        padding: 5px 15px;
        border-radius: 5px;

        span {
          font-size: 30px;
          font-weight: 300;
          margin-right: 10px;
          padding-bottom: 3px;
        }
      }

      .add-btn:hover {
        background-color: #f4f4f4;
      }
      .recommendation,
      .email {
        .data {
          .data-holders {
            margin: 25px 0;
            .tag {
              margin-top: 25px;
              padding-bottom: 25px;
              .title {
                color: #75787e;
              }
              .elements {
                display: flex;
                gap: 28px;
                flex-wrap: wrap;
                .color-container {
                  margin-top: 30px;
                  display: flex;

                  align-items: center;
                  gap: 28px;

                  .colorTag {
                    cursor: pointer;
                    margin: 0;
                    height: 37px;
                    width: 61px;
                    border: 1px solid #dbe1e7;
                    border-radius: 8px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 9px;
                    gap: 12px;
                    .color {
                      width: 26px;
                      height: 20px;
                      border-radius: 100%;
                      background: #8ccff2;
                    }
                  }
                }
              }
            }
          }
        }
      }
      .answerError {
        background: #ffe4b4;
        border-radius: 5px;
        font-size: 10px;
        color: #75787e;
        padding: 8px;
        display: flex;
        align-items: center;
        width: 236px;
        margin: 5px 0;
        svg {
          border: 1px solid #f4b037;
          border-radius: 100%;
          height: 13px;
          width: 14px;
          margin-right: 5px;
        }
      }
    }
    .back-btn {
      display: flex;
      button {
        border: none;
        background: #017cf8;
        border-radius: 100%;
        height: 24px;
        width: 24px;
        margin-left: 2rem;
        margin-right: 1rem;
        position: relative;
        svg {
          position: absolute;
          right: 6px;
          bottom: 7.8px;
        }
      }
    }
    .templates,
    .question-types {
      display: flex;
      flex-wrap: wrap;
      padding: 4rem 0;
      gap: 2rem;
      width: 100%;
      .template,
      .question-type {
        display: flex;
        flex-direction: column;
        width: 28%;
        text-align: center;
        img {
          border-radius: 6px;
        }

        p {
          font-size: 12px;
          color: #75787e;
        }
      }
    }
    .question-types {
      width: 100%;
      padding: 1rem 0;
      background: rgba(228, 241, 255, 0.3);
      border-radius: 8px;
      padding: 0 6px 0 6px;
      .title {
        width: 100%;
        position: relative;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #dbe1e7;
        .ques-icon {
          position: absolute;
          left: 0;
          z-index: 1;
        }
        .field-input {
          background: transparent;
          border: none;
          padding-left: 30px;
          outline: none;
        }
      }

      .sample-question {
        width: 100%;
        font-size: 12px;
        padding: 0rem 40px 2rem 40px;
        .inputs {
          display: flex;
          gap: 15px;
          padding: 0;
          padding-top: 12px;
          align-items: center;
          justify-content: space-between;
          padding: 2rem 0;
          font-weight: 500;
          border-bottom: 1px solid #dbe1e7;
        }
        .question {
          display: flex;
          gap: 15px;
          padding-bottom: 36px;
          border-bottom: 1px solid #dbe1e7;
        }
        .field-label {
          color: black;
        }
        .field-input-container-image {
          border: 1px solid #dbe1e7;
          border-radius: 8px;
          .field-input {
            z-index: 0;
          }
        }
        .image-input-field {
          margin: 16px 0;
          max-width: 220px;
        }

        .selection-inputs {
          align-items: center;
          display: flex;
          justify-content: space-between;
        }
        .answer {
          padding-bottom: 26px;

          border-bottom: 1px solid #dbe1e7;
          gap: 15px;
          .add-btn {
            margin-bottom: 67px;
          }
          .tags-container {
            width: 45%;
          }
          .labels {
            display: flex;
            gap: 42%;
            margin-top: 28px;
          }
        }
        .side-image {
          margin-top: 18px;
          div {
            width: 45%;
            border: 1px solid #dbe1e7;
            height: 44px;
            border-radius: 8px;
            margin: 10px 0;
            padding: 10px;
            align-items: center;
            display: flex;
            position: relative;
            label {
              color: #75787e;
              margin-left: 0.5rem;
            }

            input {
              position: absolute;
              left: 0;
              top: 0;
              opacity: 0;
              height: 100%;
              width: 100%;
            }
          }
        }
        .btn-container {
          display: flex;
          justify-content: flex-end;
          gap: 10px;
        }
      }
    }
    .s0,
    .s1 {
      stroke: none !important;
    }
    .type-slide-container {
      img {
        object-fit: cover;
        margin-bottom: 6px;
      }
      p {
        font-size: 14px;
        text-align: center;
        text-transform: capitalize;
        small {
          font-size: 12px;
          color: #75787e;
        }
      }
      &.selected {
        position: relative;
        img {
          border: 1px solid #017cf8;
          border-radius: 4px;
        }
        &:after {
          content: url("../../assets/icons/tick.svg");
          position: absolute;
          left: 50%;
          bottom: 30px;
          transform: translateX(-50%);
          z-index: 1;
        }
      }
    }
    .title-edit {
      input {
        border: none;
        font-size: inherit;
      }
      .icon-button {
        background: none;
        border: none;
        width: 24px;
        cursor: pointer;
        svg {
          height: 24px;
        }
        &:hover {
          svg {
            opacity: 0.7;
          }
        }
      }
    }
    .answer {
      .field {
        width: 50% !important;
      }
      .tags-container {
        .field {
          width: 100% !important;
        }
      }
    }
    .email-segment {
      height: 30px;
      button {
        &:last-child {
          border-left: none;
        }
        &[data-active] {
          background-color: #e4f1ff;
        }
      }
    }
    .add-question {
      .field {
        margin-bottom: 0;
      }
    }
    .product {
      .form-control {
        display: flex;
      }
      .design {
        &-title-section {
          width: 100%;
        }
        &-title {
          padding: 20px 0;
        }
        &-elements {
          padding: 0;
        }
      }
    }
  }
}

#font-form-control {
  width: 100%;

  .fontContainer {
    margin-left: 15px;
  }
}

.product-recommendation-pop-container-minimized {
  width: calc(100% - 70px);
  height: 100%;
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  right: -10px;
  background-color: rgb(0, 0, 0, 0.15);
  z-index: 20000;
}

.product-recommendation-pop-container {
  width: calc(100% - 125px);
  height: 100%;
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  right: -124px;
  background-color: rgb(0, 0, 0, 0.15);
  z-index: 20000;
}

.product-recommendation-pop {
  width: 600px;
  height: 533px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  /* white */

  background: #ffffff;
  box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.15);
  border-radius: 16px;
  padding: 40px;
}

.product-recommendation-pop-upper-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  height: 20px;
  width: 100%;
}

.product-recommendation-pop-circle {
  width: 10px;
  height: 10px;
  margin: 0 5px;
  border-radius: 5px;
}

.product-recommendation-pop-circle-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.product-recommendation-pop-exit-icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.product-recommendation-pop-footer-text {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  /* or 133% */

  text-align: center;
  letter-spacing: -0.02em;

  color: #040404;
}

.product-recommendation-pop-image {
  width: 380px;
  height: 230px;
  margin: 50px 0 20px 0;
}

.product-recommendation-pop-button-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  flex: 1;
}

.product-recommendation-trigger-container {
  position: relative;
  width: 138px;
  height: 48px;

  border-width: 1px 1px 1px 0px;
  border-style: solid;
  border-color: #be6ef7;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.product-recommendation-trigger-image {
  position: absolute;
  width: 53px;
  height: 27.21px;
  top: 12px;
  left: -30px;
}

.product-recommendation-trigger-text {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  letter-spacing: -0.02em;

  color: #be6ef7;

  .pr-page {
    .tabs {
      background: #e4f1ff;
      display: block;
      display: flex;
      width: fit-content;
      border-radius: 8px;
      margin: 24px auto;

      a,
      div {
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        cursor: pointer;
        /* identical to box height, or 171% */

        letter-spacing: -0.02em;
        display: inline-block;
        color: #75787e;
        padding: 12px 32px;
        text-decoration: none;

        &.active {
          background: #017cf8;
          border-radius: 8px;
          color: white;
        }
      }
    }
  }
}


.preview {
  .prv3-page,
  .prv3-fixed-section {
    width: 100% !important;
  }
}
