@import "../../styles/classes";
@import "../../styles/colors";

.app-page {
  height: 100vh;
  width: 100%;
}

.integrations {
  position: relative;

  &-headline {
    font-weight: 500;
    font-size: 24px;
    line-height: 24px;
    display: flex;
    flex-direction: column;
    gap: 10px;

    &-main {
      font-size: 24px;
    }

    &-sub {
      font-size: 14px;
      color: #75787E;
    }

  }

  &-container {
    margin-top: 40px;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;

    &-integration {
      background: #FFFFFF;
      box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.17);
      border-radius: 10px;
      min-width: 200px;
      width: 32vw;
      height: 15vh;
      padding: 30px;
      display: flex;
      gap: 25px;
      cursor: pointer;
      position: relative;

      &-logo {
        min-width: 45px;
        min-height: 45px;
        width: 5vh;
        height: 5vh;
        background: #FFFFFF;
        border: 1px solid #DBE1E7;
        border-radius: 8px;
        position: relative;

        img {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          margin: auto;
          max-width: 70%;
          max-height: 70%;
        }

      }

      &-details {
        font-size: 14px;
        display: flex;
        flex-direction: column;
        gap: 5px;

        &-name {}

        &-description {
          color: #75787E;
        }

      }

    }


  }
}

.integrations-pop-up {
  &-box {
    width: 621px;
    height: 387px;

    background-color: white;
    border-radius: 16px;
    padding: 32px;
  }

  &-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    right: -128px;
    background-color: rgb(15, 15, 15, 0.25);
    z-index: 8;
  }

  &-minimized {
    width: 100%;
    height: 100%;
    display: flex;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    right: 0;
    background-color: rgb(15, 15, 15, 0.25);
    z-index: 8;
  }

  &-header {
    font-family: 'Satoshi';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    /* identical to box height, or 133% */

    letter-spacing: -0.02em;

    /* text / main-black */

    color: #0F0F0F;

    margin-bottom: 12px;
  }

  &-subheader {
    font-family: 'Satoshi';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */

    letter-spacing: -0.02em;

    /* text/secondary */

    color: #75787E;
    margin-bottom: 30px;
  }

  &-input {
    &-container {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      margin-bottom: 40px;
    }

    &-header {

      &-container {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 8px;
      }

      &-text {
        font-family: 'Satoshi';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 24px;
        /* identical to box height, or 200% */

        letter-spacing: -0.02em;

        color: #000000;
        margin-right: 15px;
      }
    }

    &-type {
      width: 238px;
      height: 45px;
      background: #FFFFFF;
      /* stroke */

      border: 1px solid #DBE1E7;
      border-radius: 8px;
      padding: 12px;

      font-family: 'Satoshi';
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 20px;
      /* identical to box height, or 167% */

      letter-spacing: -0.02em;

      /* text/secondary */

      color: #75787E;

      &-left {
        width: 238px;
        height: 45px;
        background: #FFFFFF;
        /* stroke */

        border: 1px solid #DBE1E7;
        border-radius: 8px;
        margin-right: 30px;
        padding: 12px;

        font-family: 'Satoshi';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
        /* identical to box height, or 167% */

        letter-spacing: -0.02em;

        /* text/secondary */

        color: #75787E;
      }

    }
  }

  &-footer {
    font-family: 'Satoshi';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 24px;
    /* identical to box height, or 240% */

    letter-spacing: -0.02em;

    color: #75787E;
    margin-bottom: 40px;
  }

  &-link {
    font-family: 'Satoshi';
    font-style: normal;
    text-decoration: underline;
    font-weight: 500;
    font-size: 10px;
    line-height: 24px;
    /* identical to box height, or 240% */

    letter-spacing: -0.02em;
    color: #017CF8;
  }

  &-button {
    &-container {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
    }
  }
}

.integrations-pop-up-input-type-left:focus,
.integrations-pop-up-input-type:focus {
  border: 1px solid #017CF8;
}



.integrations-delete-pop-up-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  right: -124px;
  background-color: rgb(15, 15, 15, 0.25);
  z-index: 7;
}

.integrations-delete-pop-up-minimized {
  width: 100%;
  height: 100%;
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  right: -10px;
  background-color: rgb(15, 15, 15, 0.25);
  z-index: 7;
}

.integrations-delete-pop-up-box {
  width: 440px;
  height: 220px;
  background-color: #FFFFFF;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  padding: 32px;
}

.integrations-delete-pop-up-details {
  font-family: 'Satoshi';
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  letter-spacing: -0.02em;

  /* text/secondary */

  color: #75787E;
  margin-top: 12px;
}

.integrations-delete-pop-up-button-container {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
}

.integrations-connected-container {
  width: 100%;
  border-bottom: 1px solid #DBE1E7;
  padding-bottom: 15px;
}

.integrations-connected-header {
  font-family: 'Satoshi';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  letter-spacing: -0.02em;

  color: #000000;
}

.integrations-connected {
  background: #FFFFFF;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.17);
  border-radius: 10px;
  width: 350px;
  height: 162px;
  display: flex;
  flex-direction: column;
  padding: 12px;
}

.integrations-connected-top {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.integrations-connected-top-name {
  font-family: 'Satoshi';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */

  letter-spacing: -0.02em;

  color: #000000;
  margin-left: 15px;
}

.integrations-connected-description {
  font-family: 'Satoshi';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */

  letter-spacing: -0.02em;

  color: #75787E;
  margin-top: 5px;
}

.integrations-connected-button-container {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
}

.integrations-connected-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 91px;
  height: 30px;

  background-color: #FFFFFF;
  border: 1px solid #DBE1E7;
  border-radius: 4px;
  font-family: 'Satoshi';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  /* or 133% */

  letter-spacing: -0.02em;

  color: #E25656;
  cursor: pointer;
}




.integrations-all-container {
  width: 100%;
  border-bottom: 1px solid #DBE1E7;
  padding-bottom: 15px;
  margin-top: 50px;
}

.integrations-all-header {
  font-family: 'Satoshi';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  letter-spacing: -0.02em;

  color: #000000;
}

.integrations-all {
  background: #FFFFFF;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.17);
  border-radius: 10px;
  width: 350px;
  height: 162px;
  display: flex;
  flex-direction: column;
  padding: 12px;
}

.integrations-all-top {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.integrations-all-top-name {
  font-family: 'Satoshi';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */

  letter-spacing: -0.02em;

  color: #000000;
  margin-left: 15px;
}

.integrations-all-description {
  font-family: 'Satoshi';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */

  letter-spacing: -0.02em;

  color: #75787E;
  margin-top: 5px;
}

.integrations-all-button-container {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
}

.integrations-all-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 91px;
  height: 30px;

  background-color: #017CF8;
  border: none;
  border-radius: 4px;
  font-family: 'Satoshi';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.02em;
  color: #FFFFFF;
  cursor: pointer;
}

.integrations-delete-pop-up-header {
  font-family: 'Satoshi';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  /* identical to box height, or 133% */

  letter-spacing: -0.02em;

  /* text / main-black */

  color: #0F0F0F;
}



#active-integration {
  border: 2px solid $primary;
}


.checkmark-integration {
  position: absolute;
  top: -10px;
  right: -10px;
  height: 25px;
  width: 25px;
  z-index: 5;
  color: $primary;
  background-color: white;
  border-radius: 100px;
}