@font-face {
  font-family: 'Satoshi';
  src: local('Satoshi'), url(../assets/fonts/Satoshi-Regular.woff) format('woff');
}

@font-face {
  font-family: 'Satoshi';
  src: local('Satoshi'), url(../assets/fonts/Satoshi-Bold.woff) format('woff');
  font-weight: 700;
}

@font-face {
  font-family: 'Satoshi';
  src: local('Satoshi'), url(../assets/fonts/Satoshi-Medium.woff) format('woff');
  font-weight: 500;
}

@font-face {
  font-family: 'Satoshi';
  src: local('Satoshi'), url(../assets/fonts/Satoshi-Black.woff) format('woff');
  font-weight: 900;
}