.profile-page {
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
}

.profile-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 40px 60px;
}

.profile-header {
  font-family: 'Satoshi';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 24px;
  /* identical to box height, or 100% */

  letter-spacing: -0.02em;

  color: #000000;
  margin-bottom: 10px;
}

.profile-subheader {
  font-family: 'Satoshi';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */

  text-align: center;
  letter-spacing: -0.02em;

  color: #75787E;
}

.profile-tag {
  font-family: 'Satoshi';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */


  letter-spacing: -0.02em;

  color: #000000;
  margin-bottom: 20px;
}

.profile-tag-value {
  font-family: 'Satoshi';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height, or 125% */

  letter-spacing: -0.02em;

  color: #75787E;
  margin-bottom: 35px;
}

.profile-image {
  width: 85px;
  height: 85px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 40px 0;
}

.profile-image-text {
  font-family: 'Satoshi';
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 24px;
  /* identical to box height, or 96% */
  letter-spacing: -0.02em;

  color: #FFFFFF;
}

.profile-button-container {
  display: flex;
  flex-direction: row;
}

/* pop up */

.profile-page-popup-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  right: -124px;
  background: rgba(0, 0, 0, 0.15);
}

.profile-page-popup-container-minimized {
  width: 100%;
  height: 100%;
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.15);
}

.profile-page-popup {
  width: 642px;
  min-height: 280px;

  /* white */

  background: #FFFFFF;
  box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.15);
  border-radius: 16px;
  padding: 32px;
}

.profile-page-popup-header {
  font-family: 'Satoshi';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  /* identical to box height, or 133% */

  letter-spacing: -0.02em;

  /* text / main-black */

  color: #0F0F0F;
  margin-bottom: 10px;
}

.profile-page-popup-subheader {
  font-family: 'Satoshi';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  letter-spacing: -0.02em;

  /* text/secondary */

  color: #75787E;
}

.profile-page-popup-options {
  margin: 20px 0;
}

.profile-page-popup-options-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 5px 0;
}

.profile-page-popup-options-value {
  font-family: 'Satoshi';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  letter-spacing: -0.02em;

  color: #000000;
  margin-left: 10px;
}

.profile-page-popup-options-button-container {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-top: 10px;
}

.profile-page-popup-options-button-cancel {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 94px;
  height: 48px;
  cursor: pointer;
  background: none;
  border: none;
}

.profile-page-popup-options-button-confirm {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 94px;
  height: 48px;

  background: #E45447;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  color: white;
}

.profile-page-popup-options-button-disabled {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 94px;
  height: 48px;

  background: #F3F5F7;
  border-radius: 8px;
  border: none;
  color: #75787E;
}

.profile-page-popup-options-input {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex: 1;
  background: #FFFFFF;
  padding: 14px;
  /* stroke */

  border: 1px solid #DBE1E7;
  border-radius: 8px;
  width: 559px;
  height: 85px;

  font-family: 'Satoshi';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  letter-spacing: -0.02em;

  /* text/secondary */
}

.profile-page-popup-options-input::placeholder {
  color: #75787E;
}

.profile-page-popup-options-checkbox {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 14px;
  height: 14px;
  border: 2px solid #017CF8 !important;
  border-radius: 3px !important;
}

.radio-button-popup{
  height: 18px;
  width: 18px;
  border-radius: 40px;
  border: 1.5px solid #017CF8;
  background-color: #E4F1FF;
  display:flex;
  justify-content: center;
  align-items: center;

  .radio-inner-button-popup{
    display: none;
  }
}

#checked-radio-button-popup{
  background-color: white;

  .radio-inner-button-popup{
    background-color: #017CF8;
    height: 8px;
    width: 8px;
    border-radius: 40px;
    display: block;
  }
}