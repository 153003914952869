.onboarding-page {
  display: flex;
  width: 100%;
  height: 100%;

  .slick-track {
    margin: 0 !important;
  }
}

.signup-form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
  justify-content: center;
  height: 100vh;
}

.signup-form-titles-container {
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.onboarding-side-illustrations {
  min-height: 100vh;
  overflow: hidden;
  background-image: url("../../assets/images/onboarding-bg.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #000000;
  display: flex;
  position: relative;
  flex-direction: column;
  gap: 50px;
  align-items: center;
  justify-content: center;
  padding: 32px 25px;
  width: 50%;
}

.skeep-logo-side {
  position: absolute;
  top: 4%;
  left: 4%;
}

.platform {
  &-radio {
    display: flex;
    position: relative;
    display: flex;
    align-items: center;

    &-label {
      font-size: 1rem;
      padding-left: 8px;
    }

    input {
      appearance: none;
      width: 24px;
      height: 24px;
      border: 2px solid #017cf8;
      border-radius: 6px;
      display: flex;
      align-items: center;
      justify-content: center;

      &:checked {
        &:before {
          opacity: 1;
          transition: opacity 0.25s ease;
        }
      }

      &:before {
        content: "";
        width: 50%;
        height: 50%;
        background: #017cf8;
        opacity: 0;
        border-radius: 2px;
      }
    }

    &-arrow {
      position: absolute;
      right: 0;
      height: 100%;
      cursor: pointer;
      transition: transform 0.35s ease-in;
    }
  }

  &-preview {
    width: 48%;
  }
}

.brand-boxes-container {
  display: flex;
  gap: 15px;

  .brand-box {
    width: 150px;
    height: 120px;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    box-shadow: 0 2px 5px rgba(black, 0.13);
  }
}

.trusted-by-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.trusted-by-text {
  font-weight: 600;
  font-size: 16px;
}

.shopping-made-magical {
  width: 400px;
  height: auto;
}

.onboarding-editor {
  width: 90%;
  max-width: 600px;
  height: auto;
}

.onboarding-step {
  width: 50%;
  padding: 2% 5%;
  gap: 38px;
  display: flex;
  flex-direction: column;
}

.collections-selection-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: -48px;
}

.scanning-question {
  font-family: "Satoshi";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 120%;

  color: #000000;
}

.onboarding-titles-container {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-bottom: -15px;

  p {
    color: #75787e;
    font-size: 14px;
  }
}

.collection-list {
  display: flex;
  flex-direction: column;
  overflow: auto;
  height: 280px;
  width: 100%;

  .collection-item {
    display: flex;
    align-items: center;
    gap: 14px;
    width: 100%;
    border-bottom: 1px solid #dbe1e7;
    padding: 16px 0;
    color: #75787e;
  }
}

.scanning-collection-item {
  display: flex;
  align-items: center;
  margin-top: -15px;
  .scanning-title {
    font-family: "Satoshi";
    font-style: normal;
    font-weight: 500;
    margin-left: 25px;

    font-size: 16px;
    line-height: 120%;

    color: #75787e;
  }
}
.onboarding-step-buttons-container {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  gap: 30px;
}

.onboarding-step-text-button {
  color: #75787e;
  cursor: pointer;
}

.step-indicator {
  color: #75787e;
  font-size: 14px;
}

.onboarding-step-button-primary {
  color: white;
  background-color: #017cf8;
  border-radius: 9px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 14px 30px;
  cursor: pointer;
}

.onboarding-step-preview {
  color: #017cf8;
  background-color: #e4f1ff;
  border-radius: 10px;
  cursor: pointer;
  padding: 14px 30px;
}

.onboarding-step-preview:hover {
  color: #0253a5;
  background-color: #d7efff;
}

#disabled-button {
  background-color: #a5a5a5;
}

.scanning-text-container {
  margin: auto;
}
.scanning-text {
  color: #7b1dbd;
  font-size: 30px;
  font-weight: 600;
}

.scanning-text2 {
  margin-top: 8px;
  color: gray;
  font-size: 18px;
}

.colors-widgets-container {
  display: flex;
  width: 100%;
  gap: 30px;
}

.color-widget-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  position: relative;
}

.color-widget-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 25px;
  padding: 20px 20px;
  border-radius: 10px;
  box-shadow: 0 1px 9px rgba(black, 0.15);
  border: 1px solid transparent;

  .color-circle {
    width: 90px;
    height: 90px;
    border-radius: 100px;
  }

  .color-circle-mini {
    width: 60px;
    height: 60px;
  }

  .color-label {
    font-size: 16px;
    font-weight: 600;
  }
}

.space-around {
  justify-content: space-around;
  gap: 0;
}

.color-widget-box:hover {
  border: 1px solid lightgray;
  cursor: pointer;
}

.colors-wizard-content {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.hint-text {
  display: flex;
  font-size: 14px;
      margin-top: -10px;
  align-items: center;
  gap: 8px;
}

.color-picker-widget {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 100%;
  left: 50%;
  z-index: 5;
}

.onboarding-page {
  .answers-container {
    overflow: auto;
    height: 340px;
  }
}

.onboarding-question {
  width: 100%;
  outline: none;
  padding: 10px 10px;
  border: none;
  border-bottom: 1px solid #dcdcdc;
  margin-left: 20px;
}

.onboarding-question-indicator {
  position: absolute;
  bottom: -22px;
  right: 2%;
  font-size: 12px;
  color: gray;
}

.onboarding-preview-button {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background-color: #e4f1ff;
  color: #017cf8;
  padding: 8px 25px;
  cursor: pointer;
  font-size: 14px;
}

.onboarding-preview-button:hover {
  background-color: #c7e2ff;
  color: #0065ce;
}

.embedded-bullets {
  color: gray;
  line-height: 1.8;
}

.code-snippet {
  padding: 6px 18px;
  border-radius: 5px;
  background-color: #f3f5f7;
  color: black;
  margin: 0 8px;
  cursor: pointer;
  align-items: center;
  gap: 10px;
  width: 356px;
  font-size: 13px;
  display: inline-flex;
  justify-content: center;
}

.code-snippet:hover {
  background-color: #d6d8da;
}

#copied-snippet {
  background-color: #e4f1ff;
  color: #017cf8;
}

.onboarding-new-question {
  .questions-slider,
  .labels,
  .allow-multi-answers,
  .answerError,
  .ub-mt_16px,
  .btn-container,
  .connect-products-answer-container {
    display: none !important;
  }

  .field-large {
    width: 100% !important;
  }

  .selection-inputs {
    width: 250px !important;

    svg {
      top: 30px !important;
    }
  }

  .title {
    padding: 0 !important;

    .field-input-container {
      border-bottom: 1px solid gray;
    }

    .textarea {
      padding-bottom: 30px !important;
    }
  }

  .quesIcon {
    background-color: #e4f1ff !important;
  }
}

.onboarding-question-step {
  position: absolute;
  top: 22px;
  left: 49.8%;
  width: 37px;
  height: 37px;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translate(-50%, -50%);
  background-color: #c7e3ff;
  color: #75787e;
  border-radius: 50px;
  font-size: 14px;
  cursor: pointer;

  &-active {
    color: white;
    background-color: #017cf8;
  }
}

.skip-to-dashboard {
  color: #017cf8;
  font-size: 14px;
  cursor: pointer;
  background-color: #e4f1ff;
  border-radius: 5px;
  padding: 8px 12px;
}

.skip-to-dashboard:hover {
  color: white;
  background-color: #017cf8;
}

.book-a-demo-onboarding {
  color: #017cf8;
  font-size: 14px;
  cursor: pointer;
  border: 1px solid #e4f1ff;
  border-radius: 5px;
  padding: 8px 12px;
}

.book-a-demo-onboarding:hover {
  background-color: #e4f1ff;
}

.delete-button {
  z-index: 10;
  cursor: pointer;
  margin-right: 10px;
  position: absolute;
  top: 10px;
  right: -10px;
  width: 27px;
  height: 27px;
  padding: 5px;
  border-radius: 5px;
}

.delete-button:hover {
  background-color: #e3e3e3;
}
