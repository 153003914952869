

.overview-nodata {

  &-cta {
    width: 700px;
    height: 150px;
    background-color:#E4F1FF;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.12);
    border-radius: 16px;
    text-align: center;
    justify-content: center;
    padding: 25px;
    display: flex;
    gap: 20px;
    color: #75787E;
    font-weight: 500;
    font-size: 16px;
    
    .overview-text{
      display: flex;
      flex-direction: column;
    }
 
    p{
      b {
        color: #000000;
      }
      font-size: 16px;
      font-weight: 400;
      margin-top: 9px;
    }
    .create{
      min-height: 34px;
      margin-top: 14px;
      margin-left: auto;
      margin-right: auto;
    }

    &-container {
      display:flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 15px;
      margin-top: 25px;
    }

  }

}

.data-visual-container {
  display:flex;
  flex-wrap: wrap;
  gap: 15px;
  margin-top: 25px;
  justify-content: center;

  img {
    width: 100%;
    height: 100%;
  }

}