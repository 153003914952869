.exp-placement-slider {
  .slick-list {
    overflow: visible;
  }
  .type-slide-container {
    width: 156px;
    height: 130px;
    img {
      height: 90px;
    }
    p {
      margin-top: 10px;
      text-align: center;
      font-size: 12px;
    }
    &.selected {
      position: relative;
      img {
        border: 1px solid #017cf8;
        border-radius: 4px;
      }
      &:after {
        content: url("../../../assets/icons/tick.svg");
        position: absolute;
        left: 47%;
        bottom: 26px;
        transform: translateX(-50%);
        z-index: 1;
      }
    }
  }
  .slider-arrow {
    top: 30% !important;
  }


}
.blur-white {
  width: 50px;
  height: 90px;
  background: linear-gradient(90deg, white, white, transparent);
  position: absolute;
  top: 0;
  left: -50px;
  z-index: 1;
  pointer-events: all;
  &.right {
    background: linear-gradient(90deg, transparent, white, white);
    left: unset;
    right: -50px;
  }
}
