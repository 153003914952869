.verification-page {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.verification-header {
    font-family: 'Satoshi';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 24px;
    /* identical to box height, or 60% */

    letter-spacing: -0.02em;

    color: #000000;
}

.verification-image {
    width: 288px;
    height: 180px;
    margin: 78px auto 63px auto;
    position: relative;
    left: 5.5%  ;
}

.verification-subheader {
    font-family: 'Satoshi';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 16px;
    /* or 80% */

    display: flex;
    align-items: center;
    text-align: center;

    color: #000000;
    margin-bottom: 24px;
}

.verification-footer-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 11px;
}

.verification-footer-container:nth-of-type(1) {
    margin-top: 30px;
}

.verification-footer-container:nth-of-type(2) {
    margin-top: 11px;
    
}

.verification-footer-text {
    font-family: 'Satoshi';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    /* identical to box height, or 133% */

    display: flex;
    align-items: center;

    color: #000000;
    margin-right: 5px;
}

.verification-footer-link {
    font-family: 'Satoshi';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    text-decoration: underline;
    /* identical to box height, or 133% */

    display: flex;
    align-items: center;

    color: #017CF8;
    cursor: pointer;
}

.verification-change-email-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-top: 10px;
}

.verification-change-email-text {
    font-family: 'Satoshi';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    /* identical to box height, or 133% */

    color: #000000;
    margin-bottom: 8px;
}

.verification-change-email-input-container {
    position: relative;
}

.verification-change-email-input {
    width: 430px;
    height: 52px;
    background: #FFFFFF;
    border: 1px solid #9D9D9D;
    border-radius: 8px;

    font-family: 'Satoshi';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    /* identical to box height, or 167% */

    letter-spacing: -0.02em;

    color: #75787E;
    padding: 16px 0 16px 22px;
}

.verification-change-email-button {
    position: absolute;
    width: 116px;
    height: 51px;
    right: 0;
    top: 0;

    background: #017CF8;
    border-radius: 8px;
    outline: none;
    border: none;

    font-family: 'Satoshi';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */

    letter-spacing: -0.02em;

    color: #FFFFFF;
}

.verfication-container{
    margin-top: 30px;
}