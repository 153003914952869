.question {
  font-weight: 400;
  font-size: 11px;
  line-height: 120%;
  

  .question-content {
    padding: 0rem 1.5rem;

    .field {
      margin-bottom: 0;
    }
  }

  .inputs {
    display: flex;
    gap: 15px;
    padding: 0;
    padding-top: 12px;
    margin: 20px 0;
    align-items: center;
    justify-content: space-between;
    padding: 2rem 0;
    font-weight: 500;
    // border-bottom: 1px solid #dbe1e7;
  }

  .question-input,
  .answer-inputs,
  .side-image {
    padding: 25px 0;
    font-weight: 500;
  }

  .answer-inputs {
    width: 100%;

    .field {
      margin-bottom: 0 !important;
    }

    .tags-container {
      // margin-top: 8px;
      width: 40%;
    }

    .answer {
      display: flex;
      gap: 20px;
      gap: 10px;

      .answer-search {
        width: 100%;
        gap: 20px;
        display: flex;
        align-items: center;
        position: relative;
      }
    }
  }

  .side-image {
    div {
      width: 50%;
      border: 1px solid #dbe1e7;
      height: 44px;
      border-radius: 8px;
      margin: 10px 0;
      padding: 10px;
      align-items: center;
      display: flex;
      position: relative;

      label {
        color: #75787e;
        margin-left: 0.5rem;
      }

      input {
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;
        height: 100%;
        width: 100%;
      }
    }
  }

  .arrow {
    position: absolute;
    right: 20px;
    cursor: pointer;
  }
}


.onboarding-page{

  .answer-inputs {
    padding-bottom: 11px;

    border-bottom: 1px solid #dbe1e7;
  }
  .answers-title {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  .title {
    display: flex;
    gap: 9px;
    align-items: center;
    color: #0f0f0f;
    position: relative;
    padding: 18px 0px;
    cursor: pointer;
    .field-label {
      display: none;
    }

    .field {
      margin-bottom: 0px;
    }

    .field-input {
      background: transparent;
      border: none;
      outline: none;
      width: 80%;
    }
  }

  .title:hover {
    background-color: #f4f4f4;
  }

  // margin: auto;
  .quesIcon {
    height: 20px;
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
  }

  .tag-inputs {
    margin-top: 10px;
  }

  .questionNo {
    font-weight: 500;
    font-size: 16px;
    line-height: 32px;
  }

  .questions-list {
    margin: 26px 0;

    .question {
      font-weight: 400;
      font-size: 11px;
      line-height: 120%;
      border-bottom: 1px solid #dbe1e7;

      .question-content {
        padding: 0rem 1.5rem;

        .field {
          margin-bottom: 0;
        }
      }

      .inputs {
        display: flex;
        gap: 15px;
        padding: 0;
        padding-top: 12px;
        margin: 20px 0;
        align-items: center;
        justify-content: space-between;
        padding: 2rem 0;
        font-weight: 500;
        // border-bottom: 1px solid #dbe1e7;
      }

      .question-input,
      .answer-inputs,
      .side-image {
        padding: 25px 0;
        font-weight: 500;
      }

      .answer-inputs {
        width: 100%;

        .field {
          margin-bottom: 0 !important;
        }

        .tags-container {
          // margin-top: 8px;
          width: 40%;
        }

        .answer {
          display: flex;
          gap: 20px;
          gap: 10px;

          .answer-search {
            width: 100%;
            gap: 20px;
            display: flex;
            align-items: center;
            position: relative;
          }
        }
      }

      .side-image {
        div {
          width: 50%;
          border: 1px solid #dbe1e7;
          height: 44px;
          border-radius: 8px;
          margin: 10px 0;
          padding: 10px;
          align-items: center;
          display: flex;
          position: relative;

          label {
            color: #75787e;
            margin-left: 0.5rem;
          }

          input {
            position: absolute;
            left: 0;
            top: 0;
            opacity: 0;
            height: 100%;
            width: 100%;
          }
        }
      }

      .arrow {
        position: absolute;
        right: 20px;
        cursor: pointer;
      }
    }
  }

  .add-btn {
    font-weight: 500;
    font-size: 14px;
    color: #017cf8;
    background: transparent;
    border: none;
    display: flex;
    align-items: center;
    letter-spacing: -0.02em;
    cursor: pointer;
    padding: 5px 15px;
    border-radius: 5px;

    span {
      font-size: 30px;
      font-weight: 300;
      margin-right: 10px;
      padding-bottom: 3px;
    }
  }

  .add-btn:hover {
    background-color: #f4f4f4;
  }

  .answerError {
    background: #ffe4b4;
    border-radius: 5px;
    font-size: 10px;
    color: #75787e;
    top: 1240px;
    padding: 8px;
    display: flex;
    align-items: center;
    width: 95%;
    margin: 5px 0;
    margin-top: 14px;

    svg {
      border: 1px solid #f4b037;
      border-radius: 100%;
      height: 18px !important;
      width: 22px !important;
      margin-right: 5px;
    }
  }

  .infoNotif {
    background: #e4f1ff;
    border-radius: 5px;
    padding: 8px;
    width: 250px;
    margin: 5px 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    svg {
      border: 1px solid #75787e;
      border-radius: 100%;
      height: 13px;
      width: 14px;
      margin-right: 10px;
    }

    p {
      font-size: 10px;
      color: #75787e;
      width: 200px;
    }
  }

  .infoNotifError {
    background: #ffe4b4;
    border-radius: 5px;
    padding: 8px;
    width: 250px;
    margin: 5px 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    svg {
      border: 1px solid #f4b037;
      border-radius: 100%;
      height: 13px;
      width: 14px;
      margin-right: 10px;
    }

    p {
      font-size: 10px;
      color: #75787e;
      width: 200px;
    }
  }

  .infoNotifSuccess {
    background: #cec6f6;
    border-radius: 5px;
    padding: 8px;
    width: 250px;
    margin: 5px 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    svg {
      border: 1px solid #ffffff;
      border-radius: 100%;
      height: 13px;
      width: 14px;
      margin-right: 10px;
    }

    p {
      font-size: 10px;
      color: #ffffff;
      width: 200px;
    }
  }
}


.swiper-container{
  width: 100%;
  max-width: 100%;
  margin-top: -20px;
  height: 380px;
  position: relative;
}

.onboarding-swiper-buttons{
  outline: none;
  border: none;
  width: 25px;
  height: 25px;
  cursor: pointer;
  background-color: white;

  svg{
    stroke:#017CF8;
  }
}

.disabled-button{
  svg{
    stroke:#DBE1E7;
  }
}

.onboarding-swiper-buttons:first-child{
  transform: rotateZ(90deg);
}

.onboarding-swiper-buttons:last-child{
  transform: rotateZ(-90deg);
}

.questions-products-container{
  display: flex;
  flex-direction: column;
}

.questions-products-header{
  display: flex;
  flex-direction: column;
  gap: 20px;
  border-bottom: 1px solid #DBE1E7;
  padding-bottom: 20px;
}

.questions-products-question{
  display: flex;
  align-items:center;
  gap: 10px;

  p{
    color: #75787E;
    font-size: 14px;
  }
}

.question-icon{
  border-radius: 5px;
  width: 20px;
  height: 20px;
  background-color: #C7E3FF;
  display: flex;
  justify-content:center;
  align-items:center;
}

.questions-products-header-answers{
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
#answer-pill-active{
  padding-left: 35px;
}
.answer-pill{
    border-radius: 20px;
    padding: 6px 12px;
    position: relative;
    border: 1.5px solid;
    font-size: 12px;
    cursor: pointer;

    svg{
      position: absolute;
      transform: translate(-50%,-50%);
      top: 50%;
      left: 20px;
    }
}

.wall{
  position: absolute;
  height: 100%;
  width: 30px;
  background: rgb(255,255,255);
  background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
  z-index: 4;
  top: 0;
  right: 0;
}

.side-images-container{
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.side-images-items{

}

.side-images-title{
  font-weight: 600;
}

.side-images-items{
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
}

.side-image-item{
  height: 110px;
  width: 110px;
  border-radius: 20px;
  border: 1px solid #DBE1E7;
  cursor: pointer;
  margin-top: 30px;

  display: flex;
  justify-content:center;
  align-items:center;

  svg{
    width: 50px;
    height: 50px;
  }
}

.preview {
  flex-direction: column;
  .skeep-close {
    display: none !important;
  }

  // .standard-kit-container {
  //   margin-left: 0.5vw;
  //   margin-right: 0.5vw;
  // }

  .skeep-emp-preview .skeep-popup {
    animation: unset !important;
  }

  .skeep-popup-parent {
    border-radius: unset;
    backdrop-filter: unset;
  }

  .standard-answers-container {
    width: 100%;
  }
  .mantine-Alert-root {
    display: none;
  }
  .mantine-Modal-root {
    display: none;
  }
  .prv3-cart-button {
    min-width: 130px;
  }
  .skeep-popup {
    min-height: 500px;
    box-shadow: 0 0 20px #bbb;
    width: 100%;
    max-height: 100%;
    height: 100%;
    max-width: 100%;
    transform: translate(-50%,-59%);
  }
  .skeep-emp-preview {
    .prv3-product-add-container {
      width: 100%;
    }
  }
  #swiper-card-experience {
    width: 32vw;
    height: 42vh;

    max-width: 215px;
    max-height: 550px;
  }

  #swiper-card-title {
    padding-top: 13%;
    font-size: 17px;
  }

  .start-swiper-discover {
    margin-top: 8%;
  }

  .skeep-pr-close {
    display: none;
  }
  .skeep-wrapper
  .prv3-product-section
  .prv3-product-section-cta-container
  .prv3-quantity-indicator {
    width: auto;
  }
  .skeep-wrapper
  .prv3-product-section
  .prv3-product-section-cta-container
  .prv3-quantity-button {
    width: 35px;
  }
  .skeep-emp-preview .swp .swipe-container-card {
    width: 100%;
  }

  .swipe-container {
    height: 75%;
    width: 60%;
    max-width: 500px;
  }

  .skeep-embedded_page {
    top: 30%;
    position: relative;
  }
  .prv3-page,
  #multiple-products-page {
    height: 90% !important;
    position: absolute;
    bottom: 0;
  }

  .skeep-emp-preview {
    animation: unset !important;

    .standard-answers-container-images {
      flex-direction: row !important;
      overflow-x: auto !important;
      flex-wrap: nowrap !important;
      padding-left: 50px !important;
      gap: 10px !important;

      .standard-answer-text {
        padding-top: 6px;
        font-size: 14px;
      }
    }
    .swp-title {
      margin-bottom: 0vh !important;
      z-index: 1;
      position: absolute;
      top: 0;
    }

    .swipe-container-card {
      height: 60%;
      min-height: 340px !important;
    }

    .prv3-page,
    #multiple-products-page {
      height: 85% !important;
      position: absolute;
      bottom: 0;
    }
    .mantine-18eiw81 {
      flex-direction: column;
    }
    .standard-content-container {
      top: 0;
    }
    .skeep-app {
      width: 100%;
      height: 100%;

      .skeep-popup {
        min-width: unset;
        max-height: unset;
        max-width: unset;
      }
    }
    .skeep-embedded_page {
      top: 0;
      position: relative;
    }
  }
  .standard-slider-button {
    transform: translateX(-50%) !important;
    margin-left: 50% !important;
  }

  .prv3-fixed-section {
    position: fixed;
    top: 45%;
  }

  .skeep-popup-close {
    display: none;
  }

  .skeep-wrapper {
    overflow-y: scroll !important;
    height: 83%;
    width: 100%;
    &::-webkit-scrollbar {
      width: 0;
    }

    .prv3-product-section {
      height: 100%;
      //min-height: 100%;
    }
  }

  .skeep-pr-preview {
    //height: unset !important;
  }

  .skeep-design-id {
    position: absolute;
    bottom: 0;
  }

  .skeep-emp-preview {
    //border-radius: 0 0 50px 50px !important;

    //.skeep-wrapper .standard-question-title {
    //  color: #fff !important;
    //}

    .skeep-wrapper {
      height: 100%;
    }

    .skeep-embedded_page {
      margin-top: unset;
    }

    .skeep-popup {
      width: 100%;
      margin: auto;
      bottom: unset;
    }
  }

  display: flex;
  // height: 726px;
  justify-content: center;
  min-width: 33%;
  align-items: center;
  flex: 1;
  width: 100%;
  height: calc(100% - 70px);
  position: relative;

  .screen-size {
    position: absolute;
    top: -40px;
    border-radius: 6px;
    height: 30px;
    display: flex;
    align-items: center;
    .tab-container {
      height: 30px;
      width: 92px;
      border: none;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .tab {
      border: none;
      width: 100px;
      height: 100%;
      text-align: center;
    }
  }

  .preview-frame {
    position: absolute;
    height: 300px;
    z-index: 1;
    transform: scale(0.4);
  }
}

.ub-strk_rgba67-90-111-0-47{
  //stroke: none !important;
  stroke:white !important;
}
