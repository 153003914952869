@import "../../styles/classes";
@import "../../styles/colors";

.app-page.placements-page {
  height: 100vh;
  width: 100%;
  .tags-container {
    z-index: 1;
    position: relative;
  }
  
}


.app-page-header {
  height: 100px;
  width: 100%;
  padding: 0px 50px;
  border-bottom: 1px solid lightgrey;

  .app-page-header-title {
    font-size: 1.4rem;
    font-weight: 500;
  }
}

.app-page-content {
  padding: 25px 50px;
}

.exp-filters-containers {
  .exp-status-item {
    padding: 10px 24px;
    background-color: $secondary;
    color: $text;
    cursor: pointer;
  }

  .exp-status-item:hover {
    background-color: $primary;
    color: white;
  }

  .exp-status-item:first-child {
    border-radius: 5px 0px 0px 5px;
  }

  .exp-status-item:last-child {
    border-radius: 0px 5px 5px 0px;
  }

  .exp-status-active {
    background-color: $primary;
    color: white;
  }

  .exp-type-filters {
    gap: 10px;
    margin-top: 15px;

    .exp-type-filter-item {
      padding: 8px 12px;
      border-bottom: 2px solid transparent;
      cursor: pointer;
      color: $text;
    }

    .exp-type-filter-item:hover {
      color: black;
    }

    .exp-type-filter-active {
      color: black;
      border-bottom: 2px solid $primary;
    }
  }
}

.experiences-container {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  margin-top: 25px;
}

.back-button {
  display: flex;
  align-items: center;
  padding: 8px 20px;
  border-radius: 4px;
  outline: 0;
  border: 0;
  background-color: $secondary;
  cursor: pointer;

  svg {
    margin-right: 10px;
  }
}

.placements-page-titles {
  margin-top: 20px;
 
  h2 {
    font-weight: 500;
  }

  p {
    color: $text;
  }

  .placements-page-subtitle {
    margin: 5px 0px;
  }

  .placements-page-subtitle-placement {
    font-size: 0.9rem;

    svg {
      margin-right: 10px;
    }
  }
}

.add-placement-container {
  padding: 20px 0px;
  color: $primary;
  cursor: pointer;
  width: fit-content;

  svg {
    margin-right: 15px;
  }
}

.placements-page-footer {
  margin-top: 30px;
  display: flex;
  justify-content: flex-end;
}
