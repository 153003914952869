.app-toggle-open-container {
    display: flex;
    flex-direction: column;

}

.app-toggle-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.app-toggle-table-header-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 20% !important;
    text-align: center !important;
}

.app-toggle-table-header-container-active {
    background-color: #F2F8FF;
    border-radius: 8px 8px 0px 0px;
    width: 20% !important;
    text-align: center !important;
}

.app-toggle-table-header-container-skeep-active {
    background-color: #EFE7FE;
    border-radius: 8px 8px 0px 0px;
    width: 20% !important;
    text-align: center !important;
}

.app-toggle-table-header-disabled {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #EEEEEE;
    border-radius: 8px 8px 0px 0px;

    font-family: 'Satoshi' !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 20px !important;
    line-height: 24px !important;
    /* or 120% */

    text-align: center !important;
    letter-spacing: -0.02em !important;
    color: #000000 !important;
}

.app-toggle-table-header {
    font-family: 'Satoshi' !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 20px !important;
    line-height: 24px !important;
    /* or 120% */

    text-align: center !important;
    letter-spacing: -0.02em !important;
    color: #000000 !important;
}

.app-toggle-table-header-active {
    font-family: 'Satoshi' !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 20px !important;
    line-height: 24px !important;
    /* or 120% */

    text-align: center !important;
    letter-spacing: -0.02em !important;
    color: #017CF8 !important;
}

.app-toggle-table-header-skeepx {
    font-family: 'Satoshi' !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 20px !important;
    line-height: 24px !important;
    /* or 120% */

    text-align: center !important;
    letter-spacing: -0.02em !important;
    color: #5B0DF2 !important;
}

.app-toggle-table-header-skeepx-active {
    font-family: 'Satoshi' !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 20px !important;
    line-height: 24px !important;
    /* or 120% */

    text-align: center !important;
    letter-spacing: -0.02em !important;
    color: #5B0DF2 !important;
    background-color: #EFE7FE;
}

.app-toggle-table-subheader {
    font-family: 'Satoshi';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 20px;
    /* or 200% */

    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.02em;

    color: #75787E;
}

.app-toggle-table-features-header {
    font-family: 'Satoshi' !important;
    font-style: normal !important;
    font-weight: 900 !important;
    font-size: 20px !important;
    line-height: 24px !important;
    /* or 120% */

    letter-spacing: -0.02em !important;

    color: #000000 !important;
    width: 20% !important;
    text-align: center !important;
}

.app-toggle-table-features-group-header {
    font-family: 'Satoshi' !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 20px !important;
    line-height: 24px !important;
    /* or 120% */

    letter-spacing: -0.02em !important;

    color: #000000 !important;
    width: 20% !important;
    text-align: flex-start !important;
}

.app-toggle-table-features-tag {
    font-family: 'Satoshi' !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 20px !important;
    /* or 143% */
    letter-spacing: -0.02em !important;

    color: #75787E !important;
    padding: 16px 10px 16px 10px !important;
    width: 20% !important;
}

.app-toggle-table-features-info {
    font-family: 'Satoshi' !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 20px !important;
    /* or 143% */

    text-align: center !important;
    letter-spacing: -0.02em !important;

    color: #75787E !important;
    padding: 16px 10px 16px 10px !important;
    width: 20% !important;
}

.app-toggle-table-features-info-active {
    font-family: 'Satoshi' !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 20px !important;
    /* or 143% */

    text-align: center !important;
    letter-spacing: -0.02em !important;

    color: #75787E !important;
    padding: 16px 10px 16px 10px !important;
    background-color: #F2F8FF;
    width: 20% !important;
}

.app-toggle-table-features-info-disabled {
    font-family: 'Satoshi' !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 20px !important;
    /* or 143% */

    text-align: center !important;
    letter-spacing: -0.02em !important;

    color: #75787E !important;
    padding: 16px 10px 16px 10px !important;
    background-color: #EEEEEE;
    width: 20% !important;
}

.app-toggle-table-features-info-skeep-active {
    font-family: 'Satoshi' !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 20px !important;
    /* or 143% */

    text-align: center !important;
    letter-spacing: -0.02em !important;

    color: #75787E !important;
    padding: 16px 10px 16px 10px !important;
    background-color: #EFE7FE;
    width: 20% !important;
}

.app-toggle-table-features-no-info {
    font-family: 'Satoshi' !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 20px !important;
    /* or 143% */

    text-align: center !important;
    letter-spacing: -0.02em !important;

    color: #75787E !important;
    padding: 16px 10px 16px 10px !important;
    width: 20% !important;
}

.app-toggle-table-features-info-container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.app-toggle-table-features-info-container-active {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #F2F8FF;
    width: 20% !important;
}

.app-toggle-table-features-info-container-disabled {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #EEEEEE;
    width: 20% !important;
}

.app-toggle-table-features-info-top {
    font-family: 'Satoshi' !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 12px !important;
    line-height: 20px !important;
    /* or 143% */
    letter-spacing: -0.02em !important;

    color: #75787E !important;
    text-align: center !important;
}

.app-toggle-table-features-info-bottom {
    font-family: 'Satoshi' !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 10px !important;
    line-height: 20px !important;
    /* or 143% */
    letter-spacing: -0.02em !important;

    color: #75787E !important;
    text-align: center !important;
}

.app-billing-confirm-container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.app-billing-toggle-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 41px;
}

.app-billing-toggle-button {
    background: transparent;
    border: none;
    outline: none;
    font-family: 'Satoshi';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    /* identical to box height, or 133% */

    display: flex;
    align-items: center;
    letter-spacing: -0.02em;
    text-decoration-line: underline;

    color: #017CF8;
}

.app-toggle-card-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
    margin-bottom: 40px;
    flex-wrap: wrap;
}
