.exp-placements-modal {
  overflow: hidden;
  padding: 10px;
  font-family: "Satoshi";
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  background-color: white;

  .placement-heading {
    position: relative;
    margin-top: 8px;
    text-align: start;
    a {
      position: absolute;
      right: -11px;
      top: -10px;
    }
  }
  .placement-btn-container {
    .placement-btn-active {
      background: #017cf8;
      color: #fff;
      box-shadow: -2px 0px 4px rgba(0, 0, 0, 0.25),
        2px 0px 4px rgba(0, 0, 0, 0.25);
    }
    button {
      font-weight: 400;
      cursor: pointer;
      background: #e4f1ff;
      border: none;
      height: 35px;
      width: 129px;
      color: #75787e;
      &:nth-child(1) {
        border-radius: 5px 0 0 5px;
      }
      &:nth-child(3) {
        border-radius: 0 5px 5px 0;
      }
    }
  }
  .url-container {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: black;
    margin-top: 17px;
    font-weight: 500;
  }
  .home-para {
    color: #75787e;
    font-size: 12px;
    margin-top: 2px;
    margin-bottom: 8px;
  }

  .placement-steps-container {
    display: flex;
    align-items: center;
    margin-bottom: 14px;

    p {
      font-size: 14px;
      color: black;
      font-weight: 500;
      margin-top: 22px;
    }
  }
  .placement-steps {
    color: #75787e;
    font-size: 14px;
    line-height: 30px;
    font-weight: 400;
    span {
      display: inline-flex;
      align-items: center;
      background: #f3f5f7;
      border-radius: 5px;
      padding: 0 10px;
      svg {
        margin-left: 1rem;
        &:active,
        &:hover {
          fill: #017cf8;
        }
      }
    }
  }
  .save-changes-button {
    border: none;
    padding: 15px 30px;
    border-radius: 7px;
    cursor: pointer;
    width: fit-content;
    margin-left: auto;
    margin-top: -30px;
    color: #75787e;
    background-color: #f3f5f7;
bottom: 32px;
    right: 32px;
    position:absolute;
    &-active {
      color: #f3f5f7;
      background-color: #017cf8;
    }
  }

  .tags-container {
    width: 240px !important;
  }

  h1 {
    font-weight: 600;
    font-size: 24px;
  }

  h3 {
    color: #75787e;
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 18px;
  }

  .exp-placements-modal-subtitle {
    margin-bottom: 15px;
  }

  .delay-text {
    white-space: nowrap;
    color: #75787e;
    font-size: 12px;
  }
  .content-container {
    display: flex;
    align-items: flex-end;
    gap: 20px;
  }
}

.billing-placements-modal {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
  padding: 10px;
  width: 550px;
  font-family: "Satoshi";
  display: flex;
  flex-direction: column;
  z-index: 99;

  border-radius: 15px;
  background-color: white;
  box-shadow: 0 5px 8px rgba(black, 0.3);
  .placement-heading {
    position: relative;
    margin-top: 8px;
    text-align: start;
    a {
      position: absolute;
      right: -11px;
      top: -10px;
    }
  }
  .placement-btn-container {
    .placement-btn-active {
      background: #017cf8;
      color: #fff;
      box-shadow: -2px 0px 4px rgba(0, 0, 0, 0.25),
        2px 0px 4px rgba(0, 0, 0, 0.25);
    }
    button {
      font-weight: 400;
      cursor: pointer;
      background: #e4f1ff;
      border: none;
      height: 35px;
      width: 129px;
      color: #75787e;
      &:nth-child(1) {
        border-radius: 5px 0 0 5px;
      }
      &:nth-child(3) {
        border-radius: 0 5px 5px 0;
      }
    }
  }
  .url-container {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: black;
    margin-top: 17px;
    font-weight: 500;
  }
  .home-para {
    color: #75787e;
    font-size: 12px;
    margin-top: 2px;
    margin-bottom: 8px;
  }

  .placement-steps-container {
    display: flex;
    align-items: center;
    p {
      font-size: 14px;
      color: black;
      font-weight: 500;
      margin-top: 22px;
    }
  }
  .placement-steps {
    color: #75787e;
    font-size: 14px;
    line-height: 30px;
    font-weight: 400;
    span {
      display: inline-flex;
      align-items: center;
      background: #f3f5f7;
      border-radius: 5px;
      padding: 0 10px;
      svg {
        margin-left: 1rem;
        &:active,
        &:hover {
          fill: #017cf8;
        }
      }
    }
  }
  .save-changes-button {
    border: none;
    padding: 15px 30px;
    border-radius: 7px;
    cursor: pointer;
    width: fit-content;
    margin-left: auto;
    margin-top: 30px;
    color: #75787e;
    background-color: #f3f5f7;

    &-active {
      color: #f3f5f7;
      background-color: #017cf8;
    }
  }

  .tags-container {
    width: 240px !important;
  }

  h1 {
    font-weight: 600;
    font-size: 24px;
  }

  h3 {
    color: #75787e;
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 18px;
  }

  .exp-placements-modal-subtitle {
    margin-bottom: 15px;
  }

  .delay-text {
    white-space: nowrap;
    color: #75787e;
    font-size: 12px;
  }
  .content-container {
    display: flex;
    align-items: flex-end;
    gap: 20px;
  }
}

.billing-placements-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 98;
  background-color: rgba(black, 0.4);
}

.billing-plans-modal-list {
  display: flex;
}
.pane-container {
  ul {
    overflow: auto;
    height: 300px;
    &::-webkit-scrollbar {
      width: 4px;
      background: none;
      border-radius: 10px;
      padding-right: 2px;
    }

    &::-webkit-scrollbar-thumb {
      width: 4px;
      margin-right: 2px;
      background: #4a80ed;
      -webkit-border-radius: 1ex;
    }

    li {
      min-height: 36px;
      display: flex;
      margin-right: 10px;
      align-items: center;
      cursor: pointer;
      padding: 12px;
      font-size: 13px;
      border-radius: 4px;
      div {
        align-items: center;
      }
    }
  }
  padding: 10px;
  :hover {
    background-color: #017cf8;
    color: white;
    box-shadow: 0 3px 5px rgba(black, 0.15)}
}

.ub-max-h_5pu10h{
  max-height: unset;
}



