@import "../../styles/classes.scss";
@import "../../styles/colors.scss";
@import "../../styles/fonts.scss";

.app-page {
    height: 100% !important;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
}

.app-billing-header-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 70px;
    margin-bottom: 24px;
    margin-left: 45px;
}

.app-billing-header {
    width: 100%;
    font-family: 'Satoshi';
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 24px;
    /* identical to box height, or 67% */

    letter-spacing: -0.02em;

    color: #000000;
}

.app-billing-header-italic {
  font-family: 'PT Serif';
  color: #7b1dbd;
  font-weight: 400;
  font-style: italic;
}

.app-billing-card-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    //flex-wrap: wrap;
}

.app-billing-toggle-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 40px 0;
}

.app-billing-toggle-button {
    background: transparent;
    border: none;
    outline: none;
    font-family: 'Satoshi';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    cursor: pointer;
    /* identical to box height, or 133% */

    display: flex;
    align-items: center;
    letter-spacing: -0.02em;
    text-decoration-line: underline;

    color: #017CF8;
}

.app-billing-plan-pop-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    right: -124px;
    background: rgba(0, 0, 0, 0.24);
    z-index: 2;
  }

.app-billing-plan-pop-container-minimized {
  width: 100%;
  height: 100%;
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.24);
  z-index: 2;
}
  
  .app-billing-plan-pop {
    width: 448px;
    height: 220px;
    left: 564px;
    top: 269px;
  
    /* white */
  
    background: #FFFFFF;
    border-radius: 16px;
    padding: 32px;
  }

  .app-billing-plan-pop-downgrade{
    width: 50vw;
    max-width: 700px;
    height: auto;
    left: 564px;
    top: 269px;
  }
  
  .app-billing-plan-pop-skeepx {
    border: 2px solid #5B0DF2;
  }
  
  .app-billing-plan-header {
    font-family: 'Satoshi';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    /* identical to box height, or 133% */
  
    letter-spacing: -0.02em;
  
    /* text / main-black */
  
    color: #0F0F0F;
    margin-bottom: 12px;
  }
  
  .app-billing-plan-header-skeepx {
    color: #5B0DF2 !important;
  }
  
  .app-billing-plan-info {
    font-family: 'Satoshi';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.02em;
  
    /* text/secondary */
  
    color: #75787E;
  }
  
  .app-billing-plan-buttons-container {
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: center;
    margin-top: 20px;
  }
  
  .app-billing-plan-cancel-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 87px;
    height: 48px;
    left: 332px;
    top: 152px;
  
    background: none;
    color: #75787E;
    border-radius: 8px;
    outline: none;
    border: none;
    font-family: 'Satoshi';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */
  
    letter-spacing: -0.02em;
  }
  
  .app-billing-plan-confirm-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 87px;
    height: 48px;
  
    background: #017CF8;
    border-radius: 8px;
    outline: none;
    border: none;
    font-family: 'Satoshi';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */
  
    letter-spacing: -0.02em;
  
    /* white */
  
    color: #FFFFFF;
    margin-left: 15px;
  }

  .billing-user-card-grid{
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin: 20px 0px;
  }

  .billing-user-card{
    display: flex;
    align-items: center;
    padding: 10px 20px;
    background: #f6f6f6;
    border: 1px solid #dedddd;
    border-radius:10px;
    box-shadow: 0px 3px 5px rgba(black,0.05);

    .billing-user-card-property-box{
      display: flex;
      gap: 5px;
      flex-direction: column;
      justify-content: space-between;
      width: 200px;
    }
  }