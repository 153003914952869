@import "../../styles/classes.scss";
@import "../../styles/colors.scss";
@import "../../styles/fonts.scss";

.app-container {
  position: relative;
  display: flex;
  flex-direction: column;
  background: rgba(228, 241, 255, 0.48);
  border: 1px solid #dbe1e7;
  border-radius: 16px;
  margin: 5px 16px;
  margin-top: 185px;
  padding: 0 5px 13px 18px;
  width: 200px !important;
  min-height: 300px !important;
  max-height: 300px !important;
}

.app-container-skeepx {
  position: relative;
  display: flex;
  flex-direction: column;
  background: rgba(91, 13, 242, 0.1);
  border-bottom: 1px solid #dbe1e7;
  border-right: 1px solid #dbe1e7;
  border-left: 1px solid #dbe1e7;
  border-top: none;
  border-radius: 16px;
  margin: 5px 16px;
  margin-top: 185px;
  padding: 0 5px 13px 18px;
  width: 200px !important;
  min-height: 300px !important;
  max-height: 300px !important;
}

.app-container-current {
  position: relative;
  display: flex;
  flex-direction: column;
  background: rgba(255, 255, 255, 0.19);
  border-bottom: 1px solid #dbe1e7;
  border-right: 1px solid #dbe1e7;
  border-left: 1px solid #dbe1e7;
  border-top: none;
  border-radius: 16px;
  margin: 5px 8px;
  margin-top: 185px;
  padding: 0px 0px 0px 18px;
  width: 200px !important;
  min-height: 300px !important;
  max-height: 300px !important;
}

.pricing-mini-card {
  max-height: none !important;
  min-height: fit-content !important;
  margin-top: 150px;
  height: 205px;
}

.app-container-selected {
  position: relative;
  display: flex;
  flex-direction: column;
  background: rgba(255, 255, 255, 0.19);
  border: none;
  border-radius: 16px;
  margin: 5px 16px;
  margin-top: 185px;
  padding: 0 5px 13px 26px;
  width: 200px !important;
  min-height: 300px !important;
  max-height: 300px !important;
}

.app-container-disabled {
  position: relative;
  display: flex;
  flex-direction: column;
  background: rgba(250, 250, 250, 0.73);
  border-radius: 16px;
  margin: 5px 16px;
  margin-top: 185px;
  padding: 0 5px 13px 26px;
  width: 200px !important;
  min-height: 300px !important;
  max-height: 300px !important;
}

.app-image {
  &-Free {
    top: -85px;
    position: absolute;
    left: 0;
    width: 200px;
    height: auto;
    object-fit: contain;
    z-index: 1;
  }

  &-Basic {
    top: -95px;
    position: absolute;
    left: 0;
    width: 200px;
    height: auto;
    object-fit: contain;
    z-index: 1;
  }

  &-Growth {
    top: -60px;
    position: absolute;
    left: 0;
    width: 200px;
    height: auto;
    object-fit: contain;
    z-index: 1;
  }

  &-Platinum {
    top: -110px;
    position: absolute;
    left: 0;
    width: 200px;
    height: auto;
    object-fit: contain;
    z-index: 1;
  }

  &-SkeepX {
    top: -65px;
    position: absolute;
    left: 0;
    width: 200px;
    height: auto;
    object-fit: contain;
    z-index: 1;
  }
}

.app-header {
  font-family: "Satoshi";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 20px;
  /* identical to box height, or 100% */
 text-align: start;
  letter-spacing: -0.02em;

  color: $primary;
  margin: 0 0 10px 0 !important;
  padding-top: 45px;
}

.app-header-skeepx {
  font-family: "Satoshi";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 20px;
  /* identical to box height, or 100% */
  text-align: start;
  letter-spacing: -0.02em;

  color: #7B1DBD;
  margin: 0 0 10px 0 !important;
  padding-top: 45px;
}

.app-header-disabled {
  font-family: "Satoshi";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 20px;
  /* identical to box height, or 100% */

  letter-spacing: -0.02em;

  color: $text;
  margin: 0 0 22px 0 !important;
  padding-top: 58px;
}

.app-header-no-pricing {
  font-family: "Satoshi";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 20px;
  /* identical to box height, or 100% */

  letter-spacing: -0.02em;

  color: #5b0df2;
  margin: 0 0 22px 0 !important;
}

.app-pricing {
  font-family: "Satoshi";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  text-align: start;
  /* identical to box height, or 120% */

  letter-spacing: -0.02em;

  color: $primary;
  margin-bottom: 15px;
}

.app-pricing-big {
  font-family: "Satoshi";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
}

.app-pricing-big-disabled {
  font-family: "Satoshi";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 24px;
  /* identical to box height, or 120% */

  letter-spacing: -0.02em;

  color: $text;
}

.app-pricing-disabled {
  font-family: "Satoshi";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height, or 120% */

  letter-spacing: -0.02em;

  color: $text;
  margin-bottom: 15px;
}

.app-no-pricing {
  font-family: "Satoshi";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 24px;
  text-align: start;
  /* identical to box height, or 100% */

  letter-spacing: -0.02em;

  color: #7B1DBD;
  margin-bottom: 20px;
}

.app-limits-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100px !important;
}

.app-description-container {
  height: 40px;
  margin-bottom: 12px;
}

.app-description {
  font-family: "Satoshi";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  /* or 143% */

  letter-spacing: -0.02em;

  color: $primary;
  margin-bottom: 6px;
}

.app-description-current {
  font-family: "Satoshi";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  /* or 143% */

  letter-spacing: -0.02em;

  color: $text;
  margin-bottom: 6px;
}

.app-description-disabled {
  font-family: "Satoshi";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  /* or 143% */

  letter-spacing: -0.02em;

  color: $text;
  margin-bottom: 6px;
}

.app-description-no-pricing {
  font-family: "Satoshi";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  /* or 143% */

  letter-spacing: -0.02em;

  color: #5b0df2;
  margin-bottom: 6px;
}

.app-included-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 6px 0;
}

.app-included-check {
  height: 7px;
  margin-right: 6px;
  margin-top: 8px;
}

.app-included {
  font-family: "Satoshi";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */

  letter-spacing: -0.02em;

  color: $text;
}

.app-included-info-container {
  display: flex;
  justify-content: flex-start;
  align-self: flex-start;
}

.app-included-info {
  width: 8px;
  height: 8px;
  margin-left: 5px;
  margin-top: 8px;
}

.app-limits {
  font-family: "Satoshi";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  /* or 143% */

  letter-spacing: -0.02em;

  color: $text;
  margin: 4px 0;
}
