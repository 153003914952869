.gallery-container {
  .gallery-header {
    height: 65px;

    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #dbe1e7;
    margin-bottom: 9px;
    p {
      color: #0f0f0f;
      margin-top: 14px;
      margin-left: 27px;
      font-family: "Satoshi";
      font-size: 18px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: -0.02em;
    }
    svg {
      margin-top: 10px;
      margin-right: 22px;
      width: 30px;
      height: 30px;
      cursor:pointer;
    }
  }
  .content-container {
    display: flex;
    // background-color: rgb(157, 196, 206);
    height: 400px;

    .upload-image {
      width: 300px;

      p {
        font-size: 16px;
        color: #75787e;
        margin-right: 128px;
        margin-top: 9px;
      }
      .upload-field {
        margin-left: 26px;
        margin-top: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 234px;
        border: 2px dashed #017cf8;
        // border-color: #017cf8;
        width: 250px;
        div {
          display: flex;
          position: relative;
          height: 100%;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          .upload-btn {
            &::-webkit-file-upload-button {
              opacity: 0;
              color: rgba(0, 0, 0, 0);
            }
            height: 100%;
          }
          svg {
            position: absolute;
            top: 20;
            margin-bottom: 6px;
            width: 22px;
            margin-top: -21px;
            height: 22px;
          }
        }
        .upload-field-text {
          margin-left: 0px;
          margin-top: 122px;
          color: #0f0f0f;
          font-family: "Satoshi";
          width: 130px;
          font-size: 16px;
          font-weight: 400;
          line-height: 16px;
          letter-spacing: -0.02em;
          text-align: center;
        }
      }
      .alert-container {
        width: 250px;
        height: 60px;
        margin-top: 10px;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        margin-left: 26px;
        background-color: #e4f1ff;
        .alert-text {
          font-family: "Satoshi";
          font-size: 16px;
          text-align: center;
          color: #75787e;
          padding: 7px 5px 6px 4px;
          line-height: 20px;
          letter-spacing: -0.02em;
          text-align: center;
        }
      }
      .preview-image {
        width: 90%;
        height: 80%;
        object-fit: cover;
        border-radius: 5px;
        margin-top: 20px;
        margin-left: 15px;
      }
    }
    .divider-container {
      height: 322px;
      margin-top: 44px;
      background: #e3e3e3;
      .divider {
        width: 2px;
        background: #e3e3e3;
      }
    }

    .used-recently-container {
      margin-left: 24px;
      width: 300px;

      .image-container {
        display: grid;
        max-height: 325px;
        margin-top: 13px;
        overflow-y: auto;
        overflow-x: hidden;
        grid-gap: 15px;
        padding-right: 20px;
        grid-template-columns: repeat(3, 1fr);
        &::-webkit-scrollbar {
          width: 4px;
          background: none;
          border-radius: 10px;
          padding-right: 2px;
        }
        &::-webkit-scrollbar-thumb {
          width: 4px;
          margin-right: 2px;
          background: #858585;
          -webkit-border-radius: 1ex;
        }
      }
      .used-recently-text {
        color: #75787e;
        font-family: "Satoshi";
        font-size: 16px;
        margin-top: 9px;
        line-height: 24px;
        letter-spacing: -0.02em;
        text-align: left;
        
        
      }
      
      .used-recently-image {
        height: 90px;
        object-fit: cover;
        border-radius: 5px;
        width: 85px;
        position: relative;
      
        
      }
      .used-recently-image-selected {
        height: 90px;
        object-fit: cover;
        border-radius: 5px;
        width: 85px;
        position: relative;
        border: 3px solid  #017CF8;
        
      }
      .check-icon{
        position: absolute;
        bottom: -5px;
        width: 25px;
        height: 25px;
        margin-top: 75px;
        margin-left: -55px;
      }
    }
  }
  .image-gallery-dialog-crop-btn{
        background: #017cf8;
    cursor: pointer;
    border-radius: 5px;
    width: 64px;
    display: inline-flex;
    align-items: center;
    color: white;
    justify-content: center;
    height: 32px;
    font-family: "Satoshi";
    text-align: center;
    font-size: 16px;
    margin-top: 13px;
    margin-left: 13px;
    float: right;
    border: none;

  }
  .image-gallery-dialog-btn {
    background: #017cf8;
    border-radius: 5px;
    width: 100px;
    display: flex;
    cursor: pointer;
    align-items: center;
    color: white;
    justify-content: center;
    height: 41px;
    font-family: "Satoshi";
    text-align: center;
    font-size: 16px;
    margin-bottom: 13px;
    border: none;
    margin-right: 20px;

    &:disabled{
        background: #e3e3e3;
        color: #75787e;
        cursor: not-allowed;
    }
    
  }
}
