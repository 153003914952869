.design-v2-error {
  color: #f43737;
  font-size: 14px;
  display: flex;
  gap: 12px;
  align-items: center;
  border: 1px solid rgba(#f43737, 0.5);
  background-color: rgba(#f43737, 0.05);
  border-radius: 5px;
  padding: 10px 20px;

  position: absolute;
  left: 50%;
  white-space: nowrap;
  top: 100px;
  transform: translate(-50%,-50%);
}