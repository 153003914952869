@import "../../styles/colors";

.sidebar {
  height: 100vh;
  width: 248px;
  background-color: #e4f1ff;
  box-shadow: 0 0 10px #d8d3d3;
  white-space: nowrap;
  position: absolute;
  transition: width 0.6s ease;
  position: fixed;
  z-index: 10;

  * {
    text-decoration: none;
  }

  .separator {
    background: #363636;
    width: 100%;
    height: 1px;
    opacity: 0;
    transition: opacity 0.6s ease;
  }

  svg {
    flex-shrink: 0;
  }
  .logout {
    width: 100%;
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
  }

  &.minimized {
    width: 80px;
    .sidebar-option:hover::after {
      content: attr(title);
      font-size: 14px;
      position: fixed;
      left: 86px;
      padding: 8px;
      z-index: 1;
      color: #000;
      background: #e4f1ff;
      border-radius: 8px;
      box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
    }

    .sidebar-container {
      width: 80px;
      overflow: visible;
    }
    .separator {
      opacity: 1;
    }
    .sidebar-minimize {
      background: #017cf8;
      box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
      &:hover {
        background: #017cf8;
      }
      svg {
        transform: rotate(180deg);
        stroke: #fff;
      }
    }

    .sidebar-hidden {
      visibility: hidden;
    }
    .sidebar-options-title {
      opacity: 0;
      visibility: hidden;
      font-size: 0;
      padding: 0;
    }
    .sidebar-logo {
      width: 100%;
      text-align: center;
    }

    .sidebar-options {
      border-bottom-color: #363636;
      width: 80px;
    }
  }

  &-logo {
    padding: 0 16px;
    padding-bottom: 12px;
  }

  &-container {
    width: 248px;
    min-height: 80%;
    z-index: 1;
    top: 0;
    height: 100%;
    left: 0;
    overflow-x: hidden;
    padding-top: 60px;
    transition: all 600ms ease;
    color: #75787e;
    padding: 15px 0 32px 0;
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
    -ms-overflow-style: none; // IE 10+
    overflow: -moz-scrollbars-none; // Firefox

    &::-webkit-scrollbar {
      display: none; // Safari and Chrome
    }
  }
  &-minimize {
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
    background: #017cf8;
    &:hover {
      background: $primary;
    }
    svg {
      stroke: #fff;
    }
    border-radius: 50%;
    width: 24px;
    height: 24px;
    position: absolute;
    right: -12px;
    top: 40px;
    left: auto;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  &-options {
    padding: 0px 16px;
    &-title {
      color: black;
      font-weight: 700;
      font-size: 0.8rem;
      text-transform: uppercase;
      padding: 0 12px 10px;
      transition: all 0.3s ease;
    }
    &.logout {
      margin-top: auto;
    }
  }

  &-option {
    height: 48px;
    width: 100%;

    cursor: pointer;
    color: black;
    font-size: 16px;
    position: relative;
    display: flex;
    background: #e4f1ff;
    border: none;
    align-items: center;
    border-radius: 8px;
    padding: 0 12px;
    transition: all 0.3s ease-in-out;
    margin-top: 8px;
    margin-bottom: 15px;
    svg {
      width: 20px;
      margin-left: 2px;
      height: 20px;
    }
    &.active,
    &:hover {
      background: $primary;
      color: #fff;

      svg > path {
        fill: #fff;
        stroke: #fff;
      }
      svg > rect {
        fill: #fff;
        stroke: #fff;
      }
    }

    > span {
      padding-left: 18px;
    }
  }

  .upgrade-plan-btn {
    margin-top: 15px;
    margin-bottom: 8px;
    display: flex;
    width: 100%;
    align-items: center;
    color: #ffffff;
    background: #7b1dbd;
    border: none;
    height: 48px;
    cursor: pointer;
    border-radius: 8px;
    svg {
      margin-left: 14px;
    }
    .upgrade-text {
      color: #ffffff;
      margin-left: 10px;
      font-size: 14px;
    }
  }

  .taanko-contanier {
    display: flex;
    align-items: center;
    background: #ffffff;
    color: black;
    margin-top: 22px;
    height: 48px;
    border-radius: 8px;
    margin-bottom: 23px;
    svg {
      margin-left: 12px;
    }
    span {
      margin-left: 9px;
    }
    .dot-icon {
      margin-left: 85px;
      cursor: pointer;
    }
    .logout-btn {
      background: red;
    }
  }
}

.sidebar-blessing {
  padding: 0px 16px;
  margin-bottom: 10px;
  color: black;
  font-size: 1rem;
  width: 100%;
  white-space: pre-line;
}

.sidebar-tabs-container {
  position: relative;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100%;
  padding-bottom: 13vh;
}

.sidebar-tabs-container::-webkit-scrollbar {
  width: 4px;
  background: none;
  border-radius: 10px;
  padding-right: 2px;
}

.sidebar-tabs-container::-webkit-scrollbar-thumb {
  width: 4px;
  margin-right: 2px;
  background: #007cf8;
  -webkit-border-radius: 1ex;
}

.click-listener-sidebar {
  display: flex;
  align-items: center;
  gap: 18px;
  // margin-top: 16px;
  // margin-bottom: 16px;
  width: 100%;
  height: 100%;
}

.sub-sidebar-container {
  background-color: rgb(244, 248, 254);
  position: absolute;
  top: 0;
  left: 80px;
  height: 100%;
  width: 210px;
  transition: left 0.6s ease;
  &.extra-padding {
    left: 248px;
  }
  h1 {
    font-size: 24px;
    margin-top: 41px;
    font-weight: 500;
    margin-left: 39px;
    font-family: "Satoshi";
  }
  .option {
    height: 48px;
    width: 192px;
    cursor: pointer;
    color: black;
    font-size: 16px;
    position: relative;
    display: flex;
    border: none;
    align-items: center;
    border-radius: 8px;
    padding: 0 12px;
    transition: all 0.3s ease-in-out;
    margin-top: 5px;
    svg {
      width: 20px;
      height: 20px;
    }
    &.active,
    &:hover {
      background: $primary;
      color: #fff;

      svg > path {
        fill: #fff;
        stroke: #fff;
      }
      svg > rect {
        fill: #fff;
        stroke: #fff;
      }
    }

    > span {
      padding-left: 18px;
    }
  }
}
.sidebar-logout {
  margin-top: 23px;
  font-size: 16px;
  background: red;
  color: #000000;
}

.store-icon {
  margin-bottom: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  background-color: #ffffff;
  height: 48px;
  width: 48px;
  cursor: pointer;
}

.help-center-btn {
  height: 48px;
  width: 200px;
  display: flex;
  align-items: center;
  border-radius: 8px;
  margin: 0px 8px;
  cursor: pointer;

  svg {
    height: 20px;
    width: 20px;
  }

  &:hover {
    background: $primary;
    color: #fff;

    #schedule{
      fill: #fff;
    }
    svg > path {
      fill: #fff;
      stroke: #fff;
    }
    svg > rect {
      fill: #fff;
      stroke: #fff;
    }
  }
}

.support-btn {
  display: flex;
  height: 48px;
  align-items: center;
  width: 200px;
  border-radius: 8px;
  margin-left: 8px;
  cursor: pointer;

  &:hover {
    background: $primary;
    color: #fff;

    svg > path {
      fill: #fff;
      stroke: #fff;
    }
    svg > rect {
      fill: #fff;
      stroke: #fff;
    }
  }
}

.brand-add-account-container {
  margin: 0px 8px 0px -8px;
  border-radius: 8px;
  width: 200px;
  &:hover {
    background: $primary;
    color: #fff;
    .brand-add-account-text {
      color: #fff;
    }
    svg > path {
      fill: #fff;
      stroke: #fff;
    }
    svg > rect {
      fill: #fff;
      stroke: #fff;
    }
  }
}

#sidebar-active {
  background: #017cf8;

  svg > path {
    fill: #fff;
    stroke: #fff;
  }
  svg > rect {
    fill: #fff;
    stroke: #fff;
  }
}
