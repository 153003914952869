@import "../../styles/classes";

.app-page {
  height: 100vh;
  width: 100%;
}

.billing {

  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  gap: 25px;

  &-plan {
    display: flex;
    width: 100%;
    flex-wrap: wrap-reverse;
    justify-content: space-between;

    &-details {
      font-style: normal;
      font-weight: 500;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0;
      gap: 8px;

      &-name {
        font-size: 24px;
      }

      &-subtitle {
        font-size: 14px;
        color: #75787E;
      }

    }

  }

  &-payment {
    background: #FFFFFF;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.17);
    border-radius: 16px;
    border: 1px solid #DBE1E7;
    width: 100%;
    height: 25vh;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding: 20px 27px;
  }

  &-headlines {
    background: #FFFFFF;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.17);
    border-radius: 16px;
    border: 1px solid #DBE1E7;
    width: 100%;
    height: 15vh;
    display: flex;

    &-border {
      height: 60%;
      width: 1px;
      border-left: 2px solid #DBE1E7;
      background: #DBE1E7;
      margin-top: 4vh;
    }

    &-headline {
      min-width: 25%;
      padding-top: 10px;
      padding-left: 18px;
      padding-right: 18px;
      display: flex;
      justify-content: space-around;
      flex-direction: column;

      &-data {
        display: flex;
        font-weight: 500;


        &-status {
          font-size: 24px;

          b {
            font-size: 32px;
          }
        }


      }

      &-title {
        width: 100%;
        font-weight: 500;
        font-size: 15px;
        line-height: 20px;

      }


    }

  }


}

.billing-payment-left-container {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.billing-payment-right-container {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.billing-payment-header {
  font-family: 'Satoshi';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  letter-spacing: -0.02em;

  color: #000000;
}

.billing-payment-subheader {
  font-family: 'Satoshi';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;
  /* identical to box height, or 200% */

  letter-spacing: -0.02em;

  color: #75787E;
  margin-top: 10px;
}

.billing-payment-info-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.billing-payment-info-text-one {
  font-family: 'Satoshi';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;
  /* identical to box height, or 200% */

  letter-spacing: -0.02em;

  color: #000000;
  margin-left: 5px;
}

.billing-payment-info-link {
  font-family: 'Satoshi';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */

  letter-spacing: -0.02em;
  text-decoration-line: underline;

  color: #017CF8;
  margin-left: 59px;
}

.billing-invoices-header {
  font-family: 'Satoshi';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  text-align: start;
  letter-spacing: -0.02em;

  color: #000000;
}