.signup-page {
    flex: 1
}

.error-field {
    color: red;
}

.form-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;

}


.auth-title-new {
    font-family: 'Satoshi';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 24px;
    /* identical to box height, or 60% */

    letter-spacing: -0.02em;

    color: #000000;
    margin-bottom: 15px;
}

.auth-link-new {
    text-decoration: underline;
}

.remember-me-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 430px;
    margin-top: 25px;
}

.remember-me {
    font-family: 'Satoshi';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    /* identical to box height, or 133% */

    display: flex;
    align-items: center;

    color: #017CF8;
    margin-left: 10px;
}



.signup-button-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 25px 12px 12px;
    gap: 6px;
    width: 429px;
    height: 56px;

    background: #017CF8;
    border-radius: 8px;
    outline: none;
    border: none;
    margin-top: 24px !important;
}

.signup-button {
    font-family: 'Satoshi';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */

    letter-spacing: -0.02em;

    color: #FFFFFF;
}

.signup-button-google-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 25px 12px 12px;
    gap: 6px;
    width: 429px;
    height: 56px;
    background: #FFFFFF;
    border: 1px solid #9D9D9D;
    border-radius: 8px;
}

.signup-button-google {
    font-family: 'Satoshi';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    /* identical to box height, or 167% */

    letter-spacing: -0.02em;

    color: #000000;
}

.signup-already-have-account-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 19px;
}

.signup-already-have-account {
    font-family: 'Satoshi';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    /* identical to box height, or 133% */

    display: flex;
    align-items: center;

    color: #000000;
    margin-right: 5px;
}

.signup-already-have-account-link {
    font-family: 'Satoshi';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    /* identical to box height, or 133% */

    display: flex;
    align-items: center;

    color: #017CF8;
}

.signup-or-container {
    width: 429px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 14px 0 10px 0;
}

.signup-or {
    font-family: 'Satoshi';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    /* identical to box height, or 167% */

    text-align: center;
    letter-spacing: -0.02em;

    color: #75787E;
}

.signup-or-line {
    width: 180px;
    border: 1px solid #D9D9D9;
}

.unauthorized-container{

    display: flex;
    flex-direction: column;
    gap: 6px;
    margin: 10px auto 10px 0px;

    p{
        margin: 0;
    }

    .auth-link{
        cursor: pointer;
    }
}