.explore-button {
    background-color: #F4B037 !important;
    border-color: #F4B037 !important;
    width: 128px;
    font-weight: bold;
    font-size: 14px;
    height: 36px;
    border: none;

    &:hover {
        background: #F4B037 !important;
    }
    &:focus {
        background: #F4B037 !important;
    }
}