@import "./src/styles/colors";

.infoNotif {
  background: #e4f1ff;
  border-radius: 5px;
  padding: 8px;
  width: 250px;
  margin: 5px 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  svg {
    border: 1px solid #75787e;
    border-radius: 100%;
    height: 13px;
    width: 14px;
    margin-right: 10px;
  }

  p {
    font-size: 10px;
    color: #75787e;
    width: 200px;
  }
}

.tags-content {
  font-weight: 400;
  font-size: 12px;
  margin-top: 42px;
  width: 85%;
  .tags-container {
    width: 50%;
  }
  .colorTag {
    width: 116px;
  }
  .tags-question {
    label {
      margin: 0 !important;
    }

    &-tagsHolder {
      width: 80%;
      margin-left: 10%;
      margin: 30px 0 30px 10%;
      .field {
        margin-bottom: 0;
        width: 60% !important;
        height: 35px;
        label {
          width: 100%;
          height: 37px;
        }
        .edit-image-text {
          border-radius: 8px 0 0 8px;
        }
      }
      .field-input-container {
        height: 30px;
      }
      input {
        height: 30px;
        border: none;
        outline: none;
        padding: 0;
      }
      &-tag {
        display: flex;
        justify-content: space-between;
        margin: 25px 0;
        div:nth-child(1),
        div:nth-child(3) {
          display: flex;
          align-items: center;
          gap: 10px;
        }

        div:nth-child(3) {
          gap: 20px;
        }
        &-selector {
          width: 116px;
          height: 37px;
          border: 1px solid #dbe1e7;
          border-radius: 8px;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 12px;
          color: #75787e;
          font-size: 8px;
          .colorTag {
            width: 116px !important;
          }
        }
      }
    }
  }
  .selection-tags,
  .tags-recommendation,
  .tags-product,
  .tags-question {
    border-bottom: 1px solid #dbe1e7;

    .data {
      margin: 20px 0 5rem 0;
      font-weight: 500;
      padding: 0px 10px;

      .data-holders {
        //padding: 12px 0;
      }
    }
    .elements {
      display: flex;
      gap: 29px;
      flex-wrap: wrap;
      margin: 27px 0;
      width: 90%;
    }
    .tag {
      width: 160px;
      color: #75787e;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .colorTag {
        cursor: pointer;
        margin: 0;
        height: 37px;
        width: 61px;
        border: 1px solid #dbe1e7;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 9px;
        gap: 12px;
        .color {
          width: 20px;
          height: 20px;
          border-radius: 100%;
          background: #8ccff2;
        }
      }
    }
  }
  .title:hover {
    background-color: #f4f4f4;
  }
  .title {
    display: flex;
    position: relative;
    align-items: center;
    gap: 14px;
    height: 60px;
    cursor: pointer;
    // padding: 0px 10px;

    .arrow {
      position: absolute;
      right: 20px;
      cursor: pointer;
    }
  }
}

.remove-tags-input-container {
  position: relative;

  .remove-tags-input-search {
    position: absolute;
    top: 45%;
    left: 10px;
    width: 22px;
    height: 22px;
  }
}

.remove-tags-input {
  padding: 15px 15px 15px 40px;
  height: 52px;
  border: 1px solid #dbe1e7;
  border-radius: 8px;
  color: #75787e;
  margin-top: 12px;
}

.remove-tags-input:focus {
  border: 1px solid $primary;
}

.editor-quiz-intro {
  .data {
    .data-holders {
      margin: 25px 0px;
    }
  }
}
