.auth-page{
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .auth-finish{
        display: flex;
        align-items: center;
        color: #75787E;
        margin-top: 40px;
        .button.submit{
            min-width: 107px;
            margin-right: 24px;
        }
        .auth-link{
            padding-left: 8px;
        }
    }
    
    .auth-link{
        color: #017CF8;
        font-weight: 500;
    }
    .auth-back{
        color: #75787E;
        font-size: 0.875rem;
        margin-bottom: 40px;
        display: flex;
        align-items: center;
        svg{
            margin-right: 15px;
        }
       
    }
    .auth-title{
        font-size: 2rem;
        padding-bottom: 20px;
        font-weight: 400;
    }
    .auth-subtitle{
        font-size: 0.9rem;
        margin-bottom: 32px;
        font-weight: 400;
        color: #75787E;
    }

    .field{
        width: 100%;
        max-width: 100%;
    }
    .auth-form{
        &-container{
            flex: 1 1;
            display: flex;
            align-items: center;
            flex-direction: column;
            width: 100%;

            form{
                width: 100%;
                max-width: 420px;
            }
        }
       
    }

}