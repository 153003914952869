.trial-ends-hint{
  background-color: #FFE4B4;
  color: #75787E;

  display: flex;
  justify-content: center;
  align-items:center;
  padding:10px 0;
  font-size:14px;

  #upgrade{
    color: #017CF8;
    text-decoration: underline;
    cursor: pointer;
  }
}