
$field-space-bottom: 0px;

.field {
  width: 100%;
  display: flex;
  flex-direction: column;
  font-size: 0.875rem;
  color: #75787e;
  margin-bottom: $field-space-bottom;
  &.field-date {
    input {
      padding-right: 40px;
    }
  }
  &-color {
    .field-input {
      padding-left: 40px;
    }
    .input-color-picker {
      position: absolute;
      top: 0;
      width: 100%;
      left: 14px;
      bottom: 0;
      margin: auto;
      display: flex;
      align-items: center;
    }
    .color-picker {
      &-container {
        position: relative;
        margin-right: 16px;
      }
      &-button {
        width: 20px;
        height: 20px;
        border: 1px solid #dbe1e7;
        border-radius: 50%;
        cursor: pointer;
      }
      &-tool-bar {
        position: absolute;
        bottom: 60px;
        width: 100%;
        left: 0;
        top: auto;
        display: none;
        .chrome-picker {
          width: 100% !important;
        }

        &.open {
          display: block;
        }
      }
    }
  }

  &-desc {
    font-weight: 400;
    font-size: 0.75rem;
    font-style: italic;
    padding-top: 7px;
  }

  &.image-input-field {
    .field-input {
      padding-left: 50px;
      padding-right: 35px;
      z-index: -1;
      cursor: pointer;
      background-color: white;
      position: relative;
    }
    .upload-button {
      position: absolute;
      width: 100%;
      height: 100%;
      visibility: hidden;
    }
    .icon {
      position: absolute;
      top: 0;
      left: 14px;
      bottom: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    .image {
      position: absolute;
      top: 0;
      left: 14px;
      bottom: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 32px;
      height: 100%;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
    }
    .delete-image {
      position: absolute;
      top: 0;
      right: 10px;
      bottom: 0;
      display: flex;
      margin: auto;
      align-items: center;
      z-index: 10;
      cursor: pointer;
    }
  }
  input[type="color"] {
    appearance: none;
    -webkit-appearance: none;
    background: #fff;
    padding: 11px 14px;
  }
  input[type="color"]::-webkit-color-swatch-wrapper {
    width: 24px;
    height: 24px;

    padding: 0;
  }
  input[type="color"]::-webkit-color-swatch {
    border-radius: 24px;
    border: none;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type="number"] {
    -moz-appearance: textfield;
  }

  &-medium {
    max-width: 248px;
  }
  &-large {
    max-width: 520px;
  }
  &-small {
    max-width: 112px;
  }
  &-value {
    color: #0f0f0f;
  }
  &-auto {
    max-width: 48%;
  }
  &.with-units {
    .field-input {
      padding-right: 45px;
    }
  }

  &-tags {
    .field-input {
      background: url("../../assets//icons/search.svg");
      background-repeat: no-repeat;
      background-position: 14px center;
      padding-left: 40px;
      background-color: white;
    }
  }

  &-label {
    padding-bottom: 10px;
    width: 100%;
    &-opt {
      font-size: 0.75rem;
      // padding-left: 5px;
    }
  }
  &-input-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: start;
  }
  &-input {
    width: 100%;
    border: 1px solid #dbe1e7;
    box-sizing: border-box;
    border-radius: 8px;
    height: 48px;
    padding: 14px;
    color: #0f0f0f;
    font-weight: 500;
    font-family: inherit;

    /* Chrome, Safari, Edge, Opera */

    &.textarea {
      overflow: hidden;
      padding-bottom: 40px;
      resize: none;
      height: 49px !important;
    }
    &:focus {
      outline: 1px solid #017cf8;
    }
  }

  &-counter {
    position: absolute;
    bottom: 14px;
    right: 14px;
  }
  &-icon {
    position: absolute;
    right: 0;
    top: 0px;
    width: 30px;
    height: 100%;
    border-left: 1px solid #dbe1e7;
    display: flex;
    justify-content: center;
    align-items: center;
    img {

      width: 22px;
      height: 22px;
    }
  }
  &-units {
    padding: 14px;
    position: absolute;
    right: 0;
    height: 100%;
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    top: 0;
    &.calender {
      cursor: pointer;
      z-index: 1;
    }
  }
  &-info {
    position: absolute;
    right: 14px;
  }
  &-type-number {
    padding: 14px;
    position: absolute;
    right: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    top: 0;
    svg:first-child {
      transform: rotate(180deg);
    }
  }

  &-select-input-container {
    width: 100%;
    display: flex;
    align-items: flex-end;
    margin-bottom: $field-space-bottom;
    flex-wrap: wrap;

    .field-input-container {
      margin-right: 24px;
      width: 100%;
      margin-bottom: 0;
    }
    .field-select-container {
      margin-bottom: 0;
    }
  }
  &-date-container {
    display: block !important;
  }
}

input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}

.input-property-container {
  margin-top: 10px;

  .input-property {
    padding: 5px 8px;
    border-radius: 3px;
    background-color: rgb(231, 231, 231);
    margin-right: 5px;
    white-space: nowrap;
    font-size: 0.7rem;
  }
}

.field-input-error {
  border: 1px solid #ff817c;
}

@media only screen and (max-width: 426px) {
  .field {
    &-input {
      text-align: center;
      height: 56px;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      letter-spacing: -0.02em;
      color: #75787e;
    }
  }
}

.field-error-message {
  position: absolute;
  bottom: -25px;
  left: 0;
  font-size: 0.8rem;
  color: #ff817c;
}

.field-label-ok-trigger {
  color: #4a80ed;
  font-size: 0.8rem;
  border-radius: 4px;
  margin-left: 15px;

  svg {
    height: 12px;
    width: 12px;
  }
}

.tags-autosuggest__suggestions-list{
  background-color: #e3f1ff !important;
  border: none;
}

.tags-autosuggest__suggestion{
  background-color:transparent;
  color: black;
}

.tags-autosuggest__suggestion--highlighted{
  color: white;
}

.tags-autosuggest__suggestions-container--open,.tags-autosuggest__suggestions-list{
  border: none !important;
  outline: none !important;
}

.tags-autosuggest__suggestions-container{
  border-radius: 8px;
  border: none;
  outline: none;
}

.tags-autosuggest__suggestions-container--open::-webkit-scrollbar {
  width: 5px;
  background: none;
  border-radius: 10px;
  padding-right: -10px;
}

.tags-autosuggest__suggestions-container--open::-webkit-scrollbar-thumb {
  width: 5px;
  margin-right: -5px;
  background: #4a80ed;
  -webkit-border-radius: 1ex;
}