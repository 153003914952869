@import "./QuestionNode";
.logic-wrapper {
  rect {
    stroke-width: 0 !important;
    fill: white;
  }
  #end-arrow{
    path{
      fill: #e4f1ff !important;
    }
  }
  path[class^='Edge-module_path'] {
    stroke: #e4f1ff !important;
  }
  .matching-products-container {
    width: 100%;
    height: 100%;
    background: #ffffff;
    border: 1px solid #7b1dbd;
    border-radius: 10px;
    .title {
      display: flex;
      width: 100%;
      font-weight: 500;
      padding-top: 10px;
      justify-content: center;
    }
    .first-container {
      height: 70px;
      display: flex;
      flex-direction: column;
      .title {
        align-items: flex-start;
        font-family: "Satoshi";
        font-style: normal;
        font-weight: 400;
        width: 150px;
        font-size: 14px;
        line-height: 120%;
        display: flex;
        letter-spacing: -0.04em;
        color: #75787e;
      }
      .recommendation-content-container {
        display: flex;
        justify-content: center;
        width: 100%;
        height: 70px;
        .recommendation-content {
          display: flex;
          margin-top: 5px;
          justify-content: center;
          padding-bottom: 13px;
          // background-color: red;
          border-bottom: 1px solid #dbe1e7;
          width: 70%;
          align-items: center;
          .recommendation-image {
            width: 30px;
            object-fit: contain;
            height: 30px;
            border-radius: 5px;
          }
          p{
            color: #75787E;
            font-size: 14px;
            margin-left: 11px;
          }
        }
      }
    }
    .additional-container {
      height: 75px;
      width: 100%;
      margin-top: 5px;
      .title {
        align-items: flex-start;
        font-family: "Satoshi";
        font-style: normal;
        font-weight: 400;
        width: 85px;
        font-size: 14px;
        line-height: 120%;
        display: flex;
        letter-spacing: -0.04em;
        color: #75787e;
      }
      .additional-sub-container {
        width: 100%;  
        display: flex;
        flex-direction: row;
        .first-container {
          display: flex;
          flex-direction: row;
          align-items: center;
          width: 50%;
           padding-left: 44px;
          .additional-image {
            width: 30px;
            object-fit: contain;
            height: 30px;
            border-radius: 5px;
          }
          .additional-title {
            max-width: 180px;
            color: #75787E;
            margin-left: 12px;
            word-wrap: break-word;
          }
        }
        .divider {
          width: 2px;
          margin-top: 5px;
          background-color: #dbe1e7;
          height: 58px;
        }
        .second-container {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-left: 36px;
          width: 50%;
          .additional-image {
            width: 30px;
            object-fit: contain;
            height: 30px;
            border-radius: 5px;
          }
          .additional-title {
            color: #75787E;
            margin-left: 11px;
            max-width: 180px;
            word-wrap: break-word;
          }
        }
      }
    }

  }
  .upper-container {
    position: absolute;
    width: 95%;
    height: 50px;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .note-container {
      background-color: #ffe0af;
      width: 260px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 5px;
      p {
        font-family: "Satoshi";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 120%;
        text-align: center;
        letter-spacing: -0.04em;

        color: #75787e;
      }
    }
  }
  .reset-button {
    width: 80px;
    height: 30px;
    border: none;
    cursor: pointer;
    font-size: 14px;
    color: #ffffff;
    background: #017cf8;
    border-radius: 5px;
    &:disabled {
      background: #F3F5F7;
      color: #75787E;
    }
  }

  .expand-icon-contanier {
    margin-right: 10px;
    width: 35px;
    height: 35px;
    background: #e4f1ff;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
  }
  .zoom-btn-contanier {
    width: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
    height: 30px;
    z-index: 50;
    position: absolute;
    bottom: 30px;
    right: 30px;
  
    .zoom-out-container {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 30px;
      cursor: pointer;
      height: 30px;
      background: #ffffff;
      box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
      border-radius: 5px;
      .zoom-out-icon {
        width: 13px;
        height: 13px;
      }
    }
    .zoom-in-container {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 30px;
      cursor: pointer;
      height: 30px;
      background: #ffffff;
      box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
      border-radius: 5px;
      .zoom-in-icon {
        width: 13px;
        height: 13px;
      }
    }
  }
  
}
