.body {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: row;
  position: relative;
}

.sidebarContainer {
  position: "relative";
  width: 248px;
  height: 100%;
  background-color: #e4f1ff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.sidebarToggle {
  position: absolute;
  width: 24px;
  height: 24px;
  left: 236px;
  top: 40px;
  font-size: 16px;
  color: #017cf8;
}

.sidebarLogoContainer {
  height: 40px;
  display: flex;
  align-items: center;
  margin-top: 32px;
  padding-left: 27px;
}

.sidebarGreeting {
  font-family: "Satoshi";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: #000000;
  margin-top: 30.705px;
  margin-bottom: 17px;
  padding-left: 27px;
}

.sidebarTabHeader {
  font-family: "Satoshi";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  letter-spacing: -0.02em;
  padding-left: 27px;
  margin-top: 20px;
  margin-bottom: 9px;

  color: #000000;
}

.sidebarTabContainer {
  width: 216px;
  height: 48px;
  display: flex;
  align-items: center;
  margin-left: 13px;
  cursor: pointer;
}

.sidebarTabActive {
  background-color: #017cf8;
  width: 216px;
  height: 48px;
  display: flex;
  align-items: center;
  border-radius: 8px;
  margin-left: 13px;
}

.sidebarTabIcon {
  width: 25px;
  height: 25px;
  margin-right: 16px;
  margin-left: 14px;
}

.sidebarTabIconActive {
  width: 25px;
  height: 25px;
  margin-right: 16px;
  margin-left: 14px;
}

.sidebarTabName {
  font-family: "Satoshi";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: #000000;
}

.sidebarTabNameActive {
  font-family: "Satoshi";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: white;
}

.contentContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow-y: scroll;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.contentContainer::-webkit-scrollbar {
  display: none;
}

.contentHeaderContainer {
  width: 100%;
  min-height: 88px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  box-shadow: inset 0px -1px 0px #dbe1e7;
  padding: 0 50px;
}

.contentHeaderName {
  font-family: "Satoshi";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  /* identical to box height, or 133% */

  letter-spacing: -0.02em;

  color: #0f0f0f;
}

.filtersContainer {
  width: calc(100% - 70px);
  height: 57px;
  border-bottom: 1px solid #dbe1e7;
  margin-top: 13px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 5px;
}

.filterLeftContainer {
  height: 30px;
  display: flex;
  flex-direction: row;
  align-self: flex-end;
}

.filterHeader {
  font-family: "Satoshi";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  letter-spacing: -0.02em;

  /* text / main-black */

  color: #0f0f0f;
}

.filterTag {
  font-family: "Satoshi";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  letter-spacing: -0.02em;

  /* text / main-black */

  color: #0f0f0f;
  margin-left: 15px;
}

.filterSelectButton {
  background-color: transparent;
  display: flex;
  position: relative;
  flex-direction: row;
  width: 115px;
  /*justify-content: space-between;*/
  align-items: center;
  height: 25px;
  outline: none;
  border: none;
  margin-left: 5px;
  padding: 0 5px;
  cursor: pointer;
}

.filterSelectName {
  font-family: "Satoshi";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 150% */

  letter-spacing: -0.02em;

  /* text/secondary */

  color: #75787e;
  margin-right: 10px;
}

.filterRightButton {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  padding: 14px;
  gap: 8px;

  width: 180px;
  height: 48px;

  /* white */

  background: #ffffff;
  /* stroke */

  border: 1px solid #dbe1e7;
  border-radius: 8px;
  cursor: pointer;
}

.filterRightName {
  font-family: "Satoshi";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  letter-spacing: -0.02em;

  /* text / main-black */

  color: #0f0f0f;
}

.summaryContainer {
  min-height: 116px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background: #ffffff;
  border-radius: 16px;
  border: 1px solid #dbe1e7;
  margin-top: 36px;
  padding: 16px 0;
}

.summaryInfoContainer {
  width: 100%;
  height: 100%;
  padding: 0 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.summaryInfoContainer:not(:last-child) {
  border-right: 1px solid #dbe1e7;
}

.summaryInfoTopContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.summaryInfoTopName {
  font-family: "Satoshi";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  /* identical to box height, or 160% */

  letter-spacing: -0.02em;

  color: #000000;
}

.summaryInfoTopIcon {
  width: 14px;
  height: 14px;
  margin-left: 8px;
}

.summaryInfoBottomContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

.summaryInfoBottomInfo {
  font-family: "Satoshi";
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 40px;
  /* identical to box height, or 143% */

  letter-spacing: -0.02em;

  /* text / main-black */

  color: #0f0f0f;
}

.summaryInfoBottomLink {
  font-family: "Satoshi";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  text-decoration: underline;
  color: #017cf8;
  cursor: pointer;
}

.summaryInfoBottomPercentPositive {
  font-family: "Satoshi";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 20px;
  display: flex;
  align-items: center;
  /* identical to box height, or 111% */

  letter-spacing: -0.02em;

  /* green */

  color: #5bb531;
}

.summaryInfoBottomPercentNeg {
  font-family: "Satoshi";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 20px;
  display: flex;
  align-items: center;
  /* identical to box height, or 111% */

  letter-spacing: -0.02em;

  /* green */

  color: #e45447;
}

.chartTopContainer {
  /*height: 33vh;*/
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.chartTop {
  width: 100%;
  height: 300px;
  background: #ffffff;
  /* stroke */

  border: 1px solid #dbe1e7;
  border-radius: 16px;
  padding: 20px;
  display: flex;
}

.chartTopHeaderContainer {
  width: 30%;
  display: flex;
  padding: 16px;
  gap: 20px;
  flex-direction: column;
  justify-content: flex-start;
}

.chartTopHeader {
  width: 193px;
  height: 20px;
  left: 20px;
  top: 12px;

  font-family: "Satoshi";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  /* identical to box height, or 133% */

  letter-spacing: -0.02em;

  color: #000000;
}

/* .chartLegendContainer {
    display: flex;
    flex-direction: row;
}

.chartLegend {
    margin-left: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.chartLegendText {
    font-family: 'Satoshi';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    margin-left: 5px;
    line-height: 20px;

    letter-spacing: -0.02em;

    color: #75787E;
} */

.chartBottomContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin: 39px 0;
  /* gap: 5%; */
  border: 1px solid #dbe1e7;
  border-radius: 16px;
}

.separator {
  width: 1px;
  background: #dbe1e7;
  height: 50px;
  margin-top: 20px;
  margin-bottom: 25px;
  margin-left: 67px;
  margin-right: 30px;
  height: 250px;
}
.sessionsByPageContainer {
  display: flex;
  flex-direction: column;
  width: 60%;
  padding: 20px;
}

.chartBottomGraphsWrap {
  display: flex;
  flex-wrap: wrap;
  gap: 2vw;
  margin-top: 10px;
}

.chartBottomContainerDivider {
  height: 80%;
  width: 1px;
  border-left: 2px solid #dbe1e7;
}

.chartBottomLabel {
  font-weight: 500;
  margin-bottom: 10px;
  display: flex;
}

.chartBottom {
  width: 30%;
  background: #ffffff;
  padding: 20px;
  position: relative;
  border-radius: 16px;
  gap: 20px;
  display: flex;
  flex-direction: column;
}

.barGraphContainer {
  display: flex;
  flex-direction: column;
  justify-content: end;
  gap: 10px;
}

.barGraphContainer>svg{
  min-width: 30px;
}

.barGraphHeader {
  display: flex;
  align-items: flex-end;
  gap: 6px;
}

.barGraphLabel {
  color: #75787e;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
}

.barGraphPercent {
  margin-left: auto;
}

.barGraphGray {
  width: 100%;
  border-radius: 10px;
  height: 10px;
  background-color: #f3f5f7;
  margin-top: 4px;
}

.barGraphPurple {
  height: 100%;
  background-color: #826ee8;
  border-radius: 10px;
}

.chartBottomHeaderContainer {
  position: absolute;
  top: 20;
  left: 20;
}

.chartBottomHeader {
  width: 193px;
  height: 20px;
  left: 20px;
  top: 12px;

  font-family: "Satoshi";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  /* identical to box height, or 133% */

  letter-spacing: -0.02em;

  color: #000000;
}

.overviewBoxesRow {
  display: flex;
  justify-content: space-between;
  width: calc(100% - 70px);
  min-height: 110px;
  max-height: 110px;
  height: 110px;
  margin: 30px 0;
}

.overviewBox {
  height: 100%;
  width: 24%;
  border-radius: 20px;
  padding: 15px 25px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid #dbe1e7;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.06);
}

.noPublishExp {
  background-color: #ffe4b4;
  height: 35px;
  width: 100%;
}

.noPublishMessage {
  font-family: "Satoshi";
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: -0.02em;
  text-align: center;
  padding-top: 8px;
  padding-bottom: 8px;
}

.noPublishedImage {
  display: flex;
  margin-top: 186px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.noPublishDescription {
  font-family: "Satoshi";
  font-size: 20px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: -0.02em;
  text-align: center;
}

.noPublishDescription2 {
  margin-top: 9px;
  font-family: "Satoshi";
  font-size: 20px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: -0.02em;
  text-align: center;
}

.noDataSvg{
   width: 100%;
   height: auto;
   padding: 28px 40px 0px 37px;
}