.add-tag-dialog {
  display: flex;
  min-height: 250px;
  flex-direction: column;
  .inputs {
    display: flex;
    gap: 20px;
    align-items: flex-end;
    .field {
      width: 100% !important;
    }
    .tags-container {
      width: 100% !important;
    }
  }
  .container {
    display: flex;
    align-items: flex-end;
    margin-bottom: 20px;
    margin-top: auto;
  }
  .tags-autosuggest__suggestions-list {
    max-height: 200px;
    overflow-y: auto;
  }
}
