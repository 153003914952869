.signup {
    &-page {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100vh;
        width: 100%;

        .error {
            color: red;
        }

        button {
            margin-top: 0px;
        }


    }

    &-container {
        padding: 16px;
        width: 50%;
        min-height: 100%;
        flex: 1;
    }

    &-title {
        font-family: 'Satoshi';
        font-style: normal;
        font-weight: 700;
        font-size: 30px;
        line-height: 24px;
        /* identical to box height, or 80% */

        letter-spacing: -0.02em;

        color: #000000;
        margin-bottom: 10px;
    }

    &-subtitle {
        font-family: 'Satoshi';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 40px;
        /* identical to box height, or 222% */

        text-align: center;
        letter-spacing: -0.02em;

        color: #75787E;
        margin-bottom: 35px;
    }

    &-steps {
        flex: 1;
        max-width: 520px;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;

        .stepper-wrapper {
            height: 100%;
        }

        .stepper-header {
            padding-bottom: 40px;
            margin-bottom: 40px;
            border-bottom: 1px solid #dbe1e7;
        }

        h4 {
            padding-bottom: 20px;
        }

        .field-group {
            display: flex;
            justify-content: space-between;
            column-gap: 10px;
        }

        .password-note {
            color: #75787e;
            font-size: 0.75rem;
        }
    }
}

.signup-page {
    .platform {
        &-radio {
            display: flex;
            position: relative;
            display: flex;
            align-items: center;

            &-label {
                font-size: 1rem;
                padding-left: 8px;
            }

            input {
                appearance: none;
                width: 24px;
                height: 24px;
                border: 2px solid #017cf8;
                border-radius: 6px;
                display: flex;
                align-items: center;
                justify-content: center;

                &:checked {
                    &:before {
                        opacity: 1;
                        transition: opacity 0.25s ease;
                    }
                }

                &:before {
                    content: "";
                    width: 50%;
                    height: 50%;
                    background: #017cf8;
                    opacity: 0;
                    border-radius: 2px;
                }
            }

            &-arrow {
                position: absolute;
                right: 0;
                height: 100%;
                cursor: pointer;
                transition: transform 0.35s ease-in;
            }
        }

        &-preview {
            width: 48%;
        }
    }

}

.tags-wrap {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: row;
    row-gap: 10px;
    column-gap: 10px;
}

.note {
    font-family: 'Satoshi';
    font-style: normal;
    font-size: 12px !important;
    color: #75787E;
    margin-bottom: 10px;
}

.signup-button-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 25px 12px 12px;
    gap: 6px;
    width: 429px;
    height: 56px;

    background: #017CF8;
    border-radius: 8px;
    outline: none;
    border: none;
    margin-top: 24px !important;
}

.signup-button-container-disabled {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 25px 12px 12px;
    gap: 6px;
    width: 429px;
    height: 56px;

    background: #f3f5f7 !important;
    border-radius: 8px;
    outline: none;
    border: none;
    margin-top: 24px !important;
}

.signup-button {
    font-family: 'Satoshi';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */

    letter-spacing: -0.02em;

    color: #FFFFFF;
}

.signup-button-disabled {
    font-family: 'Satoshi';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */

    letter-spacing: -0.02em;
    color: #75787e !important;
}

.signup-button-google-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 25px 12px 12px;
    gap: 6px;
    width: 429px;
    height: 56px;
    background: #FFFFFF;
    border: 1px solid #9D9D9D;
    border-radius: 8px;
}

.signup-button-google {
    font-family: 'Satoshi';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    /* identical to box height, or 167% */

    letter-spacing: -0.02em;

    color: #000000;
}

.signup-already-have-account-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 19px;
}

.signup-already-have-account {
    font-family: 'Satoshi';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    /* identical to box height, or 133% */

    display: flex;
    align-items: center;

    color: #000000;
    margin-right: 5px;
}

.signup-already-have-account-link {
    font-family: 'Satoshi';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    /* identical to box height, or 133% */

    display: flex;
    align-items: center;

    color: #017CF8;
}

.signup-or-container {
    width: 429px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 14px 0 10px 0;
}

.signup-or {
    font-family: 'Satoshi';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    /* identical to box height, or 167% */

    text-align: center;
    letter-spacing: -0.02em;

    color: #75787E;
}

.signup-or-line {
    width: 180px;
    border: 1px solid #D9D9D9;
}

.terms-link {
    font-family: 'Satoshi';
    font-style: normal;
    color: #017CF8;
    text-decoration: underline;
}

.warning-text-container {
    width: 429px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 5px;
}

.warning-text {
    font-family: 'Satoshi';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    /* identical to box height, or 80% */

    letter-spacing: -0.02em;

    color: #ff817c;
    text-align: start;
}


#sign-up-page-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#sign-up-page-content-inner {
    width: 500px;


    h1 {
        font-family: "Satoshi", sans-serif;
        font-weight: 500;
    }
}