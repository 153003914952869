@import "../../styles/colors";

.dropdown-container{
  position: absolute;
  top: 30px;
  right: 0;
  padding: 10px;
  width: 200px;
  background-color: $secondary;
  border-radius: 10px;
  z-index: 10;
  max-height: 300px;
  overflow-y:scroll;


  .dropdown-option{
    width: 100%;
    padding: 10px 5px;
    display: flex;
    align-items:center;
    border-radius: 5px;
    font-weight: 500;
    font-size: 14px;
    user-select: none;
    text-align: left;
  }

  .dropdown-option:hover{
    background-color: rgba($primary,0.14);
  }

  .dropdown-checkbox{
    border-radius: 4px;
    padding: 3px;
    border: 2px solid $primary;
    margin-right: 10px;

    p{text-align: left;}

    .dropdown-checkbox-inner{
      width: 9px;
      height: 9px;
      background-color: transparent;
      border-radius: 2px;
    }

    .dropdown-checkbox-inner-active{
      background-color: $primary;
    }
  }

  .dropdown-option-sub-label{
    font-size: 12px;
    color: $text;
  }
}

.dropdown-container::-webkit-scrollbar {
  width: 4px;
  background: none;
  border-radius: 10px;
  padding-right: 2px;
}

.dropdown-container::-webkit-scrollbar-thumb {
  width: 4px;
  margin-right: 2px;
  background: $primary;
  border-radius: 1ex;
  -webkit-border-radius: 1ex;
}