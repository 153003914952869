

.create-experience-dialog {
  padding-top: 28px;
  min-height: 316px;

  .connected-tabs-group {
    display: flex;
    align-items: center;
    margin-top: 24px;
    margin-bottom: 18px;
    
    

    .tags {
      display: flex;
      align-items: center;
      svg {
        margin-top: 1px;
      }
    }
    .connected-tabs-group-active {
      background-color: #017cf8;
      color: white;
      box-shadow: 0 3px 5px rgba(black, 0.15);
      svg {
        path {
          fill: white;
        }
      }
    }
    button {
      width: 100px;
      height: 30px;
      border: none;
      cursor: pointer;
      background-color: #e4f1ff;
      color: #75787e;
    }
    button:first-child {
      border-radius: 5px 0 0 5px;
      width: 128px;
      height: 35px;
    }

    button:last-child {
      border-radius: 0 5px 5px 0;
      width: 128px;
      height: 35px;
    }
  }

  .added-product-input-box {
    &::placeholder {
      font-size: 12px;
    }
    &:focus {
      border: 1px solid #3fafff !important;
      box-shadow: none !important;
    }
  }

  * {
    font-size: 14px;
  }

  .create-exp-btn {
    color: white;
    padding: 20px 25px;
    border-radius: 8px;
    outline: 1px solid #017cf8;
    border: none;
    background-color: #017cf8;

 
    &:hover {
      color: #017cf8;
      background: #fff;
      box-shadow: 1px 1px 8px -1px rgba(1, 124, 248, 1);

      .create-exp-btn-svg {
        fill: #017cf8;
   
      }
    }

    &:disabled {
      color: #75787e;
      outline: none !important;
      background-color: #f3f5f7;
    }
  }

  .subtitle {
    font-size: 12px;
    margin-left: 5px;
    opacity: 0.7;
  }

  .products-edit-type-container {
    margin: 20px 0;
    height: 250px;
    overflow: auto;

    &::-webkit-scrollbar {
      width: 2px;
      height: 5px;
      background-color: #aeb2ba;
      border-radius: 5px;
    }
  }

  .create-selected-items-section {
    &::-webkit-scrollbar {
      width: 2px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 20px;
      height: 60px;
      background-color: #aeb2ba;
    }

    .selected-items-section {
      &::-webkit-scrollbar {
        width: 4px;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 20px;
        height: 60px;
        background-color: #aeb2ba;
      }
    }
  }

  .create-header {
    font-weight: 500;
    font-size: 24px;
    margin-top: 5px;
    line-height: 24px;
    /* identical to box height, or 100% */
    display: flex;
    align-items: center;
    gap: 6px;
    letter-spacing: -0.02em;

    color: #000000;
  }

  .create-subheader {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    margin-top: 10px;
    /* identical to box height, or 150% */

    letter-spacing: -0.02em;

    a,
    a:visited {
      color: #017cf8;
      font-size: 12px;
    }

    color: #75787e;
  }

  .menu-radiogroup {
    input:checked {
      + div {
        background: none !important;
        border: 1px solid #017cf8;

        circle {
          fill: #017cf8;
          stroke: #017cf8;
        }
      }
    }
  }

  .create-button {
    &:hover {
      color: #017cf8;
      border-color: #017cf8;
    }
  }
}

.popover-content {
  background-color: #e4f1ff;
  padding: 16px 8px;

  .menu-item {
    border-radius: 5px;
    cursor: pointer;
    padding: 8px;

    &:hover {
      background-color: #017cf8;
      color: white;
    }
  }

  .ub-ml_1-1em {
    margin-left: 0 !important;
  }
}

.menu-items {
  background-color: #e4f1ff;
  margin: 0;

  .menu-items-container {
    &::-webkit-scrollbar {
      width: 2px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 20px;
      background-color: #aeb2ba;
    }
  }

  .menu-item {
    border-radius: 5px;
    cursor: pointer;
    padding: 8px;

    &:hover {
      background-color: #017cf8;
      color: white;
    }
  }
}

.collection-container {
  display: flex;
  width: 100%;
  border-bottom: 1px solid #dbe1e7;
  > div {
    flex: 1;
    height: 276px;
    overflow: auto;

    &::-webkit-scrollbar {
      width: 4px;
      background: none;
      border-radius: 10px;
      padding-right: 2px;
    }

    &::-webkit-scrollbar-thumb {
      width: 4px;
      margin-right: 2px;
      background: #aeb2ba;
      -webkit-border-radius: 1ex;
    }

    .selected-item {
      & span {
        color: black;
      }

      &:first-child {
        position: sticky;
        top: 0;
        z-index: 1;
        background-color: white;
      }
    }

    &:nth-child(2) {
      max-width: 1px;
      margin: 26px;
      height: 250px;
      background-color: #dbe1e7;
    }
  }
}

.products-container {
  display: flex;
  width: 100%;
  margin-top: -7px;

  > div {
    flex: 1;
    height: 276px;
    overflow: auto;

    &::-webkit-scrollbar {
      width: 4px;
      background: none;
      border-radius: 10px;
      padding-right: 2px;
    }

    &::-webkit-scrollbar-thumb {
      width: 4px;
      margin-right: 2px;
      background: #aeb2ba;
      -webkit-border-radius: 1ex;
    }

    .selected-item {
      & span {
        color: black;
      }

      &:first-child {
        position: sticky;
        top: 0;
        background-color: white;
      }
    }

    &:nth-child(2) {
      max-width: 1px;
      margin: 10px;
      height: 250px;
      background-color: #dbe1e7;
    }

    > p:first-child {
      position: sticky;
      top: 0;
      height: 32px;
      line-height: 32px;
      background-color: white;
    }
  }
}

.create-exp-tabs-group {
  display: flex;
  align-items: center;

  .create-exp-tabs-group-active {
    background-color: #017cf8;
    color: white;
    box-shadow: 0 3px 5px rgba(black, 0.15);
  }

  button {
    padding: 10px 22px;
    border: none;
    cursor: pointer;
    background-color: #e4f1ff;
    color: #75787e;
  }

  button:first-child {
    border-radius: 5px 0 0 5px;
  }

  button:last-child {
    border-radius: 0 5px 5px 0;
  }
}

.experience-name-input {
  &:focus {
    border: 1px solid #3fafff !important;
    box-shadow: none !important;
  }
}

.step2-search-input {
  &:focus {
    border: 1px solid #3fafff !important;
    box-shadow: none !important;
  }
}

.product-search-tag-list-api-container {
  position: absolute;
  top: 220px;
  left: 25px;
  height: 198px;
  max-height: 190px;
  overflow-y: scroll;
  background-color: #e4f1ff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  width: 209px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 10px;
}
.product-search-type-list-api-info-container {
  margin-left: 10px;
  border-bottom: 1px solid #dbe1e7;
  padding-bottom: 7px;
  margin-bottom: 10px;
  width: 100%;
}
.product-search-tag-list-api-type {
  margin-top: 5px;
  color: #75787e;
}

.product-search-tag-list-api-container::-webkit-scrollbar {
  width: 4px;
  background: none;
  border-radius: 10px;
  padding-right: 2px;
}

.product-search-tag-list-api-container::-webkit-scrollbar-thumb {
  width: 4px;
  margin-right: 2px;
  background: #aeb2ba;
  -webkit-border-radius: 1ex;
  border-radius: 1ex;
}

.product-search-tag-list-api-box {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
}
.products-edit-tags-pop-up-selected-tag-header {
  font-family: "Satoshi";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 20px;
  /* identical to box height, or 200% */
  letter-spacing: -0.02em;
  color: #75787e;
  margin-top: 10px;
}

// _______________________
