@import "../../styles/classes.scss";
@import "../../styles/colors.scss";
@import "../../styles/fonts.scss";

.button-container {
    background: $primary;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;
    height: 45px !important;
    margin: 12px 0;
    border: none;
    outline: none;
}

.button-container-active {
    display: flex;
    justify-content: center;
    align-items: center;
    background:  #E4F1FF;
    border: none;
    border-radius: 8px;
    width: 90%;
    height: 45px !important;
    outline: none;
}

.button-container-skeepx {
    display: flex;
    justify-content: center;
    align-items: center;
    background: none;
    border: 1px solid #5B0DF2;
    border-radius: 8px;
    width: 90%;
    height: 45px !important;
    margin: 12px 0;
    outline: none;
}

.button-container-no-pricing {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #7B1DBD;
    border-radius: 8px;
    width: 90%;
    height: 45px !important;
    margin: 12px 0;
    border: none;
    outline: none;
}

.button-container-disabled {
    background: #EEEEEE;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;
    height: 45px !important;
    margin: 12px 0;
    border: none;
    outline: none;
}

.text {
    font-family: 'Satoshi';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */

    letter-spacing: -0.02em;

    /* white */

    color: #FFFFFF;
}

.text-active {
    font-family: 'Satoshi';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */

    letter-spacing: -0.02em;

    color: $primary;
}

.text-skeepx {
    font-family: 'Satoshi';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */

    letter-spacing: -0.02em;

    color: #5B0DF2;
}

.text-disabled {
    font-family: 'Satoshi';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */

    letter-spacing: -0.02em;

    /* white */

    color: $text;
}