.gradient-container {
  // background-color: #fff;
  // padding: 0.5rem;
  box-shadow: 0px 8px 24px rgba(21, 23, 46, 0.08);
  border-radius: 8px;
  position: absolute;
  z-index: 21;
  top:10px;
  right:0;

 
  //  width: 248px;

  .ui-color-picker {
    width: 100%;
    margin: 1rem auto;
    .color-preview-area {
      .input-group {
        .input-field .input-container .input {
          font-size: 10px;
        }
      }
    }
  }
  .btn-container {
    display: flex;
    justify-content: space-evenly;
    background: #f3f5f7;
    height: 48px;
    border-radius: 6px;
    align-items: center;
    span {
      cursor: pointer;
      border: none;
      background: transparent;
      border-radius: 6px;
      width: 105px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .active {
      background: #017CF8;
      color: #ffffff
    }
  }

  .chrome-picker-parent{
    background-color: white;
    height: 320px;  
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    .chrome-picker {
      box-shadow: none !important;
    }
.color-history-para{
  font-family: "Satoshi";
  font-size: 13px;
  color:  #75787E;
  margin: 1px 0px 0px 12px;
  
  
}
.colors-inner{
  width: 200px;
  margin-top: 6px;
  margin-left: 12px;
  height: 60px;
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
  .color-history-dot{
    height: 22px;
    width: 22px;
    left: 2px;
    cursor: pointer;
    top: 2px;
    border-radius: 50px;
    
  }
}
  }
  .color-dots{
    margin: 1rem;
    display: flex;
    justify-content:space-between ;
    align-items: center;
    .color-selector{
      height: 15px ;
      width: 15px;
      border-radius: 100%;
      padding: 0.2rem;
    }
    .linear-gradient{
      flex-grow: 1;
      height: 15px;
      margin: 0 8px;
    border-radius: 8px;
    }
  }
}
