@import "../../styles/classes";
@import "../../styles/colors";

.app-page.exp-page {
  height: 100% !important;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;

  .app-page-header {
    height: 100px;
    width: 100%;
    padding: 0px 50px;
    border-bottom: 1px solid lightgrey;

    .app-page-header-title {
      font-size: 1.4rem;
      font-weight: 500;
      display: flex;
      align-items: flex-start;
      gap: 6px;
      svg {
        margin-top: 4px;
        width: 12px;
        height: 10px;
      }
    }
  }

  .app-page-content {
    height: 100%;
    padding: 25px 50px;
    .MuiTableContainer-root {
      overflow-x: unset;

      &::-webkit-scrollbar {
        width: 2px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 20px;
        background-color: #dbe1e7;
      }
      table {
        padding-right: 2rem;
      }
    }
  }

  .experiences-table-header {
    font-weight: 500;
    font-size: 16px;
    font-family: "Satoshi";
  }

  input.experience-row {
    height: 36px !important;
    border-color: #ededed;
  }
  .experience-menu-holder {
    display: flex;
    align-items: center;
    margin-top: 40px;

    .action-menu-buttons {
      height: 29px;
      color: #017cf8 !important;
      background: #e4f1ff !important;
      border: none;
      margin-right: 10px;
    }

    //.disabled {
    //  background: #f3f5f7;
    //  color: #75787e !important;
    //  box-shadow: none !important;
    //}
  }
  .experience-row {
    font-family: "Satoshi";
    font-weight: 300;
    font-size: 14px;
    max-width: 150px;
    font-style: normal;
    gap: 15px;
    display: flex;
    flex-direction: column;
    margin: auto;
    text-align: center;
    &:disabled {
      background: none;
      padding: 0;
      border: none;
      font-size: 14px;
      cursor: default;
      color: #0f0f0f;
    }

    input {
      height: 36px !important;
      border-color: #ededed;
      margin-bottom: -10px !important;
      border-radius: 5px;
    }

    .tags-container {
      width: 368px !important;
      height: 36px !important;
      cursor: default;
    }

 
  }
  .create-button {
    &:disabled {
      background-color: #ededed;
    }
  }
  .products-checkbox-container {
    min-width: 16px;
    width: 16px;
    height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;

    background: #e4f1ff;
    border: 1.5px solid #017cf8;
    border-radius: 4px;
    margin-left: 5px;
  }

  .products-checkbox {
    width: 8px;
    height: 8px;

    /* blue */

    background: #017cf8;
    border-radius: 2px;
  }

  .menu-button {
    width: 25px;
    min-width: 0;
    padding: 8px;

    &:hover,
    &:focus {
      background: none;
      outline: none;
      box-shadow: none;
    }
  }

  .exp-filters-containers {
    .exp-status-item {
      padding: 10px 24px;
      background-color: $secondary;
      color: $text;
      cursor: pointer;
      outline: none;
      border: none;
    }

    .exp-status-item:hover {
      background-color: $primary;
      color: white;
    }

    .exp-status-item:first-child {
      border-radius: 5px 0px 0px 5px;
    }

    .exp-status-item:last-child {
      border-radius: 0px 5px 5px 0px;
    }

    .exp-status-active {
      background-color: $primary;
      color: white;
      outline: none;
      border: none;
    }

    .exp-type-filters {
      gap: 10px;
      margin-top: 15px;

      .exp-type-filter-item {
        padding: 8px 12px;
        border-bottom: 2px solid transparent;
        cursor: pointer;
        color: $text;
      }

      .exp-type-filter-item:hover {
        color: black;
      }

      .exp-type-filter-active {
        color: black;
        border-bottom: 2px solid $primary;
      }
    }
  }

  .experiences-holder {
    z-index: 1;
    position: relative;
    .experiences-container {
      display: flex;
      flex-wrap: wrap;
      gap: 15px;
      margin-top: 25px;
      justify-content: left;
      width: 100%;
    }
  }

  .live-pop-up-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    right: -124px;
    background: rgba(0, 0, 0, 0.15);
    z-index: 1;
  }

  .live-pop-up {
    width: 850px;
    max-height: 489px;
    left: 484px;
    top: 218px;

    /* white */
    .field {
      margin-bottom: 0;
    }
    .field-input-container {
      input {
        border: none !important;
        padding: 0;
        height: 30px;
        &:focus {
          outline: none !important;
        }
      }
    }

    background: #ffffff;
    box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.15);
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    padding: 32px 41px;
  }

  .live-pop-up-header {
    font-family: "Satoshi";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
    /* identical to box height, or 160% */

    letter-spacing: -0.02em;

    /* text / main-black */

    color: #0f0f0f;
    margin-bottom: 10px;
  }

  .live-pop-up-subheader {
    font-family: "Satoshi";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    /* identical to box height, or 133% */

    letter-spacing: -0.02em;

    /* text / main-black */

    color: #0f0f0f;
    margin-bottom: 45px;
  }

  .live-pop-up-content-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  .live-pop-up-coupon {
    width: 57px;
    height: 31px;
    object-fit: contain;
  }

  .live-pop-up-coupon-container {
    width: 100%;
    height: 46px;

    border: 1px solid #e4f1ff;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-right: 35px;
    padding: 0 10px;
  }

  .live-pop-up-coupon-container-two {
    width: 100%;
    height: 46px;

    border: 1px solid #e4f1ff;
    border-radius: 8px;
    margin-right: 35px;
  }

  .live-pop-up-coupon-info-container {
    position: relative;
    height: 46px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }

  .live-pop-up-coupon-info-container-error {
    top: 20px;
    left: 110px;
    font-size: 12px;
    padding: 5px;
  }

  .live-pop-up-coupon-tag {
    font-family: "Satoshi";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    white-space: nowrap;
    line-height: 16px;
    /* identical to box height, or 114% */

    display: flex;
    align-items: center;

    color: #000000;
    margin-right: 22px;
  }

  .live-pop-up-coupon-value {
    font-family: "Satoshi";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    overflow: hidden;
    /* identical to box height, or 167% */

    letter-spacing: -0.02em;

    color: #75787e;
  }

  .live-pop-up-placement {
    width: 44px;
    height: 37px;
    object-fit: contain;
  }

  .divider {
    width: 80%;
    border: 1px solid #dbe1e7;
    height: 0 !important;
    margin: 11px 0 16px 75px;
  }

  .live-pop-up-image-container {
    width: 70px;
    margin-right: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .live-pop-up-coupon-with-border-container {
    width: 100%;
    height: 46px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 0 10px;
  }

  .live-pop-up-coupon-with-border-container:not(:last-of-type) {
    border: 1px solid #dbe1e7;
  }

  .live-pop-up-button-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 28px;
  }

  .live-pop-up-cancel-button {
    margin-right: 23px;
    outline: none;
    border: none;
    background-color: transparent;

    font-family: "Satoshi";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */

    letter-spacing: -0.02em;

    /* text/secondary */

    color: #75787e;
  }

  .experience-status-item {
    padding: 10px 24px;
    background-color: $secondary;
    color: $text;
    cursor: pointer;
    outline: none;
    border: none;
  }

  .experience-status-item:first-child {
    border-radius: 5px 0px 0px 5px;
  }

  .experience-status-item:last-child {
    border-radius: 0px 5px 5px 0px;
  }

  .experience-status-active {
    background-color: $primary;
    color: white;
    outline: none;
    border: none;
  }

  .inline-input {
    border: none;
    border-bottom: 1px solid transparent;
    outline: none;
    color: #75787e;
    background-color: white;
    padding: 5px 0px;
  }

  .inline-input-open {
    border-bottom: 1px solid #a2a2a2;
  }

  .inline-input-invalid {
    color: $red;
  }

  .reached-limit-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    right: -124px;
    background-color: rgb(15, 15, 15, 0.25);
    z-index: 7;
  }

  .reached-limit-container-minimized {
    width: 100%;
    height: 100%;
    display: flex;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    right: -10px;
    background-color: rgb(15, 15, 15, 0.25);
    z-index: 7;
  }

  .reached-limit {
    width: 486px;
    height: 245px;

    /* white */

    background-color: #ffffff;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    padding: 32px 32px 15px 32px;
  }

  .reached-limit-header {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    /* identical to box height, or 133% */

    letter-spacing: -0.02em;

    /* text / main-black */

    color: #0f0f0f;
  }

  .reached-limit-info {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    /* or 150% */

    letter-spacing: -0.02em;

    /* text/secondary */

    color: #75787e;
    margin-top: 12px;
  }

  .reached-limit-button-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin-top: 20px;
  }

  .text-link {
    color: #017cf8;
    text-decoration: underline;
    cursor: pointer;
  }

  .exp-page-titles {
    display: flex;
    flex-direction: column;
    gap: 6px;
    margin-bottom: 25px;

    h3 {
      font-weight: 500;
      font-size: 21px;
    }

    p {
      color: #75787e;
    }
    span {
      color: #75787e;
      font-weight: 500;
      font-size: 12px;
    }
    button {
      &:hover {
        color: #017cf8;
        border-color: #017cf8;
      }
    }
  }

  .exp-page-type {
    padding: 15px;
    border-bottom: 1px solid #dbe1e7;
    margin-top: 14px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    position: relative;
    z-index: 0;
  }

  .exp-page-type:hover {
    background-color: #f3f3f3;
  }

  .exp-page-type-title {
    font-size: 18px;
    font-weight: 500;
  }
  .failed-note-container{
    display: flex;
    height: 51px;
    margin-top: 35px;
    width: 381px;
    justify-content: center;
    align-items: center;
    background: #FFE4B4;
    border-radius: 5px;
    svg{
      width: 24px;
      height: 24px;
      margin-top: 10px;
      margin-left: 8px;
      margin-right: 8px;
      margin-bottom: 30px;
    }
    p{
      font-size: 12px;
      color: #75787E;
      line-height: 20px;
      font-family: 'Satoshi';
    }
  }
}

.experience-menu-items {
  padding: 18px;
  //margin-top: 32px;
  background: #e4f1ff;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  gap: 10px;
  margin: auto;
  top: 90vh;

  button {
    opacity: 90%;
    background: #017cf8;
    &:hover {
      background-color: #017cf8 !important;
      opacity: 1;
    }
  }
  path {
    fill: #ffffff;
  }
  .experience-menu-item {
    cursor: pointer;
    border-radius: 4px;
    height: 36px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    svg {
      path {
        fill: #000;
      }
      margin-right: 8px;
      margin-left: 8px;
    }

    &:hover {
      background: #017cf8;
      color: white;

      svg path {
        fill: white;
      }
    }
  }
}

.products-checkbox-container {
  min-width: 16px;
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;

  background: #e4f1ff;
  border: 1.5px solid #017cf8;
  border-radius: 4px;
  margin-left: 5px;
}

.products-checkbox {
  width: 8px;
  height: 8px;

  /* blue */

  background: #017cf8;
  border-radius: 2px;
}

#path-cell {
  width: 200px !important;
  max-width: 200px !important;

  .experience-row {
    word-break: break-word;
    max-width: 170px !important;
    width: 200px !important;
  }
}

.tour-pop-container-minimized {
  width: 100%;
  height: 100%;
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  right: -10px;
  background-color: rgb(0, 0, 0, 0.15);
  z-index: 7;
}

.tour-pop-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  right: -124px;
  background-color: rgb(0, 0, 0, 0.15);
  z-index: 7;
}

.tour-pop {
  width: 600px;
  height: 533px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  z-index: 99;
  /* white */

  background: #ffffff;
  box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.15);
  border-radius: 16px;
  padding: 40px;
}

.tour-pop-upper-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  height: 20px;
  width: 100%;
}

.tour-pop-circle {
  width: 10px;
  height: 10px;
  margin: 0 5px;
  border-radius: 5px;
}

.tour-pop-circle-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.tour-pop-exit-icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.tour-pop-footer-text {
  font-family: "Satoshi";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  /* or 133% */

  text-align: center;
  letter-spacing: -0.02em;

  color: #040404;
}

.tour-pop-image {
  width: 380px;
  height: 230px;
  margin: 50px 0 40px 0;
}

.tour-pop-button-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  flex: 1;
}

.tour-trigger-container {
  position: relative;
  width: 138px;
  height: 48px;
  background-color: #e4f1ff;
  color: #017cf8;
  //border-width: 1px 1px 1px 0px;
  //border-style: solid;
  //border-color: #BE6EF7;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.tour-trigger-image {
  position: absolute;
  width: 53px;
  height: 27.21px;
  top: 12px;
  left: -30px;
}

.tour-trigger-text {
  font-family: "Satoshi";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  letter-spacing: -0.02em;
}

.info-box {
  display: flex;
  width: 55%;
  max-width: 665px;
  margin: 1rem auto;
  padding: 22px;
  background: #e4f1ff;
  border-radius: 12px;
  justify-content: space-evenly;
  text-align: center;
  color: #75787e;
  align-items: center;
  font-size: 16px;
  height: 121px;
  box-shadow: 0px 1px 7px 3px rgba(0, 0, 0, 0.17);

  p {
    width: 70%;
  }
}


#experiences-page-header{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 5px;

  .app-page-header-subtitle{
    color: #75787E;
    font-size: 12px;
  }
}

.exp-page-create-container{
  width: 100%;
  display: flex;
  align-items:center;
  justify-content: flex-end;
  margin-bottom: 30px;
}

.MuiTableBody-root{
  .MuiTableRow-root:hover{
    background-color: rgba(#E4F1FF,0.5);
  }
}




.exp-table-status{
  padding: 5px 25px;
  width: fit-content;
  border-radius: 20px;
  color: #75787E;
  background-color: #F3F5F7;
  margin: auto;
  text-align: center;
}

.exp-table-status-active{
  color: #5BB531;
  background-color: #F0FAEB;
}


.action-cell{
  .action-menu-buttons{
    margin: 0 10px;
    background-color: #e4f1ff;
    color: #017cf8;
    cursor: pointer;
    border: none;
    border-radius: 6px;
    height:29px;
    padding:0 20px;
  }

  .action-menu-buttons:hover{
    color: #e4f1ff;
    background-color: #017cf8;
    cursor: pointer;
  }
}

.invisible{
  visibility: hidden;
}

.exp-table-burger-icon{
  margin-left: auto;
}


.upgrade-button-exp-page{
  display:flex;
  justify-content:center;
  align-items: center;
  margin-right: 20px;
  height: 40px;
  padding: 0 20px;
  border-radius: 7px;
  border: 0;
  outline: 0;
  cursor: pointer;
  color: #FFFFFF;
  background-color: #7B1DBD;
}

.upgrade-button-exp-page:hover{
  filter: brightness(0.9);
}
.limitations-text{
  font-size: 14px;
  margin-right: 20px;
}

.action-menu-buttons{
  position: relative;
}

.get-more-text{
  position: absolute;
  transform: translate(-50%,-50%);
  top: 40px;
  white-space: nowrap;
  text-decoration: underline;
  color: #017CF8 ;
  left: 50%;
}