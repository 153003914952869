@import "../../../styles/colors";

.inputs-row{
  padding: 25px 0px 20px 0px;
  border-bottom: 1px solid gray;
  gap: 15px;

  .field{
    margin-bottom: 0px !important;
  }

  .fa-trash-can{
    margin-right: 10px;
    cursor: pointer;
  }

  .fa-code{
    cursor: pointer;
  }
}

.code-snippet-indicator{
  padding: 5px 8px;
  border-radius: 5px;
  font-size:0.8rem;
  color: $primary;
  background-color:$secondary;
  margin-left: 10px;
}