@import "../../styles/classes";
@import "../../styles/colors";

.datepicker-container {
  position: absolute;
  top: 50px;
  display: flex;
  right: 0px;
  min-width: 100%;
  border-radius: 8px;
  z-index: 10;
  background: $secondary;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);

  .datepicker-list-container {
    width: 100%;
    min-width: 150px;
    padding: 10px 10px 20px 10px;
    border-radius: 8px;
    border-right: 1px solid #dbe1e7;
  }

  .datepicker-list-item {
    width: 100%;
    display: flex;
    align-items: center;
    color: #75787e;
    border-radius: 7px;
    padding: 13px 10px;
    font-weight: 500;
    font-size: 12px;
  }

  .datepicker-list-item:hover {
    background-color: #f3f5f7;
  }

  #datepicker-list-item-active {
    background-color: #017cf8;
    color: #fff;
  }
}

.datepicker-calander-container {
  border-left: 1px solid $secondary;
  background-color: $secondary;
  border-radius: 8px;
  div {
    background: transparent !important;
  }
}
.DayPicker_weekHeader_li {
  text-transform: uppercase;
  
}
.DayPickerNavigation {
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 20px;
  margin: 0 10px;
}

.datepicker-button-container {
  display: flex;
}

.DayPicker__withBorder {
  box-shadow: none !important;
  border-bottom: 1px solid #dbe1e7;
}
.datepicker-info-container {
  padding: 11px 20px 16px 20px;
  border-top: 1px solid $secondary;
  font-size: 16px;
  font-weight: 900;

  .datepicker-info-subtitle {
    color: #aeb2ba;
    font-weight: 500;
    text-align: left;
    font-size: 12px;
  }

  .datepicker-info-dates {
    color: #000;
    font-weight: 500;
  }
}

.CalendarDay__default {
  border: none !important;
  background: transparent !important;
}

.CalendarDay__selected,
.CalendarDay__selected:active {
  background: #fff !important;
  color: $primary !important;
  border-radius: 100px;
}

.CalendarDay__selected_span,
.CalendarDay__hovered_span {
  color: #fff !important;
  background: $primary !important;
}

.CalendarDay__selected_start {
  border-radius: 100px 0px 0px 100px !important;
}

.CalendarDay__selected_end {
  border-radius: 0px 100px 100px 0px !important;
}

.CalendarMonth_caption {
  color: #000 !important;
  font-size: 16px !important;
}

.datepicker-navigation-arrow-prev {
  margin-top: 7px;
}

.datepicker-navigation-arrow-next {
  margin-top: 7px;
  transform: rotate(180deg);
}

.CalendarDay__default {
  font-size: 12px !important;
  height: 20px !important;
  width: 30px !important;
}
.DayPicker_weekHeader_li {
  width: 30px !important;
}
.DayPicker_weekHeader {
  &:nth-child(2){
    left: 236px !important;
  }
}
.CalendarMonth_table {
  border-collapse: separate !important;
  border-spacing: 0 10px !important;
}
.DayPicker_transitionContainer {
  height: 270px !important;
  width: 488px !important;
}

.DayPicker {
  width: 500px !important;
}
.DayPicker_focusRegion{
  width:488px !important;
}