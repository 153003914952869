.checkbox-container {
  min-width: 16px;
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #e4f1ff;
  border: 1.5px solid #017cf8;
  border-radius: 4px;
  margin-left: 5px;

  &-circle{
    border-radius: 50px;
  }
}


.checkbox-inner-box{
  width: 8px;
  height: 8px;
  background: #017cf8;
  border-radius: 2px;

  &-circle{
    border-radius: 50px;
  }
}