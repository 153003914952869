.product-page-skeleton {
    padding: 0 40px;
  .info-box {
    display: flex;
    width: 55%;
    max-width: 665px;
    margin: 1rem auto;
    padding: 22px;
    background: #e4f1ff;
    border-radius: 12px;
    justify-content: space-evenly;
    text-align: center;
    color: #75787E;
    align-items: center;
    font-size: 16px;
    height: 121px;
    box-shadow: 0px 1px 7px 3px rgba(0,0,0,0.17);
    p{
        width: 70%;
    }
  }
.filter{
    display: flex;
    gap: 20px;
    padding: 24px 0 24px 10px;
}
  .tags-skeleton{
      span{
          display: flex;
          gap: 8px;
      }
  }
}
