.info-container {
  position: relative;
  &-tooltip {
    display: flex;
    position: absolute;
    z-index: 10;
    align-items: center;
    margin: auto;
    justify-content: center;

    &.hide {
      display: none;
    }
    &.TOP {
        top: auto;
        bottom: 100%;
        right: 0;
        left: 0;
        margin-bottom: 5px;
        .info-container-tooltip-wrapper {
          &::before {
            width: 0;
            height: 0;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-top: 5px solid #242424;
            bottom: -5px;

          }
        }
      }
    &.BOTTOM {
        top: 100%;
        bottom: auto;
        right: 0;
        left: 0;
        margin-top: 5px;
        .info-container-tooltip-wrapper {
          &::before {
            width: 0;
            height: 0;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-bottom: 5px solid #242424;
            top: -5px;

          }
        }
      }
    &.RIGHT {
      top: 0;
      bottom: 0;
      left: auto;
      right: 100%;
      margin-right: 5px;
      .info-container-tooltip-wrapper {
        &::before {
          width: 0;
          height: 0;
          border-top: 5px solid transparent;
          border-bottom: 5px solid transparent;
          border-left: 5px solid #242424;
          right: -5px;
          top: 0;
          bottom: 0;
          margin: auto;
        }
      }
    }
    &.LEFT {
        top: 0;
        bottom: 0;
        right: auto;
        left: 100%;
        margin-left: 5px;
        .info-container-tooltip-wrapper {
          &::before {
            width: 0;
            height: 0;
            border-top: 5px solid transparent;
            border-bottom: 5px solid transparent;
            border-right: 5px solid #242424;
            left: -10px;
            top: 0;
            bottom: 0;
            margin: auto;
          }
        }
      }
    &-wrapper {
      background: #242424;
      color: #fff;
      padding: 10px;
      display: flex;
      cursor: cell;
      justify-content: center;
      word-break: break-all;
      line-height: 1.2;
      border-radius: 5px;
      &::before {
        content: "";
        width: 0;
        height: 0;
        position: absolute;
      }
    }
  }
  &-icon {
    line-height: 0;
    > svg {
      width: 16px;
      height: 16px;
    }

    cursor: pointer;
  }
}
