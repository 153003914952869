.logic-wrapper {
  rect {
    stroke-width: 0 !important;
    fill: white;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  }
.foreign-container{
  overflow:visible;
}
  .question-wrapper {
    background: #e4f1ff;
    color: #0f0f0f;
    border-radius: 10px;
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    .quesIcon {
      min-height: 25px;
      min-width: 25px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 10px;
      background-color: #c7e3ff;
      border-radius: 6px;
    }
    &.question {
      gap: 25px;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
      justify-content: flex-start;
      font-size: 14px;
      span{
        width: 75%;
      }
    }
    &.answer {
      background-color: #ffffff;
      color: #017cf8;
      text-align: center;
      font-size: 14px;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
      cursor: pointer;
      font-weight: 500;
      border: 1px solid #e4f1ff;
      &:hover {
        background-color: #e4f1ff;
      }
    }
    &.active {
      background-color: #017cf8;
      color: #ffffff;
      &:hover {
        background-color: #017cf8;
        color: #ffffff;
      }
    }
  }
}
