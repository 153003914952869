.unsuccessful-load-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.unsuccessful-load-image {
  max-width: 442px;
  width: 100%;
  object-fit: contain;
  margin-bottom: -90px;
}

.unsuccessful-load-details {
  font-family: 'Satoshi';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 40px;
  /* or 200% */

  text-align: center;
  letter-spacing: -0.02em;

  color: #000000;
  max-width: 452px;
  margin-top: 25px;
}