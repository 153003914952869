$row-titles-weight: 400;
$row-titles-font-size: 12px;
$row-label-width: 19%;
$row-pickers-width: 65%;
.design-v2 {
  display: flex;
  flex-direction: column;
  padding-left: 5px;
  //width: 80%;
  //margin: 15% auto 0;
  .design-v2-divider {
    border-bottom: 1px solid #dbe1e7;
  }
  .design-v2-font-label {
    width: $row-label-width;
    font-size: $row-titles-font-size;
  }
  .colorTag:hover {
    border: 1px solid #212121;
    cursor: pointer;
  }
  .colorTag {
    cursor: pointer;
    height: 37px;
    border: 1px solid #dbe1e7;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 9px;
    gap: 12px;
    .color {
      width: 20px;
      height: 20px;
      border-radius: 100%;
      background: #8ccff2;
    }
  }
}
.design-v2-colors-controller{
  max-width: 100%;
  margin-bottom: 27px;
}
.design-row-titles {
  font-weight: $row-titles-weight;
  font-size: $row-titles-font-size;
  display: flex;
  align-items: center;
  gap: 15px;
}
.design-v2-rows {
  display: flex;
  flex-direction: column;
  gap: 15px;
  font-weight: 500;
}
.design-property-row {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  height: 60px;
  font-weight: 500;
  .design-property-name {
    width: $row-label-width;
    font-size: $row-titles-font-size !important;
  }
  .design-properties {
    display: flex;
    align-items: center;
    width: $row-pickers-width;
    gap: 30px;
  }
  .design-properties-titles {
    p {
      min-width: 63px;
      text-align: center;
    }
  }
}
.design-v2-font-controllers {
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #dbe1e7;
  font-size: 12px;
  width: 100%;
}
.design-v2-font-row {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  .fontContainer {
    width: 75%;
  }
}
.corners-container{
 margin-top: 26px;
 display: flex;
 align-items: center;
 .corners-button-heading{
  width: 25%;
 }

 .corners-tabs{
  .create-exp-tabs-group{
    .design-exp-tabs-group-active{
      background-color: #017cf8;
      color: white;
      box-shadow: -2px 0px 4px rgba(0, 0, 0, 0.25), 2px 0px 4px rgba(0, 0, 0, 0.25);
    }
  }
 }
}