.insights {
  max-height: fit-content;
  //overflow-y: auto;
  margin-left: 8px;
  margin-bottom: 40px;

  > div:first-child {
    display: flex;
    align-items: center;
    margin-left: 8px;

    .heading {
      font-weight: 500;
      font-size: 15px;
      line-height: 20px;
      margin-left: 12px;
    }
  }

  > div:not(:first-child) {
    height: 800px;
    padding-bottom: 32px;
    .card {
      margin-top: 20px;
      background: rgba(228, 241, 255, 0.5);
      border-radius: 16px;
      width: 100%;
      padding: 12px;

      &-title {
        font-weight: 500;
        font-size: 15px;
        line-height: 20px;
        color: #000000;
      }

      &-subtitle {
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        color: #75787E;
      }
    }

    .tag {
      background: #F3F5F7;
      border-radius: 6px;
      padding: 6px;
      display: inline-block;
      margin-right: 8px;
      margin-top: 8px;
      min-width: 48px;
      border: 1px solid lightgray;
      .tag-type {
        font-style: normal;
        font-weight: 400;
        text-transform: capitalize;
        font-size: 8px;
        line-height: 16px;
        color: #75787E;
      }

      .tag-name {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        color: #0F0F0F;
      }
    }

    .product {
      display: flex;
      gap: 8px;
      margin-top: 16px;
      align-items: flex-start;
      margin-bottom: 4px;
      &-image {
        width: 38px;
        height: 38px;
        border-radius: 5px;
      }

      &-name {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 120%;
        color: #000000;
      }
    }
  }
}

.insight-cities{
  padding: 7px 10px;
  border-radius: 6px;
  background-color: #F3F5F7;
  border: 1px solid lightgray;
}