.radio-button {
  border: 2px solid #017cf8;
  height: 20px;
  width: 20px;
  border-radius: 6px;
  margin-right: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    width: 60%;
    height: 60%;
    border-radius: 2px;
  }
}
.container {
  display: flex;
  justify-content: flex-end;

  .dialog-btn {
    border-radius: 8px;
    padding: 16px 32px;
    border: none !important;
    font-weight: 500;
    font-size: 16px;
    height: 48px;
    display: flex;
    align-items: center;
  }
}
.dialog-content {
  p {
    color: #75787e;
    font-weight: 400;
    font-size: 16px;
    margin-bottom: 20px;
  }
  h4 {
    font-size: 24px !important;
    color: #0f0f0f;
    margin-bottom: 12px;
  }
}

.do-not-show-again-container {
  margin-right: auto;
  display: flex;
  align-items: center;
  font-size: 14px;
  gap: 10px;
  color: #75787e;
  cursor: pointer;
}

.popover-filter {
  background-color: #e4f1ff;
  width: 270px;
  height: 360px;
  padding: 20px;
  position: relative;
  > div:first-child {
    position: sticky;
    top: 0;
    z-index: 1;
  }
  .list-container {
    margin-top:16px;
    height: 80%;
    margin-right: -16px;
    overflow-y: auto;
  }
  input {
    height: 40px;
    width: 232px;
    border: 1px solid #dbe1e7;
    border-radius: 8px;
    padding-left: 32px;
  }
  .product-search-tag-list-api-container {
    position: absolute;
    top: 35px;
    left: 15px;
    max-height: 190px;
    overflow-y: scroll;
    background-color: #e4f1ff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 10px;
  }

  .product-search-tag-list-api-container::-webkit-scrollbar {
    width: 4px;
    background: none;
    border-radius: 10px;
    padding-right: 2px;
  }

  .product-search-tag-list-api-container::-webkit-scrollbar-thumb {
    width: 4px;
    margin-right: 2px;
    background: #aeb2ba;
    -webkit-border-radius: 1ex;
    border-radius: 1ex;
  }

  .product-search-tag-list-api-box {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
  }

  .product-search-type-list-api-info-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    margin-left: 10px;
    flex: 1;
    padding: 5px 0;
    border-bottom: 1px solid #dbe1e7 !important;
    flex-wrap: wrap;
  }

  .product-search-tag-list-api-info-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-left: 10px;
    flex: 1;
    padding: 5px 0;
    border-bottom: 1px solid #dbe1e7 !important;
    flex-wrap: wrap;
    max-width: 145px !important;
  }

  .product-search-tag-list-api-name {
    font-family: "Satoshi";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */

    letter-spacing: -0.02em;

    color: #000000;
  }

  .product-search-tag-list-api-type {
    font-family: "Satoshi";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    /* identical to box height, or 167% */

    letter-spacing: -0.02em;

    color: #75787e;
    text-transform: capitalize;
  }

  .products-edit-tags-pop-up-selected-tag-header {
    font-family: "Satoshi";
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 20px;
    /* identical to box height, or 200% */

    letter-spacing: -0.02em;

    color: #75787e;
    margin-top: 10px;
  }
}
