.tag {
  &-inputs {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 10px;
    row-gap: 10px;
    column-gap: 10px;
  }
  &-input {
    height: 32px;
    width: fit-content;
    padding: 8px 12px;
    background: #f3f5f7;
    font-size: 0.75rem;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    color: #75787e;
    border-radius: 6px;
    font-weight: 500;

    &.edit {
      &-value {
        padding-right: 6px;
      }
    }
    &-close {
      cursor: pointer;
      > svg {
        width: 16px;
        height: 16px;
      }
    }
  }
  &-label {
    font-size: 0.875rem;
    font-weight: 400;
    color: #75787e;
    padding-bottom: 10px;
  }
  &-search {
    position: relative;
    .tags-autosuggest {
      &__suggestions-container {
        overflow: auto;
        position: absolute;
        top: 110%;
        width: 100%;
        max-height: 250px;
        z-index: 1;
      }
      &__suggestions-list {
        background-color: #242424;
        color: #fff;
        border: 1px solid #0f0f0f;
        box-sizing: border-box;
        box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
        border-radius: 8px;
        padding: 12px;
      }
      &__suggestion {
        min-height: 36px;
        display: flex;
        align-items: center;
        cursor: pointer;
        padding: 12px;
        border-radius: 4px;
        
        &:hover {
          background: #017cf8;
        }
      }
    }
  }

  &-OK {
    background: #f0faeb;
    color: #5bb531;
  }
}
.input-loading {
  position: absolute;
  right: 8px;
  top: 16px;
  .loader {
    font-size: 10px;
    margin: -4px auto;
    text-indent: -9999em;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: #bbb;
    position: absolute;
    -webkit-animation: load3 1.4s infinite linear;
    animation: load3 1.4s infinite linear;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    right: 0px;
  }
  .loader:before {
    width: 50%;
    height: 50%;
    background: #ffffff;
    border-radius: 100% 0 0 0;
    position: absolute;
    top: 0;
    left: 0;
    content: "";
  }
  .loader:after {
    background: #ffffff;
    width: 75%;
    height: 75%;
    border-radius: 50%;
    content: "";
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
  @-webkit-keyframes load3 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes load3 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
}
