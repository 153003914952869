.create-tag-pop-up-container-minimized {
  width: 100%;
  height: 100%;
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  right: -10px;
  background-color: rgb(0, 0, 0, 0.15);
  z-index: 8;
}

.create-tag-pop-up-container {
  width: 100%;
  height: 100%;
  display: flex !important;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgb(0, 0, 0, 0.15);
  z-index: 18;
  .field-label {
    font-family: 'Satoshi';
    font-size: 14px;
  }
  .tag-search {
    height: 40px;
    input {
      height: 40px;
    }
  }
}

.create-tag-pop-up-box {
  width: 545px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  /* white */

  background: #ffffff !important;
  box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.15);
  border-radius: 16px;
  padding: 20px 30px;
}

.create-tag-pop-up-header-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  .close-icon{
  path{
    stroke: #75787E!important;
  }
 
  }
}

.create-tag-pop-up-header {
  font-family: "Satoshi";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  /* identical to box height, or 133% */

  letter-spacing: -0.02em;

  /* text / main-black */

  color: #0f0f0f;
}

.create-tag-pop-up-input-box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  padding: 20px 0;
  margin-bottom: 5px;
  border-bottom: 1px solid #dbe1e7;
  .arrow-icon{
    path{
      stroke: #017CF8;
    }
  }
}

.tags-container {
  input {
    height: 40px;
  }
}
.create-tag-pop-up-input-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-right: 20px;
}

.create-tag-pop-up-input-header {
  font-family: "Satoshi";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  letter-spacing: -0.02em;

  color: #000000;
  margin-bottom: 10px;
}

.create-tag-pop-up-input-name-search {
  background: #ffffff;
  border: 1px solid #dbe1e7;
  border-radius: 8px;
  width: 200px;
  height: 40px;
  padding: 0 15px;
  font-family: "Satoshi";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  letter-spacing: -0.02em;

  /* text/secondary */

  color: #75787e;
}

.create-tag-pop-up-input-name-search:focus {
  border: 1px solid #017cf8;
}

.create-tag-pop-up-input-type-search-container {
  position: relative;
}

.create-tag-pop-up-input-type-search {
  font-family: "Satoshi";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  letter-spacing: -0.02em;

  /* text/secondary */

  color: #75787e;
  width: 195px;
  height: 40px;
  background: #ffffff;
  border: 1px solid #dbe1e7;
  border-radius: 8px;
  padding: 0 5px 0 35px;
}

.create-tag-pop-up-input-type-search:focus {
  border: 1px solid #017cf8;
}

.create-tag-pop-up-selected-tags-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  height: auto;
}

.create-tag-pop-up-selected-tags {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background-color: #ecd9ff;
  padding: 5px;
  border-radius: 6px;
  margin: 5px;
}

.create-tag-pop-up-selected-tags-left-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-right: 20px;
}

.create-tag-pop-up-selected-tags-type {
  font-family: "Satoshi";
  font-style: normal;
  font-weight: 400;
  font-size: 8px;
  line-height: 16px;
  /* identical to box height, or 200% */

  letter-spacing: -0.02em;

  color: #75787e;
}

.create-tag-pop-up-selected-tags-name {
  font-family: "Satoshi";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */

  letter-spacing: -0.02em;

  /* text / main-black */

  color: #0f0f0f;
}

.create-tag-pop-up-tags-list-container {
  position: absolute;
  top: 50px;
  left: 0;
  z-index: 10;
  background-color: #e4f1ff;
  border-radius: 8px;
  width: 195px;
  padding: 10px 5px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  max-height: 180px;
  overflow-y: scroll;
}

.create-tag-pop-up-tags-list-container::-webkit-scrollbar {
  width: 4px;
  background: none;
  border-radius: 10px;
  padding-right: 2px;
}

.create-tag-pop-up-tags-list-container::-webkit-scrollbar-thumb {
  width: 4px;
  margin-right: 2px;
  background: #aeb2ba;
  -webkit-border-radius: 1ex;
}

.create-tag-pop-up-tags-list {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 165px;
  height: 36px;
  border-radius: 4px;
  padding: 20px 5px;
}

.create-tag-pop-up-tags-list:not(:last-of-type) {
  border-bottom: 1px solid #dbe1e7;
}

.create-tag-pop-up-tags-list-text {
  font-family: "Satoshi";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  letter-spacing: -0.02em;
}
