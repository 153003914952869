@import "./styles/colors";

.App {
  position: relative;

  &-page {
    padding-left: 248px;
    transition: padding-left 0.5s;
  }

  &.minimized {
    .App-page {
      padding-left: 80px;
    }

    .loader.full {
      width: calc(100% - 80px);
    }
  }
  &.subsidebar {
    .App-page {
      padding-left: 284px;
    }

    .loader.full {
      width: calc(100% - 284px);
    }
  }
 


  .page {
    &-header {
      box-shadow: inset 0px -1px 0px #dbe1e7;
      margin: 0 40px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    &-buttons {
      display: flex;
      align-items: center;

      button,
      a {
        margin-left: 9px;
      }
    }

    &-credits-indicator {
      color: #75787e;
      font-size: 0.8rem;
      margin-right: 10px;

      &-error {
        color: #ff0000;
      }
    }

    &-title {
      margin: 0;
      padding: 32px 0;
      font-weight: 500;
      font-size: 24px;
      line-height: 24px;
      letter-spacing: -0.02em;
      color: #0f0f0f;
    }
    &-container {
      padding: 40px 40px;
    }
  }
}

a {
  text-decoration: none;
}
