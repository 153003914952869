@import "styles/fonts.scss";

* {
  list-style: none;
  outline: none;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
body{
  font-family: 'Satoshi';
}
.capitalize{
  text-transform: capitalize;
}
.skeep-app{
  border-radius: 4%;
}
