.response-page {
  margin-left: 53px;
  margin-bottom: 64px;
  .title {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    margin-top: 10px;
    line-height: 32px;
    letter-spacing: -0.02em;
    color: #0f0f0f;
  }
  .subtitle {
    font-style: normal;
    margin-top: 6px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.02em;
    color: #75787e;
  }

  .cards-wrapper {
    display: flex;
    gap: 25px;
    flex-wrap: wrap;

    .cards-container {
      width: 270px;
          min-height: 540px;
      position: relative;
      margin-top: 25px;
      padding: 5px 10px 20px 10px;
      background: #ffffff;
      border: 1px solid #dbe1e7;
      border-radius: 16px;
      .question-number {
        font-family: "Satoshi";
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: -0.02em;
        margin-top: 12px;
        color: #aeb2ba;
        flex: none;
        order: 0;
        flex-grow: 0;
      }
      .question {
        font-family: "Satoshi", sans-serif;
        font-style: normal;
        font-weight: 500;
        height: 50px;
        margin-top: 2px;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: -0.02em;
        color: #0f0f0f;
      }
      .donut-graph {
        width: 95%;
        margin: 0 auto;
        height: 50%;
      }
      .down-container {
        min-height: 130px;
        margin-top: 10px;
        .labels-container {
          display: flex;
          padding-top: 10px;

          align-items: center;
          .label-color {
            width: 13px;
            height: 13px;

            background: #7a1dbd;
            border-radius: 2px;
          }
          .label-text {
            font-family: "Satoshi", sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            margin-left: 8px;

            letter-spacing: -0.02em;

            color: #75787e;
          }
          .divider {
            color: #dbe1e7;
            margin-right: 10px;
            margin-left: 10px;
            padding: 5px 0;
            border-bottom: 1px dashed #dbe1e7;
            flex: 1;
            width: 100%;
          }
        }
      }
      .dots-pagination {
        height: 50px;
        cursor: pointer;
        gap: 4px;
        bottom: -7px;
        width: 88%;
        position: absolute;
        margin-top: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .not-active {
      border: 1px solid #e3b6b6;
    }
  }
  .response-placeholder {
    width: 80%;
    height: 100%;
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 30px;
  }
}

.banner {
  background-color: #ffe4b4;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  .banner-description {
    font-family: "Satoshi";
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: -0.02em;
    text-align: center;
  }
}

.delete-wrapper {

  div {
    display: flex;
    flex-direction: column;

    .delete-heading {
      margin-top: 40px;
      font-size: 24px;
      font-weight: 500;
      line-height: 32px;
      letter-spacing: -0.02em;
      text-align: left;
    }
    .delete-sub-heading {
      margin-top: 10px;
      font-size: 14px;
      color: #75787e;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: -0.02em;
      text-align: left;
    }
  }
}
