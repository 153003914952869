.placement-wizard{
  display: flex;
  flex-direction: column;

  .onboarding-hyperlink{
    font-family: 'Satoshi'; 
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    cursor: pointer;
    line-height: 24px;
    margin-left: 17px;
    letter-spacing: -0.02em;
    text-decoration-line: underline;
    color: #017CF8;
  }
}

.placement-item{
  gap: 10px;
  padding: 12px 0;
  font-size: 14px;
  border-bottom: 1px solid #DBE1E7;
}

.placement-container-selection{
  display: flex;
  flex-direction: column;
  overflow: auto;
  margin-top: 10px;
  margin-bottom: 60px;
  height: 25vh;
  .checkbox-container{
    border-radius: 50px;
    .checkbox-inner-box{
      border-radius: 50px;
    }
  }
}