.cross-icon {
  position: absolute;
  right: 22px;
  cursor: pointer;
  top: 24px;
}
.generate-question-wrapper {
  height: 400px;
  padding: 2px;
  overflow: hidden;
  .dialog-title {
    font-family: "Satoshi";
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    margin-top: 32px;
    line-height: 32px;

    letter-spacing: -0.02em;

    color: #0f0f0f;
  }
  .generate-question-subtitle {
    font-family: "Satoshi";
    margin-top: 12px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 15px;
    letter-spacing: -0.02em;

    color: #75787e;
  }
  .question-topic {
    font-family: "Satoshi";
    font-style: normal;
    margin-top: 22px;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    /* identical to box height, or 167% */

    display: flex;
    align-items: center;
    letter-spacing: -0.02em;

    /* text/secondary */

    color: #75787e;
  }
  .subtitle2 {
    font-family: "Satoshi";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    /* identical to box height, or 167% */

    display: flex;
    margin-top: 20px;
    align-items: center;
    letter-spacing: -0.02em;

    /* text/secondary */

    color: #75787e;
  }

  .btn {
    margin-top: 8px;
    border-color: #dae1e6;
    height: 40px;
    width: 120px;
    border-radius: 8px;
    position: relative;

    &:hover {
      border-color: #dae1e6;
    }
    &:focus {
      box-shadow: none !important;
    }
    .selected-name {
      position: absolute;
      left: 7px;
      font-size: 14px;
    }
    .icon {
      position: absolute;
      right: 7px;
    }
  }
  .btn-active {
    margin-top: 8px;
    border-color: #017cf8;
    height: 40px;
    width: 120px;
    border-radius: 8px;
    position: relative;

    &:hover {
      border-color: #017cf8;
    }
    &:active{
      border-color: #017cf8;
    }
    // &:focus{
    //   border-color: #017cf8;
    // }
    &:focus {
      box-shadow: none !important;
      border-color: #017cf8 !important;
    }
    .selected-name {
      position: absolute;
      left: 7px;
      font-size: 14px;
    }
    .icon {
      position: absolute;
      right: 7px;
    }
  }

  .generate-btn-disabled {
    svg {
      margin-right: 6px;
      fill: #75787e;
    }
    width: 166px;
    position: absolute;
    height: 38px;
    right: 22px;
    color: #75787e;
    display: flex;
    align-items: center;
    font-size: 14px;
    justify-content: center;
    bottom: 35px;
    border: none;
    background: #f3f5f7;
    border-radius: 8px;
  }
  .generate-btn-active {
    svg {
      margin-right: 6px;
      fill: #ffffff;
    }
    width: 166px;
    position: absolute;
    height: 38px;
    right: 22px;
    color: white;
    display: flex;
    align-items: center;
    font-size: 14px;
    justify-content: center;
    bottom: 35px;
    border: none;
    background: #017cf8;
    border-radius: 8px;
  }
}
.pane-container {
  background-color: #e3f1ff;
  overflow: auto;
  padding-top: 140px !important;
  padding-left: 5px;
  padding-right: 5px;
  gap: 6px;
  cursor: pointer;

  padding-top: 100px;
  &::-webkit-scrollbar {
    width: 2px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 20px;
    background-color: #dbe1e7;
  }

  .dropdown-item-selected {
    .dropdown-item-selected:first-child {
      margin-top: 60px;
    }
    background-color: #017cf8;
    padding-left: 16px;
    padding-top: 10px;
    font-size: 14px;
    border-radius: 8px;

    padding-bottom: 10px;
    color: #ffffff;
  }

  .dropdown-item {
    padding-left: 16px;
    padding-top: 10px;
    border-radius: 8px;
    font-size: 14px;
    padding-bottom: 10px;
    color: #000;
    .dropdown-item:first-child {
      margin-top: 60px;
      &:hover {
        color: #ffffff;
      }
    }
    &:hover {
      color: #ffffff;
    }
  }
}
