$primary: #5E1493;

@mixin mobile {
  @media screen and (max-width: 769px) {
    @content
  }
}

@mixin bigDesktop {
  @media screen and (min-width: 1500px) {
    @content
  }
}


#onboarding-side-step-1{

  .skeep-app{
    scale:0.51 !important;
    height: 130% !important;
    top: 0 !important;

    @include mobile{
      scale:0.76 !important;
      height: 0 !important;
      top: 103% !important;
      left: -45.5% !important;
    }
  }

  .skeep-popup{
    scale: 1.5 !important;
    height: 100vh !important;
    left: 67% !important;
    bottom: 136px !important;
    transform: translate(-50%,0) !important;
    animation: none !important;

    @include bigDesktop{
      left: 63% !important;
    }

    @include mobile{
      scale: 1.32 !important;
      left: 126% !important;
      bottom: 13.6% !important;
      max-height: 74.5vh !important;
      box-shadow: 0 -5px 10px rgba(black,0.2);
      border-radius: 30px 30px 0 0 !important;

      #skp-exp-footer{
        bottom: 15% !important;
      }

      .email-modal-content-holder{
        height:40% !important;
      }
    }
  }

  .skeep-popup-parent{
    backdrop-filter: none;
  }


  .generator-page{
    background-repeat: no-repeat;
    background-size: cover;
    color: $primary;
    position: relative;
    min-height: 100vh;
    height: 100% !important;

    @include mobile{
      height: calc(100% - 40px) !important;
      max-height: calc(100% - 40px) !important;
    }

    *{
      box-sizing: border-box;
      font-family:"Lexend",sans-serif;
    }
  }

  $ctaElementsHeight: 58px;

  .generator-header{
    display:flex;
    justify-content:space-between;
    align-items:center;
    width: 100%;
    box-sizing: border-box;
    padding: 0 50px;
    height: 80px;

    @include mobile{
      padding: 0 20px;
    }

    .ShopifyButton{
      margin-top: 0;
      width: 230px !important;
      max-width: 230px !important;

      @include mobile{
        scale: 0.7;
        margin-left: 70px;
      }
    }
  }

  .generator-page-content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items:center;
    height: calc(100vh - 120px);
    max-height: calc(100vh - 120px);

    @include mobile{
      height: 100%;
      max-height: 100%;
      padding: 0 20px;
      justify-content: flex-start;
      padding-top: 50px;
    }
  }

  .generator-title{
    font-size: 46px;
    font-family: "Lexend", sans-serif;
    font-weight:600;
    width: 750px;
    text-align: center;
    margin-bottom: 18px;

    @include mobile{
      width: 100%;
      font-size: 24px;
      text-align: center;
    }
  }

  .subtitle-second-subtitle{
    @include mobile{
      margin-top: 20px;
    }
  }
  .generator-subtitle{
    max-width: 460px;
    font-family: "Lexend", sans-serif;
    font-weight: 300;
    font-size: 22px;
    text-align: center;
    margin-top: 0;

    @include mobile{
      text-align: center;
      font-size: 18px;
    }
  }

  .generator-cta-section{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
    margin-top: 50px;

    @include mobile{
      flex-direction: column;
      width: 100%;
    }
  }



  .generator-input {
    width: 450px;
    height: $ctaElementsHeight;
    font-size: 16px;
    border-radius: 8px;
    background-color: white;
    border: 2px solid #DBE1E7;
    outline: 0;
    padding: 0 5%;
    display: flex;
    justify-content: center;
    text-align: center;
    color: $primary;

    &::placeholder {
      color: $primary;
    }

    @include mobile{
      width: 100%;
    }
  }

  .generator-input:focus {
    border: 2px solid $primary;
  }

  .generator-input-button {
    height: $ctaElementsHeight;
    padding: 0 20px;
    border-radius: 8px;
    outline: 0;
    border: 0;
    background-color: $primary;
    color: white;
    font-weight: 600;
    font-size: 16px;
    z-index: 10;
    cursor: pointer;
  }

  .generator-input-button:hover{
    background-color: $primary;
  }

  .generator-side-image1{
    position: absolute;
    transform: translate(-50%, -50%);
    top: 55%;
    height: 45%;
    left: 12vw;
    z-index: 5;

    @include mobile{
      top: 84%;
      left: 20vw;
      height: 180px;
    }
  }

  .generator-side-image2{
    position: absolute;
    top: 50%;
    right: -10px;
    height: 33%;
    transform: translateY(-50%);
    z-index: 0;
    @include bigDesktop{
      height: 38%;
    }

    @include mobile{
      top: 79%;
      right: -10px;
      height: 160px;
    }
  }

  .generator-mockup{
    position: absolute;
    bottom: 0;
    left:50%;
    transform:translate(-50%,0);
    height: 650px;

    @include mobile{
      height: auto;
      width: 100%;
      bottom: -20%;
    }
  }

  .gradient-text{
    background: -webkit-linear-gradient(#7B1DBD, #017CF8);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .mockup-title {
    font-size: 32px;
    z-index: 1;
    top: 22%;
    font-weight: 600;
    position: absolute;
    text-align: center;

    @include bigDesktop{
      top: 37%;
    }

    .loading-dots{
      position: absolute;
      right: 20%;
      bottom:20%;
    }

    @include mobile{
      font-size: 28px;
      top: 10%;
    }
  }



  .generator-loading-title{
    font-size: 32px;
    position: absolute;
    top: 9vh;

    @include mobile{
      text-align: center;
      font-size: 22px;
      top: 11vh;
      width: 80%;
    }
  }

  .generator-full-exp-modal{
    padding: 30px 70px;
    border-radius: 20px;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    top: 62%;
    left: 50%;
    width: 500px;
    z-index: 20000;
    background-color:white;
    transform: translate(-50%,-50%);
    box-shadow: 0 4px 14px rgba(black,0.5);

    @include mobile{
      text-align: center;
      width: 95%;
      top: 50%;
      padding: 20px 20px;
    }

    * {
      margin: 0;
      padding: 0;
    }
    .generator-modal-title{
      text-align: center;

      @include mobile{
        font-size: 16px;
      }
    }

    .generator-modal-subtitle{
      font-size: 16px;
      margin-bottom: 30px;
    }



    .generator-disclaimer{
      color: #75787E;
      font-weight:300;
    }

    .disclaimer-link{
      text-decoration:underline;
      cursor: pointer;
    }


  }

  .generator-cta-stars{
    position: fixed;
    transform: translate(-50%,-50%);
    top: 50%;
    left: 50%;
    z-index: -1
  }

  .generator-cta-mobile-stars{
    display:none;

    @include mobile{
      display: block;
      position: absolute;
      transform: translate(-50%,-50%);
      top: 78%;
      left: 50%;
      height: 40%;
      z-index: 6;
    }
  }

  .modal-overlay{
    position: absolute;
    height: 68.7%;
    width: 52%;
    bottom: 0;
    left: -2%;
    background-color: rgba(black,0.2);
    z-index: 1002;
    transform: translate(50%,0);

    @include mobile{
      position: fixed;
      width: 110vw;
      height: 77.5%;
      right: 0;
      bottom: 0;
      transform: translate(0,0);
    }
  }

  .generator-email-sent-content{
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;

    p{
      font-weight: 600;
    }

    .ShopifyButton{
      padding: 10px 35px;
    }

    .gradient-text{
      font-size: 20px;
      text-align: center;
      margin-bottom: 20px;
    }
  }

  .generator-error-text{
    margin-top: 20px;
    font-size: 14px;
    pointer-events: none;
    user-select: none;
  }

  .skeep-popup-parent {
    backdrop-filter: none !important;
  }

  .quiz-overlay{
    position: absolute;
    bottom: 0;
    left: 20%;
    background-color: rgba(black,0.2);
    height: 614px;
    width: 60%;
    z-index: 10;

    @include mobile{
      width: 100%;
      left: 0;
      height: 79%;
    }
  }

  #quiz-overlay-email-modal{
    z-index: 1001;
  }

  .stars-container-new{
    position: absolute;
    width: 400px;
    height: 400px;

    .loading-star{
      position: absolute;
    }

    .star-1{
      top: 5%;
      left: 5%;
    }

    .star-2{
      top: 15%;
      left: 25%;
    }

    .star-3{
      top: 45%;
      left: 35%;
    }

    .star-4{
      top: 56%;
      left: 45%;
    }

    .star-5{
      top: 50%;
      left: 75%;
    }
  }

  .stars-container-reposition{
    position: absolute;
    width: 400px;
    height: 400px;

    .loading-star{
      position: absolute;
    }

    .star-1{
      top: 23%;
      left: 42%;
    }

    .star-2{
      top: 34%;
      left: 12%;
    }

    .star-3{
      top: 25%;
      left: 55%;
    }

    .star-4{
      top: 66%;
      left: 85%;
    }

    .star-5{
      top: 60%;
      left: 85%;
    }
  }

  .darken-element{
    filter: brightness(0.5);
  }

  .generator-products-mockup{
    display: flex;
    position: relative;
    width: 100%;
    background-repeat: no-repeat;
    background-position:center;
    background-size: contain;
    height: 510px;
  }

  .generator-products{
    display: flex;
    flex-direction: column;
    width: 95%;
    position: absolute;
    box-shadow: 0 0 20px #bbb;
    
    top: 53%;
    border-radius: 15px;
    left: 50%;
    transform: translate(-50%,-50%);
    background-color: white;
    z-index: 99;

    @include bigDesktop{
      width: 85% !important;
    }

    @include mobile{
      transform: translate(-50%,-113%);
      position:fixed;
      top: -25%;
    }
  }

  .product-row{
    display: flex;
    flex-direction: column;
    width: 100%;
    //height: 120px;
    padding: 10px;
    border-bottom: 1px solid #E4E4E4;

    .product-row-top{
      display: flex;


      img{
        height: 60px;
        width: auto;
        border-radius: 4px;
        object-fit: cover;
        margin-right: 15px;
      }

      .product-row-name{
        font-size: 16px;
        margin: 0;
        color: #75787E;

      }



      .product-row-type{
        margin: 0;
        margin-left: auto;
        padding: 12px;
        font-size: 14px;
        height: fit-content;
        background: #E4F1FF;
        color: black;
        border-radius:5px;
      }
    }

    .product-row-bottom{
      display:flex;
      gap: 10px;
      justify-content: flex-start;
      margin-top:15px;
      overflow: hidden;
    }

    .tag-skeleton{
      height: 54.5px;
      border-radius:8px;
      width: 54.5px;
    }

    .product-row-tag{
      margin: 0;
      padding: 10px;
      height: fit-content;
      background: #E4E4E4;
      color: black;
      display:flex;
      flex-direction: column;
      gap: 5px;
      border-radius:8px;
    }

    .product-row-tag-type{
      font-size: 11px;
      color: #75787E;
      font-weight:200;
      font-family:"Lexend",sans-serif;
      margin:0;
      white-space: nowrap;
    }

    .product-row-tag-name{
      margin:0;
      white-space: nowrap;
      font-size: 13px;
      color: black;
    }
  }

  .white-mobile{
    width: 100vw;
    z-index:1;
    background-color: white;
    position: fixed;
    left: 0;
    top: 43%;
    height:56px;
  }

  .not-shopify-modal{
    position: fixed;
    transform: translate(-50%,-50%);
    top: 50%;
    left: 50%;
    z-index: 100;
    background-color: white;
    border-radius:15px;
    padding: 30px;
    box-shadow: 0 3px 8px rgba(0,0,0,.4);
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    @include mobile{
      width: 96%;
      padding: 30px 15px;
    }

    h1,p{
      margin: 0;
    }

    h1{
      margin-bottom: 12px;
      font-size: 22px;

      @include mobile{
        font-size: 26px;
      }
    }

    .generator-full-exp-modal-input-container{
      margin-bottom: 0;
    }

    .not-shopify-modal-subtitle{
      margin-bottom: 30px;
      font-size: 14px;
      width:390px;
      text-align: center;

      @include mobile{
        width:100%;
      }
    }
  }

  .generator-full-exp-modal-input-container{
    display: flex;
    align-items: center;
    justify-content:center;
    height: 45px;
    width: 100%;
    margin-bottom: 20px;

    input{
      border: 2px solid $primary;
      outline: 0;
      padding: 0 14px;
      width: 100%;
      font-size: 16px; // If lower than 16 -> iPhone auto zooms in (BAD).
      height: 100%;
      border-radius: 10px 0 0 10px;
    }
  }

  .generator-modal-button{
    border-radius: 0 8px 8px 0;
    height: 100%;
    font-size: 14px;
    border: 2px solid $primary;
    outline: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 65px;

    background-color: $primary;
    color: white;
  }

  .modal-bg {
    position: fixed;
    z-index: 99;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0,0,0);
    background-color: rgba(0,0,0,0.4);
  }

  .not-shopify-modal-thankyou{
    font-size: 18px;
    margin-bottom: 10px;
  }
}
