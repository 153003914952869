.error-field {
    color: red;
}

.create-password-container {
    .tag-note {
        font-size: 0.75rem;
        color: #75787E;
        padding-left: 5px;
    }

    .tags-wrap {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        row-gap: 10px;
        column-gap: 10px;
        margin-bottom: 30px;
        margin-top: 16px;
    }
}

.tag-note-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 430px;
}

.form-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
}

.auth-title-new {
    font-family: 'Satoshi';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 24px;
    /* identical to box height, or 60% */

    letter-spacing: -0.02em;

    color: #000000;
    margin-bottom: 15px;
}

.auth-send {
    margin-top: 10px;
}

.auth-send-two {
    margin-top: 20px;
}

.auth-underline {
    text-decoration: underline;
}

.auth-subtitle-new {
    margin-bottom: 110px !important;
}

.auth-subtitle-new-two {
    margin-bottom: 80px !important;
}

.footer-header {
    font-family: 'Satoshi';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */
    
    letter-spacing: -0.02em;
    
    color: #000000;
}

.footer-subheader {
    font-family: 'Satoshi';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */
    
    letter-spacing: -0.02em;
    
    color: #75787E;
    margin-top: 12px;
}

.auth-image-verification {
    width: 320px;
    height: 181px;
   
   
  
    object-fit: contain;
    margin: 50px 0 45px 0;
}