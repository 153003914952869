@import "../../styles/colors";

.brand-widget {
// height: 48px;
    left: 0;
    right: 0;
// padding-top: 10px !important;
  .title {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: black !important;
    width: 140px;
    overflow-wrap: break-word;
    white-space: normal;
  }

  .menu-button {
    width: 0;
    min-width: 0;
    padding: 8px;

    &:hover,
    &:focus {
      background: none;
      outline: none;
      box-shadow: none;
    }
  }

  &-menuitem {
    li {
      margin-right: 8px;
      margin-left: -8px;
      padding-left: 8px;
      cursor: pointer;
      border-radius: 4px;
      display: flex;
      // justify-content: space-between;
      align-items: center;
      height: 36px;
      font-family: "Satoshi";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;

      svg {
        fill: #017cf8;
      }

      &:hover {
        background: #017cf8;
        color: white !important;

        svg {
          fill: white;
        }
      }
    }
  }

  .label {
    font-weight: 500;
    font-size: 12px;
    margin-bottom:5px;
    line-height: 20px;
  }

  .progress {
    background: #e4f1ff;
    border-radius: 10px;
    width: 100%;
    height: 9px;
    position: relative;

    &-inner {
      position: absolute;
      left: 0;
      top: 0;
      height: 9px;
      background: #017cf8;
      border-radius: 10px;
    }
  }

  .upgrade-link {
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    text-decoration: underline;
    margin-top: 8px;
  }
}

.brand-add-account-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid #dbe1e7;
  padding: 12px 12px 12px 2px;
  cursor: pointer;
}

.brand-add-account-text {
  font-family: "Satoshi";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */

  letter-spacing: -0.02em;

  color: #017CF8;
  margin-left: 7px;
}

.brand-widget-menuitem
  li:hover
  > .brand-add-account-container
  > .brand-add-account-text {
  color: white !important;
}

.brand-widget-menuitem
  li:hover
  > .brand-add-account-container
  > .brand-add-account-icon {

  stroke: white !important;

}

.store-name-btn{
  display: flex;
  margin: 8px 8px 0px -8px ;
  width: 200px;
  align-items: center;
  height: 48px;
  border-radius: 8px;
  cursor: pointer;
  &:hover {
    background: $primary;
    color: #fff;
  
    svg > path {
      fill: #fff;
      stroke: #fff;
    }
    svg > rect {
      fill: #fff;
      stroke: #fff;
    }
  }
}

.store-logout-btn{
  width: 200px;
  height: 48px;
  display: flex;
  align-items: center;
  margin: 0px 8px 8px -8px;
  border-radius: 8px;
  &:hover {
    background: $primary;
    color: #fff;
  
    svg > path {
      fill: #fff;
      stroke: #fff;
    }
    svg > rect {
      fill: #fff;
      stroke: #fff;
    }
  }
}