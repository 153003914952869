@mixin bigDesktop {
  @media screen and (min-width: 1500px) {
    @content;
  }
}

.product-page {
  * {
    font-family: "Satoshi" !important;
  }
  .product-table-container {
    table {
      height: 65vh;
      display: block;
      overflow-y: scroll;
      background: white;
      padding: 0 20px 0 0;
      &::-webkit-scrollbar {
        width: 2px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 20px;
        background-color: #dbe1e7;
      }
      thead {
        position: sticky;
        top: 0;
        background-color: white;
        z-index: 8;
      }
    }
  }

  .product-type {
    > div {
      display: inline-block;
      > .products-filter-with-background {
        height: 16px;
        padding: 0 !important;
        width: 12px;
        background-color: transparent !important;
        &.products-edit-type-pop-up-container {
          background-color: #e4f1ff !important;
        }
        svg {
          path {
            stroke: #017cf8 !important;
          }
        }
      }
    }
  }
  .products-table-tag-add {
    position: relative;

    > div {
      // display: inline-block;
      > div {
        background-color: transparent;
        &.products-edit-type-pop-up-container {
          background-color: #e4f1ff !important;
        }
        svg {
          path {
          }
        }
      }
    }
  }
  .tag-delete-button {
    position: absolute;
    right: 4px;
    top: 0;
    cursor: pointer;
    &:hover {
      color: #017cf8;
    }
  }
  .product-edit-button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 18px;
    font-size: 14px;
    gap: 10px;
    background-color: #e4f1ff;
    color: #007aff;
    font-weight: 500;
    border-radius: 5px;
    cursor: pointer;
  }
  .product-edit-button:hover {
    background-color: #d3e6fa;
    color: #00479d;
  }
  .products-table-medium {
    min-width: 130px;
  }

  .product-add-button {
    width: 45px;
    height: 38px;
    background: #017cf8;
    border-radius: 6px;
    cursor: pointer;
    border: none;
  }

  .products-table-tags {
    flex: 1;

    @include bigDesktop {
      width: 60%;
    }
  }

  .products-head {
    padding-left: 50px;
    margin: 30px 0 5px 0;
  }

  .products-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 34px 0 0;
  }

  .products-header-text {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    /* identical to box height, or 133% */

    letter-spacing: -0.02em;

    color: #0f0f0f;
    margin-right: 20px;
    .products-header-icon {
      height: 10px;
      width: 10px;
      cursor: pointer;
      margin-left: 8px;
    }
  }

  .products-subheader {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */
    letter-spacing: -0.02em;

    color: #75787e;
    padding-left: 50px;
    margin: 0 0 10px 0;
  }

  .products-filters-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 50px;
    margin: 20px 0;
  }

  .products-filters-left-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  .products-filters-right-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }

  .products-filters {
    border-radius: 5px;
    display: inline-flex;
    margin-right: 16px;
    padding: 6px 16px;
    align-items: center;
    height: auto;
    white-space: nowrap;
    box-sizing: border-box;
    color: #017cf8;
    background: #e4f1ff;
  }

  .products-filters-text {
    font-family: "Satoshi";
    font-style: normal;
    font-weight: 500;
    // margin-right: 10px;
    font-size: 14px;
  }

  .products-filter-with-background {
    border-radius: 6px;
    padding: 5px 20px !important;
    margin-right: 10px;
    display: inline-flex;
    width: auto;
    height: auto;
    white-space: nowrap;
    box-sizing: border-box;
  }

  .products-filter-with-background-text {
    font-family: "Satoshi" !important;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    /* identical to box height, or 133% */
    .add-icon {
      display: flex;
      align-items: center;
      &:hover {
        stroke: #fff;
      }
    }

    letter-spacing: -0.02em;
  }

  .products-search {
    width: 200px;
    height: 29px;

    /* white */

    background: #ffffff;
    border: 1px solid #dbe1e7;
    border-radius: 5px;
    outline: none;
    margin: 0 0 0 15px;
    padding: 0 30px;
  }

  .products-search:focus {
    border: 1px solid #017cf8;
  }

  /* filter */

  .products-edit-type-pop-up-container {
    position: absolute;
    top: 40px;
    right: 10px;
    width: 318px;
    height: 453px;
    z-index: 1;
    background: #e4f1ff;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 20px 30px;
  }

  .products-edit-tags-pop-up-container {
    position: absolute;
    top: 40px;
    right: 10px;
    width: 341px;
    height: 450px;
    background: #e4f1ff;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 20px 30px;
  }

  .products-edit-type-pop-up-header-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .products-edit-type-pop-up-header {
    font-family: "Satoshi";
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    /* identical to box height, or 133% */

    letter-spacing: -0.02em;

    /* text / main-black */

    color: #0f0f0f;
  }

  .products-edit-tags-pop-up-header-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .products-edit-tags-pop-up-header {
    font-family: "Satoshi";
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    /* identical to box height, or 133% */

    letter-spacing: -0.02em;

    /* text / main-black */

    color: #0f0f0f;
  }

  .products-edit-type-pop-up-alert-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 5px;
  }

  .products-edit-type-pop-up-alert {
    font-family: "Satoshi";
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    /* or 240% */
    letter-spacing: -0.02em;

    /* text/secondary */

    color: #75787e;
    margin-left: 5px;
  }

  .products-edit-type-pop-up-search-container {
    position: relative;
    margin-top: 15px;
  }

  .products-edit-type-pop-up-search {
    width: 230px;
    height: 40px;
    background: #ffffff;

    border: 1px solid #dbe1e7;
    border-radius: 8px;
    padding: 0 10px 0 35px;

    font-family: "Satoshi";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */

    letter-spacing: -0.02em;

    /* text/secondary */

    color: #75787e;
  }

  .products-edit-type-pop-up-search:focus {
    border: 1px solid #017cf8;
  }

  .products-edit-tags-pop-up-search-container {
    position: relative;
    margin-top: 15px;
  }

  .products-edit-tags-pop-up-search {
    width: 280px;
    height: 40px;
    background: #ffffff;

    border: 1px solid #dbe1e7;
    border-radius: 8px;
    padding: 0 10px 0 35px;

    font-family: "Satoshi";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */

    letter-spacing: -0.02em;

    /* text/secondary */

    color: #75787e;
  }

  .products-edit-tags-pop-up-search:focus {
    border: 1px solid #017cf8;
  }

  .products-edit-type-pop-up-list-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 10px 0;
    width: 100%;
    max-height: 220px;
    overflow-y: scroll;
    padding-right: 5px;

    //.proaducts-edit-type-pop-up-list{
    //  margin: 5px 0px;
    //}

    .products-checkbox-container {
      margin-top: 8px;
      margin-right: 12px;
    }
  }

  .products-edit-type-pop-up-list-container::-webkit-scrollbar {
    width: 4px;
    background: none;
    border-radius: 10px;
    padding-right: 2px;
  }

  .products-edit-type-pop-up-list-container::-webkit-scrollbar-thumb {
    width: 4px;
    margin-right: 2px;
    background: #aeb2ba;
    -webkit-border-radius: 1ex;
    border-radius: 1ex;
  }

  .products-edit-tags-pop-up-list-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 10px 0;
  }

  .products-edit-tags-pop-up-list {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 240px;
    height: 56px;
    padding-top: 5px;
  }

  .products-edit-tags-pop-up-list-right-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 0 10px;
    border-bottom: 1px solid #dbe1e7;
    flex: 1;
    padding-bottom: 10px;
  }

  .products-edit-tags-pop-up-list-up {
    font-family: "Satoshi";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */

    letter-spacing: -0.02em;

    color: #000000;
  }

  .products-edit-tags-pop-up-list-down {
    font-family: "Satoshi";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    /* identical to box height, or 167% */

    letter-spacing: -0.02em;

    color: #75787e;
  }

  .products-edit-type-pop-up-list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 230px;
    height: 56px;
    border-radius: 4px;
    padding: 8px;
    border-bottom: 1px solid #dbe1e7;
    cursor: pointer;
  }

  .products-edit-type-pop-up-list-up {
    font-family: "Satoshi";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */

    letter-spacing: -0.02em;
  }

  .products-edit-type-pop-up-list-down {
    font-family: "Satoshi";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    /* identical to box height, or 167% */

    letter-spacing: -0.02em;
  }

  .products-edit-tags-pop-up-create-tag-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 10px;
    cursor: pointer;
  }

  .products-edit-tags-pop-up-create-tag {
    font-family: "Satoshi";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */

    letter-spacing: -0.02em;

    color: #017cf8;
    margin-left: 10px;
  }

  /* filters */

  .products-checkbox-container {
    min-width: 16px;
    width: 16px;
    height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;

    background: #e4f1ff;
    border: 1.5px solid #017cf8;
    border-radius: 4px;
    margin-left: 5px;
  }

  .products-checkbox {
    width: 8px;
    height: 8px;

    /* blue */

    // background: #017cf8;
    border-radius: 2px;
  }

  .products-table-image {
    width: 38px;
    height: 38px;
    border-radius: 5px;
    object-fit: cover;
  }
  .products-table-tag-add {
    &:hover {
      background: #017cf8 !important;

      div > p > svg {
        stroke: #fff;
      }
    }
  }

  .products-table-header {
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    line-height: 16px !important;
    /* or 120% */

    text-align: start !important;
    letter-spacing: -0.02em !important;
    color: #000000 !important;
    padding: 10px !important;
  }

  .products-table-tag {
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 20px !important;
    /* or 143% */
    letter-spacing: -0.02em !important;
    color: #75787e !important;
    padding: 10px !important;
  }

  .products-table-with-background {
    background: #e4f1ff;
    border-radius: 6px;
    padding: 8px !important;
    display: inline-flex;
    width: auto;
    height: auto;
    white-space: nowrap;
    box-sizing: border-box;
    &:hover {
      // background: #017cf8;
      .products-table-with-background-text {
        // color: #fff;
      }
      .edit-icon {
        fill: #fff;
      }
    }
  }

  .products-table-with-background-edit {
    background: #e4f1ff;
    border-radius: 6px;
    padding: 8px !important;
    display: inline-flex;
    width: auto;
    height: auto;
    white-space: nowrap;
    box-sizing: border-box;
    &:hover {
      background: #017cf8;
      .products-table-with-background-text {
        color: #fff;
      }
      .edit-icon {
        fill: #fff;
      }
    }
  }

  .products-table-with-background-text {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-transform: capitalize;
    /* identical to box height, or 133% */

    letter-spacing: -0.02em;

    /* text / main-black */

    color: #0f0f0f;
    .edit-icon {
      fill: #017cf8;
      margin-left: 7px;
      cursor: pointer;
    }
  }

  .products-table-tag-row-container {
    display: inline-flex;
    height: 100%;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    row-gap: 15px;
    //max-width: 400px;
  }

  .products-table-tag-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    background: #f3f5f7;
    border-radius: 6px;
    padding: 2px 20px 5px 8px;
    margin: 0 5px;
    width: auto;
    height: auto;
    white-space: nowrap;
    box-sizing: border-box;
    // margin-bottom: 20px;
  }

  .products-table-tag-header {
    font-style: normal;
    font-weight: 400;
    font-size: 8px;
    line-height: 16px;
    /* identical to box height, or 200% */
    text-transform: capitalize;

    letter-spacing: -0.02em;

    color: #75787e;
  }

  .products-table-tag-info {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    /* identical to box height, or 133% */
    text-transform: capitalize;

    letter-spacing: -0.02em;

    /* text / main-black */

    color: #0f0f0f;
  }

  .products-pagination-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 25px 0 25px 0;
  }

  .products-pagination-text {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    /* or 133% */

    display: flex;
    align-items: center;

    color: #000000;
    margin: 0 25px;
  }

  .products-filters-list-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding-left: 50px;
    margin-bottom: 30px;
  }

  .products-filters-list-wrap-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
  }

  .products-filters-list-clear {
    font-family: "Satoshi";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    /* identical to box height, or 133% */

    letter-spacing: -0.02em;
    text-decoration-line: underline;

    color: #017cf8;
    margin-right: 30px;
    min-width: 90px;
  }

  .products-filters-list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 5px 10px;
    background-color: #ffffff;
    border: 1px solid #017cf8;
    border-radius: 6px;
    min-width: 70px;
    max-width: 175px;
    height: 42px;
    position: relative;
    margin: 0 5px 8px 5px;
  }

  .products-filters-list-type {
    font-family: "Satoshi";
    font-style: normal;
    font-weight: 400;
    text-transform: capitalize;
    font-size: 8px;
    line-height: 16px;
    /* identical to box height, or 200% */

    letter-spacing: -0.02em;

    color: #75787e;
  }

  .products-filters-list-name {
    font-family: "Satoshi";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 120%;
    /* identical to box height, or 17px */

    letter-spacing: -0.04em;

    /* text / main-black */

    color: #0f0f0f;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 145px;
    padding-right: 15px;
  }

  .product-search-tag-list-api-container {
    position: absolute;
    top: 35px;
    left: 15px;
    max-height: 190px;
    overflow-y: scroll;
    background-color: #e4f1ff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 10px;
  }

  .product-search-tag-list-api-container::-webkit-scrollbar {
    width: 4px;
    background: none;
    border-radius: 10px;
    padding-right: 2px;
  }

  .product-search-tag-list-api-container::-webkit-scrollbar-thumb {
    width: 4px;
    margin-right: 2px;
    background: #aeb2ba;
    -webkit-border-radius: 1ex;
    border-radius: 1ex;
  }

  .product-search-tag-list-api-box {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
  }

  .product-search-type-list-api-info-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    margin-left: 10px;
    flex: 1;
    padding: 5px 0;
    border-bottom: 1px solid #dbe1e7 !important;
    flex-wrap: wrap;
  }

  .product-search-tag-list-api-info-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-left: 10px;
    flex: 1;
    padding: 5px 0;
    border-bottom: 1px solid #dbe1e7 !important;
    flex-wrap: wrap;
    max-width: 145px !important;
  }

  .product-search-tag-list-api-name {
    font-family: "Satoshi";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */

    letter-spacing: -0.02em;

    color: #000000;
  }

  .product-search-tag-list-api-type {
    font-family: "Satoshi";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    /* identical to box height, or 167% */

    letter-spacing: -0.02em;

    color: #75787e;
    text-transform: capitalize;
  }

  .products-edit-tags-pop-up-selected-tag-header {
    font-family: "Satoshi";
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 20px;
    /* identical to box height, or 200% */

    letter-spacing: -0.02em;

    color: #75787e;
    margin-top: 10px;
  }

  .products-edit-tags-pop-up-search-alert-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .products-edit-tags-pop-up-search-alert-left-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .products-edit-tags-pop-up-search-alert-left-top {
    font-family: "Satoshi";
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 24px;
    /* or 240% */

    letter-spacing: -0.02em;

    /* text/secondary */

    color: #75787e;
  }

  .products-edit-tags-pop-up-search-alert-left-bottom {
    font-family: "Satoshi";
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 24px;
    /* or 240% */

    letter-spacing: -0.02em;

    /* text/secondary */

    color: #75787e;
  }

  .products-edit-tags-pop-up-tags-scroll-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    max-height: 250px;
    overflow-y: scroll;
    margin-top: 10px;
    width: 280px;
  }

  .products-edit-tags-pop-up-tags-scroll-container::-webkit-scrollbar {
    width: 4px;
    background: none;
    border-radius: 10px;
    padding-right: 2px;
  }

  .products-edit-tags-pop-up-tags-scroll-container::-webkit-scrollbar-thumb {
    width: 4px;
    margin-right: 2px;
    background: #aeb2ba;
    -webkit-border-radius: 1ex;
    border-radius: 1ex;
  }
}

.popup-primary-button {
  opacity: 0.95;
  &:focus,
  &:hover {
    opacity: 1 !important;
    background-color: #017cf8 !important;
  }
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.add-product-button {
  padding: auto;
  width: 119px;
  height: 30px;
  border: none;
  background: #017cf8;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 500;
  color: white;
  cursor: pointer;
}

.products-edit-type-pop-up-container {
  position: absolute;
  top: 40px;
  right: 10px;
  width: 318px;
  height: 453px;
  z-index: 1;
  background: #e4f1ff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 20px 30px;
}

.products-edit-tags-pop-up-container {
  position: absolute;
  top: 40px;
  right: 10px;
  width: 341px;
  height: 450px;
  background: #e4f1ff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 20px 30px;
}

.products-edit-type-pop-up-header-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.products-edit-type-pop-up-header {
  font-family: "Satoshi";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  /* identical to box height, or 133% */

  letter-spacing: -0.02em;

  /* text / main-black */

  color: #0f0f0f;
}

.products-edit-tags-pop-up-header-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.products-edit-tags-pop-up-header {
  font-family: "Satoshi";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  /* identical to box height, or 133% */

  letter-spacing: -0.02em;

  /* text / main-black */

  color: #0f0f0f;
}
