.button {
  border: none;
  margin: 0;
  padding: 0;
  font-family: "Satoshi";
  border-radius: 8px;
  height: 48px;
  cursor: pointer;
 
  &.gray {
    background: #E4F1FF;
    color: #75787e;
    padding: 12px;
  }
  &.link {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #017cf8;
    color: #fff;
    min-width: 170px;
    padding: 0 16px;
    font-size: 0.875rem;
  }
  &.create {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #017cf8;
    color: #fff;
    min-width: 170px;
    padding: 0 16px;
    font-size: 0.875rem;
    > span {
      padding-left: 10px;
    }
    &:hover {
      background-color: #025cb7;
      transition: 0.3s linear;
    }
    &.disabled {
      pointer-events: none !important;
      background-color: #f3f5f7 !important;
      color: #75787e !important;
    }
  }
  &.add {
    background: #ffffff;
    border: 1px solid #dbe1e7;
    box-sizing: border-box;
    border-radius: 6px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px;
    height: 48px;
    min-width: 108px;
    > span {
      padding-left: 8px;
    }
  }
  &.spinloader{
    position: relative;
    background: #f3f5f7;
    border: none; 
    margin-left: 1rem;
    color: #75787e;
    font-size: 0.875rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px;
    height: 48px;
    min-width: 147px;
  }
  &.add:hover{
    border-color: black;
  }
  &.submit {
    color: #fff;
    background-color: #017cf8;
    padding: 12px;
    min-width: 147px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;

    &.disabled {
      background: #f3f5f7 !important;
      color: #75787e !important;
      border: 0 !important;
    }
    &.filter {
      font-size: 1rem;
    }

    &.filter:hover{
      border-color: black;
    }
  }
}

.button-wrap {
  display: flex;
  align-items: center;
  > svg {
    margin-right: 16px;
  }
}
