@import "../../styles/colors.scss";

.app-toggle-card {
    position: relative;
    width: 195px;
    height: 180px;

    background: #FFFFFF;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    margin: 35px 17.5px 0 17.5px;
    padding: 0px 5px 15px 10px;
}

.app-toggle-card-skeepx {

    position: relative;
    width: 195px;
    height: 180px;

    background: #EFE7FE;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    margin: 35px 17.5px 0 17.5px;
    padding: 0px 5px 15px 10px;
}

.PLATINUM_NEW_CARD{
    .app-toggle-card-image{
        top: -90px !important;
    }
}

.app-toggle-card-current {
    position: relative;
    width: 195px;
    height: 180px;

    background: #F2F9FF;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    margin: 35px 17.5px 0 17.5px;
    padding: 0px 5px 15px 10px;

    .button-container-active{
        margin: 12px 0px;
    }
}

.app-toggle-card-selected {
    position: relative;
    width: 195px;
    height: 180px;

    background: #FFFFFF;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    margin: 35px 17.5px 0 17.5px;
    padding: 0px 5px 15px 10px;
}

.app-toggle-card-disabled {

    position: relative;
    width: 195px;
    height: 180px;

    background: rgba(250, 250, 250, 0.73);
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    margin: 35px 17.5px 0 17.5px;
    padding: 0px 5px 15px 10px;
}








.app-toggle-card-image {
    position: absolute;
    object-fit: contain;
    top: -58px !important;
    left: -12px !important;
    width: 220px !important;

    // &-Free {
    //     top: -95px;
    //     left: -15px;
    //     width: 195px;
    // }

    // &-Basic {
    //     top: -45px;
    //     left: -15px;
    //     width: 223px;
    // }

    // &-Premium {
    //     top: -95px;
    //     left: -15px;
    //     width: 223px;
    // }

    // &-Platinum {
    //     top: -95px;
    //     left: -15px;
    //     width: 223px;
    // }

    // &-SkeepX {
    //     top: -95px !important;
    //     left: 0px !important;
    //     width: 225px !important;
    // }
}
.app-toggle-card-image-skeepx{
    position: absolute;
    object-fit: contain;
    top: -72px !important;
    left: -12px !important;
    width: 220px !important;
}
.app-toggle-card-header {
    font-family: 'Satoshi';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 20px;
    /* identical to box height, or 100% */

    letter-spacing: -0.02em;

    color: #017CF8;
    margin-bottom: 10px;
    align-self: flex-start;
}

.app-toggle-card-header-disabled {
    font-family: 'Satoshi';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 20px;
    /* identical to box height, or 100% */

    letter-spacing: -0.02em;

    color: $text;
    margin-bottom: 17px;
    align-self: flex-start;
}

.app-toggle-card-header-skeepx {
    font-family: 'Satoshi';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 20px;
    /* identical to box height, or 100% */

    letter-spacing: -0.02em;

    color: #5B0DF2;
    margin-bottom: 10px;
    align-self: flex-start;
}

.app-toggle-card-pricing {
    font-family: 'Satoshi';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    /* identical to box height, or 120% */
    margin-bottom: 15px;

    letter-spacing: -0.02em;

    color: #017CF8;
    align-self: flex-start;
}

.app-toggle-card-pricing-disabled {
    font-family: 'Satoshi';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 15px;
    /* identical to box height, or 120% */

    letter-spacing: -0.02em;

    color: $text;
    align-self: flex-start;
}

.app-toggle-card-no-pricing {
    font-family: 'Satoshi';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 24px;
    margin-bottom: 15px;
    /* identical to box height, or 120% */

    letter-spacing: -0.02em;

    color: #5B0DF2;
    align-self: flex-start;
}

.app-toggle-card-bold {
    font-family: 'Satoshi';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 20px;
    /* identical to box height, or 62% */

    letter-spacing: -0.02em;
}