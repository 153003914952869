.quiz-rating-tag {

  &-container {
    // display: flex;
    flex-direction: column;
  }

  &-header {
    font-family: 'Satoshi';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    /* or 133% */

    color: #000000;
    margin-bottom: 12px;
  }

  &-table {

    &-left {
      width: 116px;
      height: 52px;
      margin: 10px 16px 0 0;
      background: #F3F5F7;
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;

      &-placeholder {
        font-family: 'Satoshi';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
        /* identical to box height, or 167% */

        letter-spacing: -0.02em;

        /* text/secondary */

        color: #75787E;
      }
    }

    &-container {
      // display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
    }

    &-header {
      font-family: 'Satoshi';
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 20px;
      /* or 167% */

      letter-spacing: -0.02em;

      color: #000000;
      text-align: start;

      &-left {
        font-family: 'Satoshi';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
        /* or 167% */

        letter-spacing: -0.02em;

        color: #000000;
        text-align: start;
        width: 132px;
      }

      &-container {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 10px;
      }
    }
  }
}