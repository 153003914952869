.auth-welcome {
  min-height: 100vh;
  max-width: 800px;
  background-image: url("../../../assets/images/signup-image.svg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #000000;
  display: flex;
  position: relative;
  flex-direction: column;
  padding: 32px 25px;
  &-logos{
    display: flex;
    align-items: center;
    gap: 30px;


  }

  .auth-svg{
    position: absolute;
    top: 32%;
    left: 5%;
    height: 500px;
    width: calc(100% - 50px);
    filter: drop-shadow(0 3px 5px rgba(black, 0.2));
  }

  &-wrapper {
    flex-direction: column;
    min-height: 100%;
    display: flex;
    flex: 1;
    justify-content: space-between;
    text-align: center;
    max-width: 520px;
    align-self: center;
    padding-top: 4%;
  }
 

  &-title {
    font-size: 40px;
    padding-bottom: 32px;
    line-height: 1;
    color:#540E87;
    padding-bottom: 22px;
    font-weight:800
  }

  &-desc {
   font-size: 18px;
    font-style: normal;
    font-weight: 600;
    color: #454F5B;
    span{
      color: #BE6EF7;
      font-family: PT Serif;
      font-style: italic;
    }
  
  }

  &-company {
    padding-top: 40px;
  }
}

.auth-welcome-50-width {
  width: 50%;
}

.auth-welcome-40-width {
  width: 40%;
}
