@import "../../styles/classes";

.user-page {
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
}

.user-content {
  padding: 40px 60px;
}


.user-delete-member-pop-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  right: -124px;
  background: rgba(0, 0, 0, 0.15);
}

.user-delete-member-pop-container-minimized {
  width: 100%;
  height: 100%;
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.15);
}

.user-delete-member-pop {
  width: 440px;
  height: 220px;

  background: #FFFFFF;
  box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.15);
  border-radius: 16px;
  padding: 32px 26px 32px 32px;
}

.user-delete-member-pop-header {
  font-family: 'Satoshi';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  /* identical to box height, or 133% */

  letter-spacing: -0.02em;

  /* text / main-black */

  color: #0F0F0F;
}

.user-delete-member-pop-info {
  font-family: 'Satoshi';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  letter-spacing: -0.02em;

  /* text/secondary */

  color: #75787E;
  margin-top: 12px;
}

.user-delete-member-pop-buttons-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-top: 40px;
}

.user-delete-member-pop-cancel-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 110px;
  height: 48px;

  background: transparent;
  border-radius: 8px;
  outline: none;
  border: none;
  font-family: 'Satoshi';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  letter-spacing: -0.02em;

  /* white */

  color: #75787E;
  margin-left: 15px;
}

.user-delete-member-pop-confirm-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 140px;
  height: 48px;

  background: #E45447;
  border-radius: 8px;
  outline: none;
  border: none;
  font-family: 'Satoshi';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  letter-spacing: -0.02em;

  /* white */

  color: #FFFFFF;
  margin-left: 15px;
}


.user-header {
  font-family: 'Satoshi';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 24px;
  /* identical to box height, or 100% */

  letter-spacing: -0.02em;

  color: #000000;
}

.user-subheader {
  font-family: 'Satoshi';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */

  letter-spacing: -0.02em;

  color: #75787E;
  margin-top: 8px;
}

.user-top-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 30px 0;
}

.user-top-left {
  font-family: 'Satoshi';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  text-align: center;
  letter-spacing: -0.02em;

  color: #000000;
}

.user-top-right {
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
}

.user-top-right-text {
  font-family: 'Satoshi';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 24px;
  /* identical to box height, or 160% */

  text-align: center;
  letter-spacing: -0.02em;

  color: #000000;
  margin-right: 14px;
}

.user-top-right-text-warning {
  margin-left: 24px;
  color: #EE6A5F !important;
}

.user-page-add-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  margin-top: 25px;
  margin-bottom: 49px;

}

.user-page-add {
  margin-right: 14px;
}

.user-page-add-text {
  font-family: 'Satoshi';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */

  letter-spacing: -0.02em;

  color: #017CF8;
}

.user-page-add-text-disabled {
  color: rgba(117, 120, 126, 0.5) !important;
}

.user-status-container {
  width: 126px;
  height: 26px;
  margin: auto;
color: #5BB531;
  background: #F0FAEB;
  border-radius: 10000px;;
  display: flex;
  justify-content: center;
  align-items: center;
}

.user-status-container-disabled {
  width: 126px;
  height: 26px;
 margin: auto;
  background: #F3F5F7;;
  border-radius: 1000px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.user-status-container-purple {
  width: 126px;
  height: 26px;
  margin: auto;
  background: #E4F1FF;
  border-radius: 1000px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.user-status {
  font-family: 'Satoshi';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */

  text-align: center;
  letter-spacing: -0.02em;

}

.user-icon-container {
  background-color: transparent;
  border: none;
  outline: none;
  .resend-email{
    font-size: "14px";
    margin-right: 50px;
    color: "#017CF8";
    text-decoration: underline;
    text-decoration-color: #017CF8;
  }
}

/* add team */
.add-team-container {
  width: 928px;
  height: 117px;
  left: 308px;
  top: 600px;

  background: rgba(228, 241, 255, 0.3);
  border-radius: 8px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  padding: 19px 0 24px 15px;
  margin-top: 22px;
}

.add-team-name-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.add-team-email-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  margin-left: 42px;
  
}
.error{
  font-size: 12px;
  color: red;
}
.add-team-tag {
  font-family: 'Satoshi';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */

  display: flex;
  align-items: center;

  /* text/secondary */

  color: #75787E;
}

.add-team-name-input {
  /* input-field */


  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 14px 0px 14px 14px;
  gap: 10px;

  width: 200px;
  height: 48px;

  /* white */

  background: #FFFFFF;
  border: 1px solid #DBE1E7;
  border-radius: 8px;
  color: #000000;
}

.add-team-name-input:focus,
.add-team-email-input:focus {
  background: #FFFFFF;
  border: 1px solid #017CF8;
  border-radius: 8px;
}

.add-team-email-input {
  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 14px 0px 14px 14px;
  gap: 10px;

  width: 272px;
  height: 48px;

  /* white */

  background: #FFFFFF;
  /* stroke */

  border: 1px solid #DBE1E7;
  border-radius: 8px;
  color: #000000;
}

.user-limit-warning-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 30px;
}

.user-limit-warning-icon {
  margin-right: 8px;
  margin-top: 5px;
  width: 14px;
  height: 14px;
}

.user-limit-warning-text-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.user-limit-warning {
  font-family: 'Satoshi';
  font-weight: 400;
  font-size: 10px;
  line-height: 24px;
  font-size: 12px;
  /* identical to box height, or 240% */

  letter-spacing: -0.02em;

  color: #75787E;
}

.user-limit-warning-normal {
  font-family: 'Satoshi';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 24px;
  /* identical to box height, or 240% */

  letter-spacing: -0.02em;

  color: #EE6A5F;
}

.email-sent {
  font-family: 'Satoshi';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  /* or 171% */
  letter-spacing: -0.02em;

  color: #75787E;
  padding: 0 !important;
  margin: 0 0 0 15px !important;
  display: inline-flex;
}

.infoNotifError {
  background: #ffe4b4;
  border-radius: 5px;
  padding: 8px;
  width: 340px;
  margin: 5px 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 40px;

  svg {
    border: 1px solid #f4b037;
    border-radius: 100%;
    height: 13px;
    width: 13px;
    margin-right: 10px;
  }

  p {
    font-size: 10px;
    color: #75787e;
    width: 300px;
    line-height: normal;
  }
}