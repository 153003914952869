.upgrade-plan-modal{
  position:fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  display: flex;
  flex-direction: column;
  z-index: 99;

  width: 600px;
  padding: 30px;
  border-radius:20px;
  background-color: white;
  box-shadow: 0 3px 10px rgba(black,0.3);

  .pricing-mini-card{
    box-shadow: 0 3px 10px rgba(black,0.12);
  }

  .upgrade-plan-modal-title{
    font-weight: 500;
    font-size: 24px;
    margin-bottom: 10px;
  }

  .upgrade-plan-modal-subtitle{
    font-weight: 400;
    font-size: 16px;
    color: #75787E;
    width: 370px;
  }
}


.upgrade-plan-modal-cards-container{
  display: flex;
  justify-content:center;
}

.upgrade-plan-modal-overlay {
  position: fixed;
  z-index: 98;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0,0.4);
}

.cta-link{
  color: #75787E;
  text-decoration: underline;
  font-weight: 500;
  margin: 0 auto;
  margin-top: 25px;
  cursor: pointer;
}

.cta-link:hover{
  color: #3a3c3f;
}